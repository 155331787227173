import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { cartActionCreators } from "../../redux/cart";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { userActionCreators } from "../../redux/user";
import { Button, Form, Select } from "antd";
import formatter from "../../utils/formatter";
import triggerNotification from "../../hooks/triggerNotification";
import AuthModal from "../../components/AuthModal";
import colors from "../../assets/colors";
import {
  getSavedItemToLocalStorage,
  deleteSavedItemFromLocalStorage,
} from "../../utils/guestSavedProductsLocal";

const { Option } = Select;

const ProductInSavedItems = ({ item, deleteItem, itemsInfo }) => {
  const { data } = useSelector((state) => state.currency);
  const { rate, code, indicator } = data;

  const dispatch = useDispatch();

  const { items: productInCart } = useSelector((state) => state.cart);

  const { addToCart, updateProductAlreadyInCart } = bindActionCreators(
    cartActionCreators,
    dispatch
  );

  const [itemColors, setItemColors] = useState([]);
  const [itemSizes, setItemSizes] = useState([]);

  const getItemColors = (id) => {
    const item = itemsInfo.find((item) => item.id === id);
    const colors = item?.info.map((info) => info.color);
    // console.log('itemColor', colors)
    setItemColors(colors);
  };
  const getItemSizes = (selectedColor, id) => {
    const item = itemsInfo.find((item) => item.id === id);
    const info = item.info.find((info) => info.color === selectedColor);
    setItemSizes(info.sizes);
  };
  

  const [form] = Form.useForm();

  const addProductToCart = (values) => {
    const selectedColor = colors.find(
      (color) =>
        color?.name?.toLowerCase() === values?.colorSelect?.toLowerCase()
    );

    const clonedCartItems = JSON.parse(JSON.stringify(productInCart));

    let productAlreadyInCartIndex;

    if (selectedColor) {
      productAlreadyInCartIndex = clonedCartItems.findIndex((cartItem) => {
        return (
          cartItem?.product_id === item?.id &&
          cartItem?.selectedColor?.hex?.toLowerCase() ===
            selectedColor?.hex?.toLowerCase() &&
          cartItem?.selectedSize === values?.selectedSize
        );
      });
    } else {
      productAlreadyInCartIndex = clonedCartItems.findIndex((cartItem) => {
        return cartItem?.product_id === item?.id;
      });
    }

    if (productAlreadyInCartIndex !== -1) {
      const clonedItemAlreadyInCart = {
        ...clonedCartItems[productAlreadyInCartIndex],
      };
      clonedItemAlreadyInCart.quantityWanted++;
      clonedItemAlreadyInCart.totalPrice =
        clonedItemAlreadyInCart.price * clonedItemAlreadyInCart.quantityWanted;
      updateProductAlreadyInCart(clonedItemAlreadyInCart);
      triggerNotification({
        type: "success",
        message: "Item quantity updated in your shopping bag",
      });
    } else {
      const tempProductForCart = JSON.parse(JSON.stringify(item));
      tempProductForCart.quantityWanted = 1;
      tempProductForCart.totalPrice = JSON.parse(JSON.stringify(item)).price;
      tempProductForCart.selectedColor = selectedColor;
      tempProductForCart.selectedSize = values?.sizeSelect;
      addToCart(tempProductForCart);
      triggerNotification({
        type: "success",
        message: "Item has been added to your shopping bag",
      });
    }
  };

  useEffect(() => {
    form.resetFields();
    if (itemColors?.length === 1) {
      form.setFieldsValue({
        colorSelect: itemColors[0],
      });
      getItemSizes(itemColors[0], item.product_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, itemColors, itemSizes]);

  useEffect(() => {
    getItemColors(item.product_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, itemsInfo]);

  return (
    <Form
      className="add-to-cart-form"
      form={form}
      onFinish={addProductToCart}
      scrollToFirstError
    >
      <img alt={item.name} className="product-img" src={item.img1} />
      <svg
        className="icon-close"
        width="30"
        height="30"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => deleteItem(item.product_id)}
      >
        <path
          d="M20.8258 0.583008C9.36216 0.583008 0.25 9.69517 0.25 21.1588C0.25 32.6225 9.36216 41.7347 20.8258 41.7347C32.2895 41.7347 41.4017 32.6225 41.4017 21.1588C41.4017 9.69517 32.2895 0.583008 20.8258 0.583008ZM28.7622 31.4468L20.8258 23.5104L12.8894 31.4468L10.5379 29.0952L18.4743 21.1588L10.5379 13.2225L12.8894 10.8709L20.8258 18.8073L28.7622 10.8709L31.1138 13.2225L23.1774 21.1588L31.1138 29.0952L28.7622 31.4468Z"
          fill="black"
        />
      </svg>
      <div className="saved-item-details">
        <h4 className="saved-item-name">{item.name}</h4>
        <h4 className="saved-item-price">
          {formatter(item.price, rate, code, indicator)}
        </h4>
        <Form.Item
          name="colorSelect"
          rules={[
            {
              required: itemColors?.length > 0 ? true : false,
              message: "Please select a color",
            },
          ]}
          style={{ visibility: itemColors?.length === 0 && "hidden" }}
        >
          <Select
            className="saved-item-select-option"
            placeholder="Select Color"
            onChange={(val) => getItemSizes(val, item.product_id)}
          >
            {itemColors?.map((color, idx) => (
              <Option value={color} key={idx}>
                {color}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="sizeSelect"
          rules={[
            {
              required: itemSizes?.length > 0 ? true : false,
              message: "Please select a size",
            },
          ]}
          style={{ visibility: itemSizes?.length === 0 && "hidden" }}
        >
          <Select
            className="saved-item-select-option"
            placeholder="Select Size"
          >
            {itemSizes?.map((size, idx) => (
              <Option value={size} key={idx}>
                {size}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Button className="btn-add-item" type="primary" htmlType="submit">
          Add to bag
        </Button>
      </div>
    </Form>
  );
};

const SavedItems = () => {
  const dispatch = useDispatch();

  const [itemsInfo, setItemsInfo] = useState([]);
  const [guestSavedProducts, setGuestSavedProducts] = useState([]);

  const [buyerSignInAuthModalVisible, setBuyerSignInAuthModalVisible] =
    useState(false);

  const { savedItems, info } = useSelector((state) => state.user);

  const { postItem } = bindActionCreators(userActionCreators, dispatch);



  const { fetchItems, deleteSavedItem } = bindActionCreators(
    userActionCreators,
    dispatch
  );

  const setSizesAndColors = () => {
    const savedItemsCheck = info.token ? savedItems : guestSavedProducts;
    const items = [];
    savedItemsCheck?.map((item) => {
      let currItem = items.find((itm) => itm.id === item.product_id);
      if (!currItem)
        currItem = {
          id: item.product_id,
          info: [],
        };
      // for savedItems, variant is named varieties, while for guestSavedItems, it is named variety
      item?.[info?.token ? "varieties" : "variety"]?.map((variant) => {
        if (!currItem?.info.find((itm) => itm.color === variant.color)) {
          currItem.info.push({
            color: variant.color,
            sizes: [variant.size],
          });
        } else {
          let info = currItem?.info.find((itm) => itm.color === variant.color);
          if (!info.sizes.includes(variant.size)) info.sizes.push(variant.size);
        }
        return true;
      });
      items.push(currItem);
      return true;
    });

    setItemsInfo(items);
  };

  const deleteItem = async (id) => {
    if (info.token) {
      const res = await deleteSavedItem(id);
      if (res.status === "ok") {
        triggerNotification({
          type: "success",
          message: res.message,
        });
      }
    } else {
      deleteSavedItemFromLocalStorage(id);
      const getSavedLocal = getSavedItemToLocalStorage();
      setGuestSavedProducts(getSavedLocal);
      triggerNotification({
        type: "success",
        message: "Saved Item Successfully Deleted",
      });
    }
  };

  const mergeSavedItems = async (idArr) => {
    const res = await postItem(idArr);
    if (res.status === "ok") {
      localStorage.removeItem("guestSavedItem");
    } else {
      console.error(res.status);
    }
  };

  useEffect(() => {
    if (info.token) {
      const savedItemsLocal = JSON.parse(
        localStorage.getItem("guestSavedItem")
      );
      if (savedItemsLocal && savedItemsLocal.length > 1) {
        const savedItemsLocalIds = savedItemsLocal.map(
          (item) => item.product_id
        );
        mergeSavedItems(savedItemsLocalIds);
      }
      fetchItems();
      setSizesAndColors();
    } else {
      const getSavedLocal = getSavedItemToLocalStorage();
      setGuestSavedProducts(getSavedLocal);
      setSizesAndColors();
    }
    // setSizesAndColors();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.token]);

  useEffect(() => {
    setSizesAndColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedItems, guestSavedProducts]);

  return (
    <div>
      <div
        className="saved-items-container"
        style={{ fontFamily: "DomaineSansText-Light" }}
      >
        <div className="page-details-container">
          <h1 className="page-header-title">Saved Items</h1>
          <desc className="page-desc">Save your favourite items here. You can always return to purchase saved items at a later time.</desc>
          <div className="page-line" />
        </div>

        {!info.token && guestSavedProducts?.length > 0 && (
          <div className="guest-sync-container">
            <p>Sign in to sync your Saved Items across all your devices</p>
            <Button
              className="save-page-no-item-saved-button"
              onClick={() => setBuyerSignInAuthModalVisible(true)}
            >
              Register / Sign in
            </Button>
          </div>
        )}

        {info.token && savedItems && savedItems?.length > 0 ? (
          <div className="saved-items-list-container">
            <div className="sort-container">
              <div className="saved-mobile-filter-container">

                <span>{savedItems?.length} Items</span>
              </div>
            </div>

            <div className="saved-items">
              {savedItems
                ?.sort((a, b) => b.product_id - a.product_id)
                ?.map((item, idx) => (
                  <div className="saved-item" key={idx}>
                    <ProductInSavedItems
                      item={item}
                      deleteItem={deleteItem}
                      itemsInfo={itemsInfo}
                    />
                  </div>
                ))}
            </div>
          </div>
        ) : !info.token &&
          guestSavedProducts &&
          guestSavedProducts?.length > 0 ? (
          <div className="saved-items-list-container">
            <div className="sort-container">
              <div className="saved-mobile-filter-container">
                <span>{guestSavedProducts?.length} Items</span>
              </div>
            </div>

            <div className="saved-items">
              {guestSavedProducts.map((item, idx) => (
                <div className="saved-item" key={idx}>
                  <ProductInSavedItems
                    item={item}
                    deleteItem={deleteItem}
                    itemsInfo={itemsInfo}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="no-order-div-container">
            <div className="no-order-div">
              <SavedItemsIcon />
              <NoSavedItemDetails setBuyerSignInAuthModalVisible={setBuyerSignInAuthModalVisible} />
            </div>
          </div>
        )}
      </div>

      <AuthModal
        authModalVisible={buyerSignInAuthModalVisible}
        setAuthModalVisible={setBuyerSignInAuthModalVisible}
        activePage="2"
        pageUserType="buyer"
      />

      <style jsx="true">
        {`
          .saved-items-container {
            background: rgb(240 240 240);
            margin: 0;
          }
          .saved-mobile-filter-container {
            font-size: 20px;
          }
          .page-details-container {
            // margin: 40px auto;
            margin: 0px auto;
            padding: 30px 0;
            width: 100%;
            text-align: center;
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .page-header-title {
            font-family: DomaineSansText-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 39px;
            text-align: center;
          }
          .page-desc {
            font-family: DomaineSansText-Regular;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            padding: 0 12px;
            max-width: 450px;
          }
          .page-line {
            background: #c4c4c4;
            height: 1px;
            width: 100%;
            margin-top: 15px;
            box-shadow: 0px 2px 6px 0px #00000040;
          }
          .guest-sync-container {
            margin: 30px 0;
            padding: 30px 50px;
            background: #fff;
          }

          .saved-items-list-container {
            margin: 0;
            padding: 40px 50px;
            background: #fff;
          }

          .sort-container {
            font-family: DomaineSansText-Regular;
            padding-left: 15px;
          }
          .saved-items-sort {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            width: 12%;
          }
          .saved-item-size {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            color: #92959e;
          }
          .saved-items {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            grid-gap: 10px;
            margin-top: 30px;
          }
          .saved-item {
            width: 300px;
            height: 600px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 70px;
            position: relative;
          }
          .product-img {
            border: 1px solid #f4f4f4;
            cursor: pointer;
            width: 300px;
            height: 400px;
            object-fit: cover;
          }

          .icon-close {
            cursor: pointer;
            right: -3%;
            position: absolute;
            top: -2%;
            z-index: 100;
          }
          .saved-item-details {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 250px;
          }
          .saved-item-name {
            font-family: DomaineSansText-Regular;
            font-style: normal;
            font-weight: 900;
            font-size: 15px;
            line-height: 24px;
            color: #32353c;
            margin-bottom: 0px;
            white-space: nowrap;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
          }
          .saved-item-price {
            font-family: DomaineSansText-Light;
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 19px;
            margin-bottom: 0px;
          }
          .saved-item-select-option {
            font-family: DomaineSansText-Light;
            font-size: 12px;
            font-weight: 400;
            width: 96%;
            height: 24px;
          }
          .saved-item-select-option.ant-select-single:not(
              .ant-select-customize-input
            )
            .ant-select-selector {
            border: 0px;
            border-radius: 0;
            border-bottom: 0.1px solid #000;
            align-items: center;
            margin-top: 0px;
            padding: 0px;
          }
          .saved-item-select-option .ant-select-arrow {
            top: 70%;
          }
          .btn-add-item {
            background: #000;
            border-radius: 2px;
            color: #fff;
            font-family: DomaineSansText-Light;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            margin-top: 1rem;
            height: 48px;
            width: 100%;
            border: 0;
          }

          .no-order-div {
            background: white;
            padding: 80px;
            font-size: 16px;
            min-height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 16px;
            width: 70%;
          }
          .no-order-div-container {
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 60px 0;
          }
          .no-order-text {
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            justify-content: center;
            text-align: center;
          }

          .saved-page-no-item-title {
            font-size: 38px;
            margin-top: 14px;
          }

          .save-page-no-item-subtitle {
            width: 430px;
            margin: auto;
            font-size: 16px;
            margin-bottom: 24px;
            margin-top: 8px;
          }

          .save-page-no-item-saved-button {
            height: 48px;
            background: black;
            color: white;
            width: 300px;
            font-family: DomaineSansText-Light;
            border-radius: 4px;
          }

          @media (min-width: 768px) and (max-width: 1024px) {
            .saved-item {
              margin-right: 0.6rem;
              margin-left: 0.6rem;
            }
          }

          @media (max-width: 640px) {
            .page-details-container {
              padding: 15px 0;
              width: 100%;
            }
            .page-header-title {
              font-size: 24px;
              // text-align: left;
              margin: 0;
            }
            .page-desc {
              font-size: 14px;
              text-align: center;
              margin: auto;
              width: 350px;
              font-family: DomaineSansText-Light;
            }
            .page-line {
              background: #c4c4c4;
              height: 1px;
              width: 95%;
              margin-top: 10px;
            }
            .guest-sync-container {
              margin: 0 0;
              padding: 20px 16px;
              background: #fff;
            }
            .guest-sync-container p {
              font-size: 14px;
            }
            .saved-items-list-container {
              padding: 16px;
            }
            .saved-items {
              gap: 8px;
              justify-content: space-between;
              grid-template-columns: 1fr 1fr;
            }
            .saved-item {
              width: 185px;
              height: auto;
              margin-right: 0rem;
              margin-left: 0rem;
            }
            .no-order-div-container {
              padding: 0;
            }
            .no-order-div {
              width: 100%;
              height: 100%;
              // padding: 16px;
              margin: 0;
            }
            .no-order-text {
              width: 300px;
            }
            .saved-page-no-item-title {
              font-size: 18px;
              font-weight: 600;
            }
            .save-page-no-item-subtitle {
              width: 100%;
              font-size: 14px;
              // margin: 25px 0 30px;
            }
            .product-img {
              height: 220px;
              width: 100%;
            }
            .saved-item-details {
              height: auto;
              margin-top: 8px;
              gap: 8px;
            }
            .saved-item-name {
              height: 36px;
              display: block;
              display: -webkit-box;
              display: -moz-box;
              line-height: normal;
              font-size: 13px;
              white-space: nowrap;
              overflow: hidden;
              display: block;
              text-overflow: ellipsis;
            }
            .saved-item-select-option.ant-select-single:not(
                .ant-select-customize-input
              )
              .ant-select-selector {
              height: 32px !important;
              padding: 0;
            }
            .saved-item-select-option.ant-select-single.ant-select-show-arrow
              .ant-select-selection-placeholder {
              padding: 0 !important;
            }
            .btn-add-item {
              height: 40px;
              font-size: 13px;
            }
            .saved-item-price {
              font-size: 13px;
            }
            .icon-close {
              width: 24px;
              height: 24px;
              right: -6%;
            }
            .saved-mobile-filter-container {
              font-family: DomaineSansText-Light;
              display: flex;
              align-items: center;
              gap: 8px;
            }
            .saved-items-sort {
              width: 130px;
            }
            .sort-container {
              padding-left: 0;
            }
            // .sort-container
            //   .ant-select-single:not(.ant-select-customize-input)
            //   .ant-select-selector {
            //   margin-top: 0 !important;
            //   height: auto !important;
            // }
          }
        `}
      </style>
    </div>
  );
};

export default SavedItems;


const SavedItemsIcon = () => {
  return (
    <svg
      width="70"
      height="61"
      viewBox="0 0 70 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.3879 1.47741C44.7543 0.496873 47.2908 -0.0078125 49.8523 -0.0078125C52.4139 -0.0078125 54.9503 0.496873 57.3167 1.47741C59.6828 2.45777 61.8325 3.8946 63.6431 5.70585C65.4544 7.5165 66.8922 9.66721 67.8726 12.0332C68.8531 14.3997 69.3578 16.9361 69.3578 19.4977C69.3578 22.0592 68.8531 24.5957 67.8726 26.9621C66.8921 29.3283 65.4551 31.4782 63.6436 33.289C63.6435 33.2892 63.6438 33.2888 63.6436 33.289L37.1236 59.809C35.9521 60.9806 34.0526 60.9806 32.881 59.809L6.36099 33.289C2.7033 29.6313 0.648438 24.6704 0.648438 19.4977C0.648438 14.3249 2.7033 9.36403 6.36099 5.70634C10.0187 2.04866 14.9796 -0.00620818 20.1523 -0.00620818C25.3251 -0.00620818 30.2859 2.04866 33.9436 5.70634L35.0023 6.76503L36.0605 5.70684C36.0603 5.70701 36.0607 5.70668 36.0605 5.70684C37.8713 3.89537 40.0216 2.45786 42.3879 1.47741ZM49.8523 5.99219C48.0789 5.99219 46.3229 6.34159 44.6846 7.02042C43.0463 7.69925 41.5578 8.69422 40.3041 9.94849L37.1236 13.129C35.9521 14.3006 34.0526 14.3006 32.881 13.129L29.701 9.94899C27.1685 7.41652 23.7338 5.99379 20.1523 5.99379C16.5709 5.99379 13.1361 7.41652 10.6036 9.94899C8.07116 12.4815 6.64844 15.9162 6.64844 19.4977C6.64844 23.0791 8.07116 26.5139 10.6036 29.0463L35.0023 53.445L59.401 29.0463C60.6552 27.7927 61.6507 26.3037 62.3296 24.6654C63.0084 23.027 63.3578 21.271 63.3578 19.4977C63.3578 17.7243 63.0084 15.9683 62.3296 14.33C61.6507 12.6917 60.6557 11.2032 59.4015 9.94948C58.1478 8.69522 56.6583 7.69925 55.02 7.02042C53.3817 6.34159 51.6257 5.99219 49.8523 5.99219Z"
        fill="#272727"
      />
    </svg>
  );
}


const NoSavedItemDetails = ({setBuyerSignInAuthModalVisible }) => {

  const navigate = useNavigate()

  const { type, info } = useSelector((state) => state.user);
  

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  return (
    <div className="no-order-text">
      <div className="saved-page-no-item-title">
        Your Saved Items List Is Empty
      </div>
      <div className="save-page-no-item-subtitle">
        You can save your favourite item here. Go to{" "}
        <span
          style={{
            fontWeight: "600",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={shopNewIn}
        >
          New in
        </span>{" "}
        to start shopping now. Sign in to sync your Saved Items across
        all your devices.
      </div>
      {!info.token && (
        <Button
          className="save-page-no-item-saved-button"
          onClick={() => setBuyerSignInAuthModalVisible(true)}
          style={{marginTop: "12px"}}
        >
          Register
        </Button>
      )}
    </div>
  );
}