import { useMediaQuery } from "react-responsive";
import HomePageMobile from "./HomepageMobile";
import HomePageWeb from "./HomepageWeb";
import ModalForEmailCollection from "../components/layout/ModalForEmailCollection";
import { useState } from "react";

const Home = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const [showModalForEmailCollection, setShowModalForEmailCollection] = useState(false);
  
  return (
    <div
    // style={{overflow:"hidden", position:"fixed"}}
    >
      {
        isTabletOrMobile ? <HomePageMobile /> : <HomePageWeb/>
      }
      <ModalForEmailCollection showModal={showModalForEmailCollection} setShowModal={setShowModalForEmailCollection} promoName={'10PercentPromo'} showOnPageLoad />
     
    </div>
  );
};

export default Home;
