import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { Button } from "antd";

const PopularCategory = () => {
  const navigate = useNavigate();

  // const { type } = useSelector((state) => state.user);

  // const createCategoryURL = (cat, id) => {
  //   return(`/${type.toLowerCase()}/subcategory/${cat}/cid=${id}&page=1`);
  // };

  const brands = [
    {
      title:"Boubous",
      url: '/products/category/cid=23&name=women-bou-bou?page=1',
      imageUrl:"https://res.cloudinary.com/losode-ng/image/upload/w_600/f_auto/v1742539421/website-pictures-optimized/Boubous_pfookm.png",
    },
    {
      title:"Dresses",
      url: '/products/category/cid=11&name=women-dresses?page=1',
      imageUrl:"https://res.cloudinary.com/losode-ng/image/upload/w_600/f_auto/v1742539419/website-pictures-optimized/Dresses_afre2l.png",
    },
    {
      title:"Kimonos",
      url: "/products/category/cid=14&name=women-kimonos?page=1",
      imageUrl:"https://res.cloudinary.com/losode-ng/image/upload/w_600/f_auto/v1742539422/website-pictures-optimized/Kimonos_zlmmio.png",
    },
    {
      title:"Co-ords",
      url: "/products/category/cid=261&name=men-2piece?page=1",
      imageUrl:"https://res.cloudinary.com/losode-ng/image/upload/w_600/f_auto/v1742539421/website-pictures-optimized/Co-ords_kpq81n.png"
    }
];
  return (
    <div className="popularcategory-container">
      <div>
        <div className="popularcategory-title">
          Discover Our Popular Categories
        </div>
        <p className="popularcategory-subtitle">
          View our most popular fashion categories, featuring trend-setting
          styles from our designers. Shop from our vast array of chic womenswear
          to statement menswear, and discover the latest in fashion trends.
        </p>
      </div>

      <div className="popularcategory-products-container">
        {brands.map((brand, index) => (
          <div
            className="popularcategory-product"
            key={index}
            onClick={() => navigate(brand.url)}
          >
            <img
              className="popularcategory-product-image"
              loading="lazy"
              alt="brand"
              src={brand.imageUrl}
            />
            {/* <div className="popularcategory-product-title">{brand.title}</div> */}
          </div>
        ))}
      </div>

      <style jsx="true">{`
        .popularcategory-container {
          margin: 0;
          padding: 40px 4rem;
          background: #f9f9f9;
        }
        .popularcategory-container .popularcategory-title {
          text-align: center;
          font-size: 30px;
          font-family: DomaineSansText-Medium;
          margin-bottom: 16px;
          line-height: 30px;
        }
        .popularcategory-subtitle {
          font-size: 16px;
          font-family: DomaineSansText-Light;
          line-height: 1.4;
          max-width: 1000px;
          margin: 8px auto 24px;
          text-align: center
        }
        .popularcategory-products-container {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
              0,
              1fr
            );
          gap: 24px;
          justify-content: center;
          text-align: center;
        }
        .popularcategory-product {
          position: relative;
          cursor: pointer;
        }
        .popularcategory-product-image {
          width: 100%;
          height: auto;
        }
        .popularcategory-product-title {
          font-size: 18px;
          font-family: DomaineSansDisplay-SemiBold;
          line-height: 1;
          position: absolute;
          bottom: 14px;
          left: 10px;
          color: #fff;
        }
        .popularcategory-product-price {
          font-size: 12px;
          font-family: DomaineSansText-Medium;
          color: #000;
        }
        .mobile-popularcategory-btn {
          width: 100%;
          height: 40px;
          background: #000000;
          border-radius: 2px;
          color: #fff;
          font: normal normal 16px DomaineSansText-Regular;
          margin: 16px 0;
        }
      `}</style>
    </div>
  );
};

export default PopularCategory;
