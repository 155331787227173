import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const ShopGender = () => {
  const navigate = useNavigate();

  const genders = [
    {
      id: "male",
      imageUrl:
        // "https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1729587414/website-pictures-optimized/homepage-gender-male-mobile_ojnybj.png",
        "https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1716063101/product-images/fjquglsgz9krufmarits.jpg",
      name: "Be The Man They Remember",
      description: "From laid-back cool to refined sophistication, our designs will make you stand out effortlessly. Aim for understated charm and bold confidence, own the stage no matter the occasion.",
      action: "Shop Men",
      cta_url:'/men-fashion/all/cid=1&name=men-products'
    },
    {
      id: "female",
      imageUrl:
        "https://res.cloudinary.com/losode-ng/image/upload/v1713928505/product-images/eqbhnq2iqfdtklzdxytm.jpg",
      name: "Play Dress-up With Confidence",
      description:
      "Discover flawless outfits, chic styles, and statement-making designs guaranteed to turn heads and leave a lasting impression. Elevate your presence with impeccable tailoring and timeless style.",
      action: "Shop Women",
      cta_url:'/women-fashion/all/cid=2&name=women-products'
    },
  ];

  return (
    <div style={{display:'flex', flexDirection:'column', gap:'28px', margin:'24px 0'}}>
      {genders.map((gender, index) => (
        <div className="shop-gender-container" key={index}>
          {/* temporary link to specific product. for update */}
          <img src={gender.imageUrl} alt="shop" className="shop-gender-image" onClick={()=> gender.id==="female" ? navigate('/product/bell-pants-set') :'' } />
          <div className="shop-gender-content-container">
            <div className="gender-title" style={{color:'fff'}}>{gender.name}</div>
            <div className="gender-subtext">{gender.description}</div>
            <Button
                className="btn-shop-shop-gender"
                onClick={() => navigate(gender.cta_url)}
              >
                {gender.action}
            </Button>
          </div>
        </div>
      ))}

    <style jsx="true">{`
        .shop-gender-container {
          position:relative;
          margin: 0px 16px;
        }
        .shop-gender-image{
          width: 100%;
          height: 100%;
          aspect-ratio: 11 / 16;
          object-fit: cover;
          cursor: pointer;
        }
        .shop-gender-container .btn-shop-shop-gender {
          width: 280px;
          height: 40px;
          border-radius: 2px;
          border: none;
          font: normal normal 14px DomaineSansText-Regular;
          margin-top: 16px;
          background-color: #000000;
          color: #fff;
        }
        .shop-gender-content-container{
          text-align:center;
          width: 100%;
          padding: 16px 0px 0;
        }
        .shop-gender-content-container .gender-title{
          font-size: 20px;
          font-family: DomaineSansText-Medium;
          margin-bottom: 4px;
          line-height: 24px;
        }
        .shop-gender-content-container .gender-subtext{
          font-size: 14px;
          font-family: DomaineSansText-Light;
          line-height: 18px;
        }
      `}</style>
    </div>
  );
};

export default ShopGender;
