import { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  Drawer,
  Row,
  Dropdown,
  Input,
  InputNumber,
  Slider,
  Space
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { productsActionCreators } from "../redux/products";

import Product from "../components/Product";

import colors from "../assets/colors";
import formatter from "../utils/formatter";
import { occasion } from "../assets/occassions";

import { isEmptyObject } from "../utils/isEmptyObject";
// import Subscribe from "../components/Subscribe";

const { Panel } = Collapse;

const SearchResult = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const navigate = useNavigate();
  const location = useLocation();

  const [visibleCategories, setVisibleCategories] = useState([]);
  const [visibleBrands, setVisibleBrands] = useState([]);
  const [visibleColors, setVisibleColors] = useState("Multicolour");

  const { type } = useSelector((state) => state.user);

  const { products, filteredProducts: filtered } = useSelector(
    (state) => state.products
  );
  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data
    ? data
    : { code: "", currency: "", rate: "" };
  const [price, setPrice] = useState([0, 10000000000]);
  const isMobile = useMediaQuery({ maxWidth: 640 });

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const value = location.state;

  const dispatch = useDispatch();
  const {
    fetchLastProducts,
    fetchNextProducts,
    // fetchBrandProducts,
    sortByPrice
  } = bindActionCreators(productsActionCreators, dispatch);

  const handleButtonClick = (e) => {
    if (e.key === "0") sortByPrice("descending");
    if (e.key === "1") sortByPrice("ascending");
  };

  const items = [
    {
      key: "0",
      label: "Price High to Low",
    },
    {
      key: "1",
      label: "Price Low to High",
    },
  ];

  const menuProps = {
    items,
    onClick: handleButtonClick,
  };

  const sizesSet = new Set();

  products?.data?.map((product) => {
    if (typeof product.sizes === "object") {
      product?.sizes?.map((size) =>
        sizesSet.has(size) ? "" : sizesSet.add(size)
      );
    } else {
      if (!sizesSet.has(product.sizes)) sizesSet.add(product.sizes);
    }
    return sizesSet;
  });

  const categoriesForEmptySearch = [
    {
      imageUrl: `/images/${isTabletOrMobile?"mobile-":""}search-pic-1.png`,
      name: "New In",
      slug:
        type === "Men"
          ? `/new-in/men/cid=${1}&name=new-in-men-products`
          : `/new-in/women/cid=${2}&name=new-in-women-products`,
    },
    {
      imageUrl: `/images/${isTabletOrMobile?"mobile-":""}search-pic-2.png`,
      name: "Clothing",
      slug: `/${type.toLowerCase()}/subcategory/clothing/cid=${
        type === "Women" ? 6 : 5
      }?page=1`,
    },
    {
      imageUrl: `/images/${isTabletOrMobile?"mobile-":""}search-pic-3.png`,
      name: "Accessories",
      slug: `/${type.toLowerCase()}/subcategory/accessories/cid=${
        type === "Women" ? 10 : 9
      }?page=1`,
    },
    {
      imageUrl: `/images/${isTabletOrMobile?"mobile-":""}search-pic-4.png`,
      name: "Designers",
      slug: "/designers",
    },
  ];
  const groupBrands = (brands) => {
    let data = brands.reduce((r, e) => {
      // get first letter of name of current element
      let alphabet = e.store_name[0];

      // if there is no property in accumulator with this letter create it
      if (!r[alphabet]) r[alphabet] = { alphabet, record: [e] };
      // if there is push current element to children array for that letter
      else r[alphabet].record.push(e);

      // return accumulator
      return r;
    }, {});

    return Object.values(data);
  };

  const brandDiv = () => {
    const items = [];
    const sortedBrands = products?.brands.sort((a, b) =>
      a.store_name.localeCompare(b.store_name, "en", { sensitivity: "base" })
    );
    const groupedBrands = groupBrands(sortedBrands);
    groupedBrands.map((group) =>
      items.push(
        <>
          <h3 style={{ marginTop: 30, marginBottom: 0 }}>{group.alphabet}</h3>
          {group.record.map((brand) => (
            <div
              className="brands-div"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Checkbox
                style={{
                  fontFamily: "DomaineSansText-Regular",
                  marginTop: 20,
                  fontSize: 14,
                }}
                onClick={() => checkBrand(brand.store_name)}
              >
                {capitalize(brand.store_name)}
                <span
                  style={{ color: "#C4C4C4", marginLeft: 5 }}
                >{`(${brand.size})`}</span>
              </Checkbox>
            </div>
          ))}
        </>
      )
    );
    return items;
  };

  // const shopBrand = (id, slug) => {
  //   fetchBrandProducts(id);
  //   navigate(`/products/${slug}`);
  // };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  };

  const priceFilter = (value) => {
    setPrice(value);
    // filterProductsByPrice(value);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const checkCategory = (category_name) => {
    const category_name_index = visibleCategories?.indexOf(
      category_name?.toLowerCase()
    );
    if (category_name_index !== -1) {
      setVisibleCategories(
        visibleCategories.filter(
          (cat_name, index) => category_name_index !== index
        )
      );
    } else {
      const newCategoryArray = visibleCategories.concat(
        category_name.toLowerCase()
      );
      setVisibleCategories(newCategoryArray);
    }
  };

  const checkBrand = (brand_name) => {
    const brand_name_index = visibleBrands?.indexOf(brand_name?.toLowerCase());
    if (brand_name_index !== -1) {
      setVisibleBrands(
        visibleBrands.filter((brand_name, index) => brand_name_index !== index)
      );
    } else {
      const newBrandArray = visibleBrands.concat(brand_name.toLowerCase());
      setVisibleBrands(newBrandArray);
    }
  };

  const checkColor = (color_name) => {
    setVisibleColors(color_name);
  };

  const filterSectionContainer = (
    <div style={{ fontFamily: "DomaineSansText-Light" }}>
      {products?.categories?.length > 1 && (
        <div className="box" style={{ borderTop: "1px solid #e6e6e6" }}>
          <Collapse
            className="collapse-container"
            ghost
            expandIconPosition="right"
            // defaultActiveKey={["1"]}
          >
            <Panel
              className="panel"
              header={
                <div>
                  <div>Category</div>
                  <div
                    style={{
                      color: "#92959E",
                      fontSize: "12px",
                    }}
                  >
                    All
                  </div>
                </div>
              }
              key="1"
            >
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                {products?.categories?.map((category) => (
                  <div className="category-div category-filter-item">
                    <Checkbox
                      style={{
                        fontSize: 12,
                        textTransform: "capitalize",
                      }}
                      onClick={() => checkCategory(category.catname)}
                    >
                      {category?.catname?.toLowerCase()}(
                      <span style={{ color: "#C4C4C4" }}>{category.size})</span>
                    </Checkbox>
                  </div>
                ))}
              </div>
            </Panel>
          </Collapse>
        </div>
      )}
      {products?.brands?.length > 1 && (
        <div className="box">
          <Collapse
            className="collapse-container"
            ghost
            expandIconPosition="right"
            // defaultActiveKey={["1"]}
          >
            <Panel
              className="panel"
              header={
                <div>
                  <div>Designers</div>
                  <div
                    style={{
                      color: "#92959E",
                      fontSize: "12px",
                    }}
                  >
                    All
                  </div>
                </div>
              }
              key="1"
            >
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Input
                  allowClear
                  className="searchBox"
                  placeholder={`Search designers`}
                  // style={{fontSize:'12px'}}
                  suffix={
                    <SearchOutlined
                      style={{ color: "#B7B7B7", fontSize: "12px" }}
                    />
                  }
                />
              </div>

              <div className="brands-container">{brandDiv()}</div>
            </Panel>
          </Collapse>
        </div>
      )}

      <div className="box">
        <Collapse
          className="collapse-container"
          ghost
          expandIconPosition="right"
          // defaultActiveKey={["1"]}
        >
          <Panel
            className="panel"
            header={
              <div>
                <div>Size</div>
                <div
                  style={{
                    color: "#92959E",
                    fontSize: "12px",
                  }}
                >
                  All
                </div>
              </div>
            }
            key="1"
          >
            <div
              className="size-div size-filter-item"
              style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}
            >
              {[...sizesSet]?.map((size) => (
                <Checkbox
                  style={{
                    fontSize: 12,
                    textTransform: "capitalize",
                    minWidth: "30%",
                  }}
                >
                  {size}
                </Checkbox>
              ))}
            </div>
          </Panel>
        </Collapse>
      </div>
      {/* <div className="box">
        <Collapse
          className="collapse-container"
          ghost
          expandIconPosition="right"
          // defaultActiveKey={["1"]}
        >
          <Panel className="panel" header="Style" key="1"></Panel>
        </Collapse>
      </div> */}

      <div className="box">
        <Collapse
          className="collapse-container"
          ghost
          expandIconPosition="right"
          // defaultActiveKey={["1"]}
        >
          <Panel
            className="panel"
            header={
              <div>
                <div>Occasion</div>
                <div
                  style={{
                    color: "#92959E",
                    fontSize: "12px",
                  }}
                >
                  All
                </div>
              </div>
            }
            key="1"
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              {occasion.map((occasion) => (
                <div className="category-div category-filter-item">
                  <Checkbox
                    style={{
                      fontSize: 12,
                      textTransform: "capitalize",
                    }}
                  >
                    {occasion}
                  </Checkbox>
                </div>
              ))}
            </div>
          </Panel>
        </Collapse>
      </div>

      <div className="box">
        <Collapse
          className="collapse-container"
          ghost
          expandIconPosition="right"
          // defaultActiveKey={["1"]}
        >
          <Panel
            className="panel"
            header={
              <div>
                <div>Fit</div>
                <div
                  style={{
                    color: "#92959E",
                    fontSize: "12px",
                  }}
                >
                  All
                </div>
              </div>
            }
            key="1"
          >
            {["Loose Fit", "Regular Fit", "Tight Fit "].map((fit) => (
              <div className="category-div category-filter-item">
                <Checkbox
                  style={{
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                >
                  {fit}
                </Checkbox>
              </div>
            ))}
          </Panel>
        </Collapse>
      </div>

      <div className="box">
        <Collapse
          className="collapse-container"
          ghost
          expandIconPosition="right"
          // defaultActiveKey={["1"]}
        >
          <Panel
            className="panel"
            header={
              <div>
                <div>Gender</div>
                <div
                  style={{
                    color: "#92959E",
                    fontSize: "12px",
                  }}
                >
                  All
                </div>
              </div>
            }
            key="1"
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              className="size-filter-item"
            >
              {["male", "female"]?.map((size) => (
                <Checkbox
                  style={{
                    fontSize: 12,
                    textTransform: "capitalize",
                    minWidth: "30%",
                  }}
                >
                  {size}
                </Checkbox>
              ))}
            </div>
          </Panel>
        </Collapse>
      </div>

      <div className="box">
        <Collapse
          className="collapse-container"
          ghost
          expandIconPosition="right"
          // defaultActiveKey={["1"]}
        >
          <Panel
            className="panel"
            header={
              <div>
                <div>Color</div>
                {visibleColors === "Multi-colour" && (
                  <div
                    style={{
                      color: "#92959E",
                      fontSize: "12px",
                      // marginTop:'4px'
                    }}
                  >
                    All
                  </div>
                )}
              </div>
            }
            key="1"
          >
            <div
              style={{
                fontSize: "12px",
                textDecoration: "underline",
                color: "#92959E",
                cursor: "pointer",
              }}
            >
              Unselect All
            </div>
            <div className="color-row">
              <div
                className="size-filter-item"
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                {/* <Checkbox
                    className="color-container"
                    value="multicolor"
                    onClick={() => checkColor("Multi-colour")}
                  >
                    <div style={{display:'flex'}}>
                      <div
                      className="color-box"
                      style={{ background: `transparent
                      linear-gradient(231deg, #177cef 0%, #f61313 100%)` }}
                      />
                      <p style={{fontSize:'12px', margin:0}}>Multicolor</p>
                    </div>
                   
                </Checkbox> */}
                {colors.map((color) => (
                  <Checkbox
                    id={color.id}
                    className="color-container"
                    value={color.name}
                    onClick={() => checkColor(color.name)}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        className="color-box"
                        style={{ background: `${color.hex}` }}
                      />
                      <p style={{ fontSize: "12px", margin: 0 }}>
                        {color.name}
                      </p>
                    </div>
                  </Checkbox>
                ))}
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className="box">
        <Collapse
          className="collapse-container"
          ghost
          expandIconPosition="right"
          // defaultActiveKey={["1"]}
        >
          <Panel
            className="panel"
            header={
              <div>
                <div>Price</div>
                <div
                  style={{
                    color: "#92959E",
                    fontSize: "12px",
                  }}
                >
                  All
                </div>
              </div>
            }
            key="1"
          >
            <div
              style={{
                fontSize: "12px",
                textDecoration: "underline",
                color: "#92959E",
                cursor: "pointer",
              }}
            >
              Reset
            </div>
            <Slider
              defaultValue={[0, 5000000]}
              max={5000000}
              onChange={(value) => priceFilter(value)}
              range={{ draggableTrack: true }}
              style={{ marginBottom: "16px" }}
              step={1000}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <div>
                <div style={{ fontSize: "12px" }}>min</div>
                <InputNumber
                  min={0}
                  max={5000000}
                  step={1000}
                  value={formatter(price[0], rate, code, indicator)}
                  style={{ fontSize: "12px", minWidth: "90px", width: "auto" }}
                />
              </div>
              <div>
                <div style={{ fontSize: "12px" }}>max</div>
                <InputNumber
                  min={800}
                  max={5000000}
                  step={1000}
                  value={formatter(price[1], rate, code, indicator)}
                  style={{ fontSize: "12px", minWidth: "90px", width: "auto" }}
                />
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );

  const { searchProduct } = bindActionCreators(
    productsActionCreators,
    dispatch
  );

  useEffect(() => {
    searchProduct(value,code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div>
      {!isMobile ? (
        <>
          {filtered?.length > 0 ? (
            <>
              <div className="cat-text-container">
                <h1 className="search-text">
                  Your Search Result for '{value}'
                </h1>
                <h3 className="search-result-num">
                  Items found: {products?.meta_data?.total}
                </h3>
              </div>

              <div className="products-container">
                <aside
                  className="filters-container"
                  style={{ position: "relative", top: "20px" }}
                >
                  {/* <Breadcrumb className="breadcrumb-container">
                <Breadcrumb.Item href="/">
                  <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>All Products</Breadcrumb.Item>
              </Breadcrumb> */}
                  {/* <div
                style={{ position: "relative", top: "-8px", color: "#92959E" }}
              >
                Clear All
              </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    {isMobile && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span className="filter-header-title-text">Filter</span>
                      </div>
                    )}
                    <div style={{ cursor: "pointer", color: "#92959E" }}>
                      Clear All
                    </div>
                  </div>

                  {filterSectionContainer}
                </aside>
                <div className="products-row">
                  <div className="sort-container">
                    <div>
                      {/* <span
                        style={{
                          font: "normal bold 17px DomaineSansText-Light",
                        }}
                      >
                        Sort:
                      </span> */}
                      <Dropdown menu={menuProps}>
                        <Button style={{ padding: 5 }}>
                              <Space>
                                  Sort
                                <DownOutlined />
                              </Space>
                          </Button>
                      </Dropdown>
                    </div>
                  </div>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {filtered?.map((product, index) => (
                      // <Col className="gutter-row" span={6}>
                      <Product
                        product={product}
                        currency={data}
                        productIndex={index}
                      />

                      // </Col>
                    ))}
                  </Row>
                  {!isEmptyObject(products) && (
                    <div className="pagination-container">
                      <div className="rows-box">
                        <p className="num-text">
                          Page {products?.meta_data?.current_page} of{" "}
                          {Math.ceil(
                            products?.meta_data?.total /
                              products?.meta_data?.per_page
                          )}
                        </p>
                        {products?.meta_data?.current_page !== 1 && (
                          <p
                            className="txt-next"
                            onClick={() =>
                              fetchLastProducts(
                                products?.meta_data?.prev_page_url
                              )
                            }
                          >
                            Prev
                          </p>
                        )}
                        {products?.meta_data?.current_page !==
                          Math.ceil(
                            products?.meta_data?.total /
                              products?.meta_data?.per_page
                          ) && (
                          <p
                            className="txt-next"
                            onClick={() =>
                              fetchNextProducts(
                                products?.meta_data?.next_page_url
                              )
                            }
                          >
                            Next
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div>
              <div className="search-text-container">
                <h1 className="no-result-text">You searched for “{value}”</h1>
                <h3 className="search-text-desc">
                  We do not have what you are looking for at this time. Why not
                  look at some other categories we have selected for you.
                </h3>
              </div>
              <div className="ex-brands-container">
                {categoriesForEmptySearch?.map((brand, index) => (
                  <div
                    className={`ex-brand div${index + 1} `}
                    key={index}
                    // onClick={() => shopBrand(brand.id, brand.slug)}
                    onClick={() => navigate(brand.slug)}
                  >
                    <img
                      className="ex-brand-img"
                      src={brand.imageUrl}
                      alt={brand.name}
                    />
                    <div className="search-none-image-overlay" />
                    <h4 className="txt-ex-brand">{brand.name}</h4>
                  </div>
                ))}
              </div>
              {/* <Subscribe /> */}
            </div>
          )}
          <div style={{ margin: "88px" }} />
        </>
      ) : filtered?.length > 0 ? (
        <div className="mobile-categories-container">
          <div className="mob-cat-text-container">
            <h1 className="mob-cat-text">Your Search Result for '{value}'</h1>
            <h3 className="mob-search-result-num">
              Items found: {products?.meta_data?.total}
            </h3>
          </div>

          <div className="mob-products-container">
            <Row gutter={[22]}>
              {filtered?.map((product, index) => (
                // <Col className="gutter,i-row" span={12}>
                <Product
                  product={product}
                  currency={data}
                  productIndex={index}
                />
                // </Col>
              ))}
            </Row>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="search-text-container"
            style={{ margin: "40px auto 0" }}
          >
            <h1 className="no-result-text" style={{ fontSize: "24px" }}>
              You searched for “{value}”
            </h1>
            <h3 className="search-text-desc">
              We do not have what you are looking for at this time. Why not look
              at some other categories we have selected for you.
            </h3>
          </div>
          <div
            className="ex-brands-container"
            style={{ columnGap: "16px", rowGap: "8px" }}
          >
            {categoriesForEmptySearch?.map((brand, index) => (
              <div
                className={`ex-brand div${index + 1} `}
                key={index}
                // onClick={() => shopBrand(brand.id, brand.slug)}
                onClick={() => navigate(brand.slug)}
              >
                <img
                  className="ex-brand-img"
                  src={brand.imageUrl}
                  alt={brand.name}
                />
                <div className="search-none-image-overlay" />
                <h4 className="txt-ex-brand" style={{ fontSize: "14px" }}>
                  {brand.name}
                </h4>
              </div>
            ))}
          </div>
          {/* <Subscribe /> */}
        </div>
      )}

      <Drawer
        placement="left"
        title="Filter"
        width="100%"
        onClose={() => setFilterDrawerOpen(false)}
        visible={filterDrawerOpen}
        className="products-filter-drawer"
      >
        {filterSectionContainer}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: "32px",
            marginTop: "24px",
          }}
        >
          <Button className="filter-drawer-btn">Apply</Button>
          <Button
            className="filter-drawer-btn"
            style={{ background: "#fff", color: "#000" }}
          >
            Clear All
          </Button>
        </div>
      </Drawer>

      <style jsx="true">{`
        .cat-text-container {
          text-align: center;
          padding-top: 3em;
          // width: 55%;
          margin-left: auto;
          // margin-right: 10rem;
        }
        .search-text-container {
          display: grid;
          place-items: center;
          margin: 78px auto 0;
          text-align: center;
          width: 80%;
          max-width: 935px;
        }
        .search-text {
          font: normal normal 35px/52px DomaineSansText-Light;
          color: #000000;
          margin-bottom: 10px;
        }
        .search-result-num {
          font: normal normal 22px/18px DomaineSansText-Light;
        }
        .no-result-text {
          font-family: DomaineSansText-Regular;
          color: #000000;
          font-size: 36px;
          font-weight: normal;
          margin-bottom: 16px;
        }
        .search-text-desc {
          font-size: 16px;
          font-family: "DomaineSansText-Light";
          color: #000000;
        }
        .ex-brands-container {
          margin: 48px auto;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 28px;
          grid-row-gap: 14px;
          width: 80%;
          max-width: 935px;
        }

        .ex-brands-container .div1 {
          grid-area: 1 / 1 / 3 / 2;
          height: 375px;
        }
        .ex-brands-container .div2 {
          grid-area: 1 / 2 / 3 / 3;
          height: 375px;
        }
        .ex-brands-container .div3 {
          grid-area: 1 / 3 / 2 / 4;
          height: 180px;
        }
        .ex-brands-container .div4 {
          grid-area: 2 / 3 / 3 / 4;
          height: 180px;
        }
        .ex-brand {
          cursor: pointer;
          height: 375px;
          text-align: center;
          position: relative;
        }
        .search-none-image-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 2;
        }
        .ex-brand-img {
          height: 100%;
          object-fit: cover;
          width: 100%;
          object-position: top;
        }
        .txt-ex-brand {
          color: white;
          position: absolute;
          top: 0;
          padding: 8px;
          font-size: 18px;
          font-weight: normal;
          font-family: "DomaineSansText-Light";
          z-index: 3;
        }
        .txt-shop-ex {
          font: normal normal 14px/18px DomaineSansText-Light;
          color: #000000;
          margin-top: 6px;
        }
        .products-container {
          display: flex;
          margin-right: 3em;
          padding-top: 2%;
        }

        .filters-container {
          flex: 0.5;
          margin-left: 2em;
          margin-right: 1em;
          font-family: "DomaineSansText-Light";
          // text-transform: uppercase;
        }
        .filter-header-title-text {
          margin-left: 8px;
          font-family: "DomaineSansText-Regular";
        }
        .products-row {
          flex: 2;
        }
        .brands-container {
          height: 200px;
          overflow-y: scroll;
        }
        .sort-container {
          align-items: flex-end;
          display: flex;
          justify-content: flex-end;
          padding-bottom: 24px;
        }
        .box {
          border-bottom: 1px solid #e6e6e6;
          // padding: 8px 0px;
          // margin-top: 16px;
        }

        .box
          .ant-collapse-ghost
          > .ant-collapse-item
          > .ant-collapse-content
          > .ant-collapse-content-box {
          padding-top: 0;
        }

        element.style {
          font-family: DomaineSansText-Regular;
          margin-top: 20px;
          font-size: 17px;
        }
        .ant-checkbox-wrapper {
          // align-items: center;
        }
        .category-filter-item .ant-checkbox-inner {
          width: 16px;
          height: 16px;
          border-radius: 50%;
        }

        .size-filter-item .ant-checkbox-wrapper + .ant-checkbox-wrapper {
          margin-left: 0;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #000;
        }
        .searchBox.ant-input-affix-wrapper > input.ant-input {
          color: #000;
        }
        .ant-slider-step {
          background: #000;
          height: 2px;
        }
        .ant-slider-handle {
          border: solid 1px #000;
        }
        .ant-slider-track {
          background: #000;
          height: 2px;
        }
        .multi-color-container {
          border: 1px solid #d4d4d4;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
        }
        .multi-color-box {
          background: transparent
            linear-gradient(231deg, #177cef 0%, #f61313 100%);
          width: 20px;
          height: 20px;
          margin-left: 10px;
          margin-right: 30px;
        }
        .color-row {
          cursor: pointer;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 20px;
          margin-bottom: 22px;
          height: 250px;
          // width: 250px;
          padding-right: 10px;
          overflow-x: hidden;
          overflow-y: scroll;
        }
        .color-row::-webkit-scrollbar {
          width: 15px;
        }
        .color-row::-webkit-scrollbar-track {
          background-color: transparent;
        }
        .color-row::-webkit-scrollbar-thumb {
          background-color: #d6dee1;
          border-radius: 20px;
          border: 6px solid transparent;
          background-clip: content-box;
        }
        .brands-container::-webkit-scrollbar {
          width: 15px;
        }
        .brands-container::-webkit-scrollbar-track {
          background-color: transparent;
        }
        .brands-container::-webkit-scrollbar-thumb {
          background-color: #d6dee1;
          border-radius: 20px;
          border: 6px solid transparent;
          background-clip: content-box;
        }
        .color-container .ant-checkbox {
          top: 0;
        }
        .color-box {
          width: 16px;
          height: 16px;
          margin-left: 0px;
          margin-right: 5px;
          border: 1px solid #ccc;
          border-radius: 50%;
        }
        .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
          )::before {
          background-color: #000 !important;
        }
        .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
          ) {
          border: 1px solid #000 !important;
          color: #000;
        }
        .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled)
          > span {
          display: flex;
          align-items: center;
        }
        .pagination-container {
          display: flex;
          justify-content: space-around;
          margin-top: 52px;
        }
        .rows-box {
          display: flex;
          justify-content: space-around;
          width: 20%;
        }
        .num-text {
          font: normal normal 14px/16px DomaineSansText-Regular;
          color: #000000;
        }
        .txt-next {
          cursor: pointer;
          font: normal 900 14px/16px DomaineSansText-Regular;
          text-decoration: underline;
        }
        .btn-num {
          background: rgba(0, 0, 0, 0.1);
          height: 30px;
          width: 80px;
        }
        .mobile-categories-container {
          padding: 16px;
          font-family: "DomaineSansText-Light";
        }
        .mob-cat-text {
          font-size: 22px;
          text-align: center;
        }
        @media(max-width:640px){
          .search-text-container{
            margin: 40px auto 0px;
            width: 100%;
            padding: 0 24px;
          }
          .no-result-text{
            font-family: 'DomaineSansText-Black';
            font-size: 18px !important;
          }
          .search-text-desc{
            font-size: 14px !important;
          }
          .ex-brands-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 12px;
            gap: 24px !important;
          }
  
          .ex-brands-container .div1,
          .ex-brands-container .div2,
          .ex-brands-container .div3,
          .ex-brands-container .div4 {
            height: auto;
          }
           
          .ex-brand {
            cursor: pointer;
            height: 375px;
            text-align: center;
            position: relative;
          }
          .ex-brand-img {
            height: 350px;
          }
          .txt-ex-brand {
            bottom: 32px;
            top: auto;
            left: 32px;
            font-size: 32px !important;
            padding: 0;
            margin: 0;
            font-family: 'DomaineSansText-Regular';
            line-height: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default SearchResult;
