import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { cartActionCreators } from "../redux/cart";
import { userActionCreators } from "../redux/user";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Collapse } from "antd";
import formatter from "../utils/formatter";
import api from "../api";
import triggerNotification from "../hooks/triggerNotification";
import LoadingIcon from "../components/LoadingIcon";
import CheckoutHeader from "../components/layout/CheckoutHeader";
import capitalizeStr from "../utils/capitalizeStr";

const { Panel } = Collapse;

const OrderCompleted = () => {
  const navigate = useNavigate();
  const query = useLocation().search;
  const txref = new URLSearchParams(query).get("txRef");
  const message = new URLSearchParams(query).get("message");

  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.cart);

  const { type } = useSelector((state) => state.user);

  const { reset, deleteCartItem } = bindActionCreators(
    cartActionCreators,
    dispatch
  );

  const resetCart = () => {
    items.map((item) => item?.cart_id && deleteCartItem(item.cart_id));
    localStorage.setItem("cartItems", null);
    reset();
  };

  const { data: currency } = useSelector((state) => state.currency);
  const { code, indicator, rate } = currency
    ? currency
    : { code: "", currency: "", rate: "" };
  const [data, setData] = useState([]);

  const getOrderDetails = async () => {
    try {
      const { data } = await api.checkout.orderReceipt(txref);
      setData(data?.data);
      const order = data?.data;
      const itemIds = order?.order_information?.map((item) =>
        item.items.map((item) => item.product_id)
      );
      const ids = itemIds.flat();
      window.fbq("track", "Purchase", {
        value: order?.order_summary?.total_amount, // Dynamic total value
        currency: code, // Dynamic currency, e.g., 'USD'
        content_ids: ids, // Dynamic array of product IDs
        content_type: "product", // Specify 'product' or 'service'
        num_items: ids.length, // Dynamic number of purchased items
        order_id: order?.order_id, // Dynamic order ID
      });
      if (message === "success") {
        resetCart();
      }
    } catch (err) {
      triggerNotification({
        type: "error",
        message: err.response?.data?.message,
      });
    }
  };

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);

    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  const { logout } = bindActionCreators(userActionCreators, dispatch);

  const signOut = () => {
    logout();
    navigate("/");
  };

  useEffect(() => {
    if (message !== "success") {
      triggerNotification({
        type: "error",
        message:
          " Your payment has not gone through on this occasion and you have not been debited. Please use a different payment option",
      });
      navigate("/checkout");
      return;
    }
    getOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  console.log("ORDER DATA", data);

  return (
    <div>
      <CheckoutHeader
        dropdownOpen={dropdownOpen}
        setDropdownOpen={setDropdownOpen}
        pageItemsLength={data?.order_information?.length}
      />

      {message === "success" ? (
        <div className="completed-container" style={{ position: "relative" }}>
          <section className="order-container-section">
            <div className="order-container">
              <div className="order-content">
                <h2>Thank you for Shopping With Losode</h2>
                <p>
                  We have sent you an order confirmation email. Once your Order
                  has been sent, you can track it in your order history
                </p>
              </div>
              <div className="order-details">
                <div className="order-details-div">
                  <h4 className="order-details-info">Order ID:</h4>
                  <h3 className="order-details-value">{data?.order_id}</h3>
                </div>

                <div className="order-details-div">
                  <h4 className="order-details-info">Order Status:</h4>
                  <div className="order-details-status">Paid</div>
                </div>
              </div>
            </div>

            <div class="buttons">
              <Button
                className="success-btn sign-out-btn"
                type="primary"
                classNames={signOut}
              >
                Sign Out
              </Button>
              <Button
                className="success-btn continue-btn"
                type="primary"
                onClick={shopNewIn}
              >
                Continue Shopping
              </Button>
            </div>
          </section>

          <div className="checkout-order-info-section">
            <div className="checkout-order-info-section-title">
              <div>Your Order Information</div>
            </div>

            <div className="checkout-order-info-products-section">
              {data?.order_information &&
                data?.order_information.map((items, index) => {
                  return (
                    <Collapse
                      defaultActiveKey={["1"]}
                      key={index}
                      className="cart-items-collapse"
                    >
                      <Panel
                        header={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="panel-store-name"
                              style={{ fontWeight: "bold" }}
                            >
                              {items?.designer}
                            </div>
                          </div>
                        }
                        key="1"
                      >
                        <div className="right-panel-section-container">
                          <div style={{ width: "100%" }}>
                            {items?.items?.map((item, idx) => (
                              <div
                                className="cart-page-bag-section-body-item"
                                key={idx}
                                style={{
                                  borderBottom: "1px solid #a7a7a7",
                                  marginBottom:
                                    idx !== items?.items?.length - 1
                                      ? "24px"
                                      : "none",
                                  width: "100%",
                                }}
                              >
                                <div className="cart-page-bag-section-body-item-main">
                                  <div
                                    className="cart-page-bag-section-body-item-image"
                                    style={{
                                      backgroundImage: `url(${item.product_img})`,
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      flex: 2,
                                    }}
                                  >
                                    <div className="cart-page-bag-section-body-item-text">
                                      <div className="cart-item-name">
                                        {capitalizeStr(item.product_name)} by{" "}
                                        {items?.designer}
                                      </div>
                                      {item.product_color &&
                                        item.product_size && (
                                          <div className="cart-item-color-size">
                                            <div
                                              style={{
                                                borderRight: "1px solid grey",
                                                paddingRight: "8px",
                                              }}
                                            >
                                              {item.product_color}
                                            </div>
                                            <div>{item.product_size}</div>
                                          </div>
                                        )}

                                      {/* Quantity changed to select for change */}
                                      <div
                                        style={{
                                          fontFamily: "DomaineSansText-Regular",
                                          fontSize: "14px",
                                        }}
                                        className="cart-item-quantity"
                                      >
                                        Qty: {item?.total_quantity}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontFamily: "DomaineSansText-Medium",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {formatter(
                                          item?.price * item?.total_quantity,
                                          rate,
                                          code,
                                          indicator
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="delivery-option">
                          <h3 className="delivery-option-title">
                            Selected Delivery Option
                          </h3>
                          <div className="delivery-option-text">
                            {items?.delivery_option} -{" "}
                            {formatter(
                              items?.delivery_amount,
                              rate,
                              code,
                              indicator
                            )}
                          </div>
                          <div className="delivery-option-text">
                            {items?.scheduled_delivery_date}
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  );
                })}
            </div>

            <div className="order-sum">
              <div className="order-sum-title">Order Summary</div>
              <div className="order-sum-item">
                <span>Sub- Total</span>{" "}
                <span>
                  {formatter(
                    data?.order_summary?.sub_total,
                    rate,
                    code,
                    indicator
                  )}
                </span>
              </div>
              <div className="order-sum-item">
                <span>Discount</span>{" "}
                <span>
                  -
                  {formatter(
                    data?.order_summary?.discount_applied,
                    rate,
                    code,
                    indicator
                  )}
                </span>
              </div>
              <div className="order-sum-item">
                <span>Delivery</span>{" "}
                <span>
                  {formatter(
                    data?.order_summary?.delivery_price,
                    rate,
                    code,
                    indicator
                  )}
                </span>
              </div>
              <div className="order-sum-item order-sum-item-total">
                <span>Total</span>{" "}
                <span>
                  {formatter(
                    data?.order_summary?.total_amount,
                    rate,
                    code,
                    indicator
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ minHeight: "40vh", display: "flex" }}>
          <LoadingIcon />
        </div>
      )}
      <style jsx="true">{`
        .completed-container {
          width: 100%;
          // margin: 0 auto;
          padding: 8rem 8rem 4rem;
          background: #f9f9f9;
          display: flex;
          flex-direction: row;
          gap: 8rem;
          position: relative;
        }
        .order-container-section {
          flex-basis: 50%;
          // position: sticky;
          top: 40px;
        }
        .order-container {
          background-color: #ffffff;
          padding: 2rem;
          height: fit-content;
        }
        .order-content {
          color: #000000;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 95%;
          margin: 0 auto;
        }
        .order-content h2 {
          color: #000000;
          font-family: "DomaineSansText-Medium";
          font-style: normal;
          font-size: 28px;
          text-align: center;
          line-height: 28px;
        }
        .order-content p {
          // max-width: 769px;
          font-family: "DomaineSansText-Regular";
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
        }
        .order-details {
          // max-width: 834px;
          margin: 1rem auto 0;
        }
        .order-details-div {
          display: flex;
          justify-content: space-between;
          padding: 0.5rem 0;
        }
        .order-details-info {
          color: #6f6f6f;
          font-family: "DomaineSansText-Bold";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
        }
        .order-details-value {
          color: #6f6f6f;
          font-family: "DomaineSansText-Light";
          font-style: normal;
          font-size: 20px;
        }
        .order-details-status {
          color: #1eae98;
          font-family: "DomaineSansText-Light";
          font-style: normal;
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          // padding: 60.5714px;
          width: 94px;
          height: 30px;
          background: rgba(30, 174, 152, 0.2);
        }

        .checkout-order-info-section {
          background: white;
          padding: 24px 2rem;
          height: fit-content;
          flex-basis: 50%;
        }
        .checkout-order-info-section-title {
          font-size: 20px;
          text-align: center;
          font-family: "DomaineSansText-Regular";
          padding: 16px;
          border-bottom: 1px solid #a7a7a7;
        }
        .checkout-order-info-products-section {
          display: flex;
          flex-direction: column;
          padding: 24px 0;
          gap: 16px;
          background: white;
          // fontFamily: "DomaineSansText-Light";
        }

        .cart-items-collapse {
          border: none;
          background-color: #f9f9f9;
          border-radius: 0;
          padding-left: 16px;
          padding-right: 16px;
        }
        .panel-store-name {
          font-family: "DomaineSansText-Medium";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
        }
        .cart-item-name {
          font-family: "DomaineSansText-Medium";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
        }
        .cart-item-color-size {
          font-family: "DomaineSansText-Light";
          display: flex;
          gap: 8px;
          font-size: 14px;
        }
        .cart-item-quantity {
          font-family: DomaineSansText-Regular;
          font-size: 14px;
        }
        .cart-items-collapse .ant-collapse-item {
          border: 0;
          border-radius: 0;
        }
        .ant-collapse-header {
          // background: #fff;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        .right-panel-section-container {
          display: flex;
          gap: 16px;
          background: transparent;
        }
        .cart-page-bag-section-body-item {
          background: transparent;
          position: relative;
          // margin-bottom: 24px;
          padding-bottom: 24px;
          // border-bottom: 1px solid #a7a7a7;
        }
        .cart-page-bag-section-body-item-main {
          display: flex;
          gap: 16px;
        }
        .quantity-select.ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 0px;
        }
        .cart-page-bag-section-body-item-image {
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          height: 125px;
          width: 90px;
        }

        .cart-page-bag-section-body-item-text {
          display: flex;
          gap: 8px;
          flex-direction: column;
          font-size: 12px;
        }

        .ant-collapse-content {
          background: transparent !important;
        }
        .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
          padding: 16px 0px;
        }
        .delivery-option {
          margin: 20px 0 10px;
        }
        .delivery-option-title {
          font-family: "DomaineSansText-Medium";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #af231c;
        }
        .delivery-option-text {
          font-family: "DomaineSansText-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 25px;
        }
        .order-sum {
          border-top: 1px solid #a7a7a7;
          // padding: 1rem 0;
        }
        .order-sum-item {
          display: flex;
          justify-content: space-between;
          font-family: "DomaineSansText-Medium";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          margin: 0.5rem 0;
        }
        .order-sum-item-total {
          border-top: 1px solid #a7a7a7;
          padding: 0.5rem 0 0;
        }

        .buttons {
          width: 100%;
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
          gap: 1rem;
          margin-top: 1rem;
        }
        .success-btn {
          text-align: center;
          // padding: 16.0432px 29.6182px;
          width: 100%;
          height: 52px;
          border: 1px solid #272727;
          box-shadow: none;
          border-radius: 0;
          font-family: "DomaineSansText-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
        }
        .sign-out-btn {
          color: #000000;
          background: #ffffff;
        }
        .sign-out-btn:hover {
          background: #000000;
          color: #ffffff;
        }
        .continue-btn {
          color: #ffffff;
          background: #000000;
        }
        .continue-btn:hover {
          color: #000000 !important;
          background-color: #ffffff !important;
        }
        .order-sum-title {
          display: none;
        }

        @media (max-width: 1024px) {
          .completed-container {
            padding: 8rem 1.5rem 4rem;
            gap: 3rem;
          }
        }

        @media (max-width: 640px) {
          .right-panel-section-container {
            flex-direction: column;
          }
          .checkout-order-info-products-section {
            padding: 24px 0px;
            background: #f9f9f9;
          }
          .completed-container {
            background: #f9f9f9;
            flex-direction: column;
            padding: 8rem 0rem 4rem;
            gap: 4rem;
          }
          .order-container-section {
            width: 90%;
            margin: 0 auto;
            // padding: 1rem;
          }
          .order-container {
            padding: 1rem;
            background: #000000;
          }
          .order-content h2 {
            font-size: 24px;
            text-align: center;
            font-family: "DomaineSansText-Regular";
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            color: #ffffff;
          }
          .order-content p {
            max-width: 100%;
            text-align: center;
            font-family: "DomaineSansText-Light";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            margin-top: 0.5rem;
            color: #ffffff;
          }
          .order-details {
            max-width: 100%;
            margin: 1.5rem 0 0.5rem;
          }
          .order-details-info,
          .order-details-value {
            font-family: "DomaineSansText-ReguLar";
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
          }
          .order-details-status {
            font-size: 16px;
            width: 109px;
            height: 28px;
            background: rgba(30, 174, 152, 0.2);
          }
          .buttons {
            width: 100%;
            align-items: center;
            gap: 1.2rem;
          }
          .success-btn {
            width: 100%;
            height: 50px;
            font-size: 14px;
          }
          .checkout-order-info-section {
            flex-basis: 100%;
            width: 90%;
            margin: 0 auto;
            padding: 0 0rem 18px;
          }
          .checkout-order-info-section-title {
            font-size: 18px;
            text-align: center;
            padding: 0 0 8px;
            background: #f9f9f9;
          }
          .cart-items-collapse {
            background-color: #ffffff;
          }
          .cart-item-name {
            font-family: "DomaineSansText-Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
          }
          .cart-item-color-size {
            font-family: "DomaineSansText-Light";
            display: flex;
            gap: 8px;
            font-size: 12px;
          }
          .cart-item-quantity {
            font-size: 12px;
          }
          .ant-collapse > .ant-collapse-item > .ant-collapse-header {
            padding: 10px 16px;
          }
          .delivery-option-text {
            font-size: 12px;
          }
          .order-sum {
            border-top: none;
          }
          .order-sum-title {
            display: block;
            width: 100%;
            background: #f9f9f9;
            padding-bottom: 0.5rem;
            font-family: "DomaineSansText-Medium";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.25px;

            color: #000000;
          }
          .order-sum-item {
            font-family: "DomaineSansText-Regular";
            font-weight: 400;
            padding-left: 16px;
            padding-right: 16px;
          }
          .order-sum-item-total {
            font-family: "DomaineSansText-Medium";
            padding-top: 1rem;
          }
        }
      `}</style>
    </div>
  );
};

export default OrderCompleted;
