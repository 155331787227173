import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const ShopBeauty = () => {
  const navigate = useNavigate();

  return (
    <div className="shopbeauty-container">
      <img
        src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732077073/womens-homepage-image-3_k6ltwa.png"
        alt="random-category"
        className="shopbeauty-image"
      />
      <div className="shopbeauty-content-container">
        <div className="text-style-main" style={{ color: "fff" }}>
          Effortless elegance, wrapped in tradition.
        </div>
        <div className="subtext">
          Feel the ease of luxurious comfort while embodying a piece of timeless
          heritage.
        </div>
        <Button
          className="btn-shop-shopbeauty"
          style={{ marginTop: "15px" }}
          onClick={() => navigate("/women/subcategory/beauty/cid=3?page=1")}
        >
          Shop Beauty
        </Button>
      </div>
      <style jsx="true">{`
        .shopbeauty-container {
          position: relative;
          background-position: top;
          margin: 24px 16px;
        }
        .shopbeauty-image {
          width: 100%;
          height: auto;
        }
        .shopbeauty-container .btn-shop-shopbeauty {
          background: #fff;
          border-radius: 2px;
          border: none;
          color: #000;
          font: normal normal 14px DomaineSansText-Light;
          width: 100%;
          height: 45px;
        }
        .shopbeauty-content-container {
          position: absolute;
          color: #fff;
          bottom: 0px;
          left: 0px;
          width: 100%;
          padding: 16px;
        }
        .shopbeauty-content-container .subtext {
          font-size: 14px;
          font-family: DomaineSansText-Light;
          max-width: 100%;
          line-height: 1.3;
        }
        .text-style-main {
          font-size: 28px;
          font-family: DomaineSansText-Medium;
          color: #fff;
          margin-bottom: 10px;
          line-height: 30px;
        }
      `}</style>
    </div>
  );
};

export default ShopBeauty;
