import { Modal, Button, Divider, Checkbox } from "antd";
import { useState } from "react";
import { userActionCreators } from "../../../redux/user";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import triggerNotification from "../../../hooks/triggerNotification";

const MailingListModalMobile = ({ visible, handleCancel, subscriberEmail }) => {
  const [selectedList, setSelectedList] = useState({
    customer_list: false,
    designer_list: false,
    general_list: false,
  });

  const mailingListOptions = [
    {
      label: "Our customer mailing list",
      subtext: "(For purchase discounts and fashion updates)",
      checked: selectedList.customer_list,
      slug: "customer_list",
    },
    {
      label: "Our designer mailing list",
      subtext:
        "(For our partner designers and brands, access to special offers and business information)",
      checked: selectedList.designer_list,
      slug: "designer_list",
    },
    {
      label: "Our general mailing list",
      subtext: "(For everyone)",
      checked: selectedList.general_list,
      slug:"general_list",
    },
  ]

  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { joinNewsLetter } = bindActionCreators(userActionCreators, dispatch);

  const handleCheck = (listType) => {
    setSelectedList((prevList) => ({
      ...prevList,
      [listType]: !selectedList[listType],
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    // Handle form submission logic here
    const values = { ...selectedList, email: subscriberEmail };
    const res = await joinNewsLetter(values);
    if (res.status === "ok") {
      handleCancel(false);
      triggerNotification({
        type: "success",
        message: "You have successfully joined our mailing list",
      });
      setIsSubmitting(false);
    }
    if (res.status === "not ok") {
      triggerNotification({
        type: "error",
        message: res.message,
      });
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      centered
      footer={null}
      width="100%"
      body={{ padding: "10" }}
      maskClosable={false}
      className="mailing-list-modal"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div
          style={{ backgroundImage: "url(/images/mailing-list-img.png)" }}
          className="mailing-list-modal-image"
        />
        <div
          style={{ flex: "1" }}
          className="mailing-list-modal-text-container"
        >
          <div style={{ textAlign: "center" }}>
            <div className="modal-title">Join Our Mailing List</div>
            <div className="modal-desc">
              Sign up to get exclusive updates, new arrivals, and inside-only
              discounts
            </div>
            <div className="modal-desc">
              Join the list you are interested in
            </div>
          </div>
          <div className="mailing-list-form">
            {mailingListOptions?.map((item, index) => (
              <div key={index}>
                <div className="mailing-list-item">
                  <div>
                    <div className="mailing-list-item-label">{item.label}</div>
                    <div className="mailing-list-modal-subtext">
                      {item.subtext}
                    </div>
                  </div>
                  <Checkbox 
                    checked={item.checked} 
                    onChange={() => handleCheck(item.slug)} 
                  />
                </div>
                <Divider
                    style={{ margin: "12px 0", borderTop: "1px solid #6f6f6f" }}
                />
              </div>
            ))}
          </div>
          <Button
            className="btn-mailing-list-submit"
            type="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Subscribe
          </Button>
        </div>
      </div>

      <style>
        {`
          .mailing-list-modal{
              font-family:DomaineSansText-Light;
          }
          .mailing-list-modal .ant-modal-body{
              padding: 0;
              height: 100%;
          }
          .mailing-list-modal .ant-modal-content{
              padding: 0px;
              border-radius: 0px;
          }
          .mailing-list-modal-image{
              width: 100%;
              height: 500px;
              flex: 1;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
          }
          .mailing-list-modal-text-container{
              padding: 24px 30px;
          }
          .mailing-list-modal .modal-title {
              font-size: 20px;
              font-family:DomaineSansText-Medium;
              line-height:31.03px;
              margin-bottom: 5px;
              text-align:center;
            }
            .mailing-list-modal .modal-desc {
              font-size: 14px;
              font-weight: 400;
              font-family:DomaineSansText-Light;
              line-height:23.27px;
              text-align:center;
              margin: 4px auto 0;
            }
            .mailing-list-form {
              margin-top: 32px;
            }
            .mailing-list-modal-subtext {
              font-size: 11px;
              font-family:DomaineSansText-Light;
              color: #6f6f6f;
            }
            .btn-mailing-list-submit {
              background: #000;
              font-size: 16px;
              font-family:DomaineSansText-Light;
              line-height: 20.69px;
              width: 100%;
              height: 48px;
              margin-top: 40px;
              border-radius: 4px;
            }
      
            .btn-mailing-list-submit:hover {
              background: #fff;
              border: 1px solid #000;
              color: #000;
            }
            .ant-modal-close-x{
              color: black
            }

            .mailing-list-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 16px;
              gap: 24px;
            }
            .mailing-list-item-label {
              font-size: 16px;
              font-family:DomaineSansText-Regular;
              text-transform: capitalize;
            }

            @media (max-width: 640px) {
              .mailing-list-modal {
                width: 100%;
              }
              .mailing-list-modal-image {
                 display: none;
              }
              .mailing-list-modal-text-container {
                padding: 40px 24px 30px;
              }
            }
         `}
      </style>
    </Modal>
  );
};

export default MailingListModalMobile;
