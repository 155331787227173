// import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StaticPageTemplate from "./StaticPageTemplate";

const Policy = () => {

  const navigate = useNavigate()

  return (
    <div>
      <StaticPageTemplate title={"Privacy Policy"}>
      <div className="static-content-container about-content">
        <h3 style={{fontFamily:'DomaineSansText-Black'}}>INTRODUCTION</h3>
        <p>
          {" "}
          Losode (“we” or “us” or “our”) respects the privacy
          of our users (“user” or “you”). This Privacy Policy explains how we
          collect, use, disclose, and safeguard your information when you visit
          our website; www.losode.com and our mobile application, including any
          other media form, media channel, mobile website, or mobile application
          related or connected thereto (collectively, the “Site”). Please read
          this privacy policy carefully. If you do not agree with the terms of
          this privacy policy, please do not access the site.
        </p>
        <p>
          We reserve the right to make changes to this Privacy Policy at any
          time and for any reason. We will alert you about any changes by
          updating the “Last Updated” date of this Privacy Policy. Any changes
          or modifications will be effective immediately upon posting the
          updated Privacy Policy on the Site, and you waive the right to receive
          specific notice of each such change or modification.
        </p>
        <p>
          You are encouraged to periodically review this Privacy Policy to stay
          informed of updates. You will be deemed to have been made aware of,
          will be subject to, and will be deemed to have accepted the changes in
          any revised Privacy Policy by your continued use of the Site after the
          date such revised Privacy Policy is posted.
        </p>

        <h3 style={{fontFamily:'DomaineSansText-Black', marginTop:'40px'}}>COLLECTION OF YOUR INFORMATION </h3>
        <p>
          We may collect information about you in a variety of ways. The
          information we may collect on the Site includes:
        </p>
        <h4>Personal Data</h4>
        <p>
          Personally identifiable information, such as your name, shipping
          address, email address, and telephone number, and demographic
          information, such as your age, gender, hometown, and interests, that
          you voluntarily give to us when you register with the Site or our
          mobile application, or when you choose to participate in various
          activities related to the Site and our mobile application, such as
          online chat. You are under no obligation to provide us with personal
          information of any kind, however your refusal to do so may prevent you
          from using certain features of the Site and our mobile application.
        </p>
        <h4> Derivative </h4>
        <p>
          {" "}
          Data Information our servers automatically collect when you access the
          Site, such as your IP address, your browser type, your operating
          system, your access times, and the pages you have viewed directly
          before and after accessing the Site. If you are using our mobile
          application, this information may also include your device name and
          type, your operating system, your phone number, your country, your
          likes and replies to a post, and other interactions with the
          application and other users via server log files, as well as any other
          information you choose to provide.
        </p>
        <h4>Financial Data </h4>
        <p>
          Financial information, such as data related to your payment method
          (e.g. valid credit card number, card brand, expiration date) that we
          may collect when you purchase, order, return, exchange, or request
          information about our services from the Site or our mobile
          application. We store only very limited, if any, financial information
          that we collect. Otherwise, all financial information is stored by our
          payment processor, Flutterwave, Paystack, Interswitch, Paypal and
          Stripe. You are encouraged to review their privacy policy and contact
          them directly for responses to your questions.{" "}
        </p>
        <h4>Data From Social Networks</h4>
        <p>
          User information from social networking sites, such as Facebook,
          Instagram, LinkedIn, Twitter, including your name, your social network
          username, location, gender, birth date, email address, profile
          picture, and public data for contacts, if you connect your account to
          such social networks. If you are using our mobile application, this
          information may also include the contact information of anyone you
          invite to use and/or join our mobile application.{" "}
        </p>
        <h4>Mobile Device Data </h4>
        <p>
          Device information, such as your mobile device ID, model, and
          manufacturer, and information about the location of your device, if
          you access the Site from a mobile device.{" "}
        </p>
        <h4>Third-Party Data </h4>
        <p>
          Information from third parties, such as personal information or
          network friends, if you connect your account to the third party and
          grant the Site permission to access this information.{" "}
        </p>
        <h4> Data From Contests, Giveaways, and Surveys </h4>
        <p>
          Personal and other information you may provide when entering contests
          or giveaways and/or responding to surveys.
        </p>
        <h4>Mobile Application Information </h4>
        <p>If you connect using our mobile application:</p>
        <ul>
          <li>
            {" "}
            Geo-Location Information. We may request access or permission to and
            track location-based information from your mobile device, either
            continuously or while you are using our mobile application, to
            provide location-based services. If you wish to change our access or
            permissions, you may do so in your device’s settings.{" "}
          </li>
          <li>
            Mobile Device Access. We may request access or permission to certain
            features from your mobile device, including your mobile device’s
            Bluetooth, calendar, camera, contacts, microphone, reminders,
            sensors, SMS messages, social media accounts, storage and other
            features. If you wish to change our access or permissions, you may
            do so in your device’s settings.
          </li>
          <li>
            Mobile Device Data. We may collect device information such as your
            mobile device ID, model and manufacturer, operating system, version
            information and IP address.
          </li>
          <li>
            Push Notifications. We may request to send you push notifications
            regarding your account or the Application. If you wish to opt-out
            from receiving these types of communications, you may turn them off
            in your device’s settings.
          </li>
        </ul>

        <h3 style={{fontFamily:'DomaineSansText-Black', marginTop:'40px'}}>USE OF YOUR INFORMATION </h3>
        <p>
          {" "}
          Having accurate information about you permits us to provide you with a
          smooth, efficient, and customised experience. Specifically, we may use
          information collected about you via the Site or our mobile application
          to:
        </p>
        <ul>
          <li>Administer promotions and contests.</li>
          <li>Assist law enforcement and respond to subpoena.</li>
          <li>
            Compile anonymous statistical data and analysis for use internally
            or with third parties.
          </li>
          <li>Create and manage your account.</li>
          <li>
            Deliver targeted advertising, coupons, newsletters, and other
            information regarding promotions and the Site and our mobile
            application to you.{" "}
          </li>
          <li>Email you regarding your account or order.</li>
          <li>Enable user-to-user communications.</li>
          <li>
            Fulfill and manage purchases, orders, payments, and other
            transactions related to the Site and our mobile application.
          </li>
          <li>
            Generate a personal profile about you to make future visits to the
            Site and our mobile application more personalized.
          </li>
          <li>
            Increase the efficiency and operation of the Site and our mobile
            application.{" "}
          </li>
          <li>
            Monitor and analyse usage and trends to improve your experience with
            the Site and our mobile application.{" "}
          </li>
          <li>
            Monitor and analyse usage and trends to improve your experience with
            the Site and our mobile application.{" "}
          </li>
          <li>Offer new products, services and/or recommendations to you.</li>
          <li>Perform other business activities as needed.</li>
          <li>
            Prevent fraudulent transactions, monitor against theft, and protect
            against criminal activity.
          </li>
          <li> Process payments and refunds. </li>
          <li>
            Request feedback and contact you about your use of the Site and our
            mobile application.
          </li>
          <li>Resolve disputes and troubleshoot problems.</li>
          <li>Respond to product and customer service requests.</li>
          <li>Send you a newsletter.</li>
          <li> Solicit support for the Site and our mobile application.</li>
        </ul>

        <h3 style={{fontFamily:'DomaineSansText-Black', marginTop:'40px'}}>DISCLOSURE OF YOUR INFORMATION </h3>
        <p>
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </p>
        <h4>By Law or to Protect Rights</h4>
        <p>
          If we believe the release of information about you is necessary to
          respond to legal process, to investigate or remedy potential
          violations of our policies, or to protect the rights, property, and
          safety of others, we may share your information as permitted or
          required by any applicable law, rule, or regulation. This includes
          exchanging information with other entities for fraud protection and
          credit risk reduction.
        </p>
        <h4>Third-Party Service Providers</h4>
        <p>
          We may share your information with third parties that perform services
          for us or on our behalf, including payment processing, data analysis,
          email delivery, hosting services, customer service, and marketing
          assistance.
        </p>
        <h4>Marketing Communications</h4>
        <p>
          With your consent, or with an opportunity for you to withdraw consent,
          we may share your information with third parties for marketing
          purposes, as permitted by law.
        </p>
        <h4>Interactions with Other Users</h4>
        <p>
          If you interact with other users of the Site and our mobile
          application, those users may see your name, profile photo, and
          descriptions of your activity, including sending invitations to other
          users, chatting with other users, liking posts, following blogs.
        </p>
        <h4>Online Postings</h4>
        <p>
          When you post comments, contributions or other content to the Site or
          our mobile applications, your posts may be viewed by all users and may
          be publicly distributed outside the Site and our mobile application in
          perpetuity.
        </p>
        <h4>Third-Party Advertisers</h4>
        <p>
          We may use third-party advertising companies to serve ads when you
          visit the Site or our mobile application. These companies may use
          information about your visits to the Site and our mobile application
          and other websites that are contained in web cookies in order to
          provide advertisements about goods and services of interest to you.
        </p>
        <h4>Affiliates</h4>
        <p>
          We may share your information with our affiliates, in which case we
          will require those affiliates to honor this Privacy Policy. Affiliates
          include our parent company and any subsidiaries, joint venture
          partners or other companies that we control or that are under common
          control with us.
        </p>
        <h4>Business Partners</h4>
        <p>
          We may share your information with our business partners to offer you
          certain products, services or promotions.
        </p>
        <h4>Offer Wall</h4>
        <p>
          Our mobile application may display a third-party hosted “offer wall.”
          Such an offer wall allows third-party advertisers to offer virtual
          currency, gifts, or other items to users in return for acceptance and
          completion of an advertisement offer. Such an offer wall may appear in
          our mobile application and be displayed to you based on certain data,
          such as your geographic area or demographic information. When you
          click on an offer wall, you will leave our mobile application. A
          unique identifier, such as your user ID, will be shared with the offer
          wall provider in order to prevent fraud and properly credit your
          account.
        </p>
        <h4>Other Third Parties</h4>
        <p>
          We may share your information with advertisers and investors for the
          purpose of conducting general business analysis. We may also share
          your information with such third parties for marketing purposes, as
          permitted by law.
        </p>
        <h4>Sale or Bankruptcy</h4>
        <p>
          {" "}
          If we reorganise or sell all or a portion of our assets, undergo a
          merger, or are acquired by another entity, we may transfer your
          information to the successor entity. If we go out of business or enter
          bankruptcy, your information would be an asset transferred or acquired
          by a third party. You acknowledge that such transfers may occur and
          that the transferee may decline honor commitments we made in this
          Privacy Policy.
          <br /> We are not responsible for the actions of third parties with
          whom you share personal or sensitive data, and we have no authority to
          manage or control third-party solicitations. If you no longer wish to
          receive correspondence, emails or other communications from third
          parties, you are responsible for contacting the third party directly.
        </p>

        <h3 id='cookies' style={{fontFamily:'DomaineSansText-Black', marginTop:'40px'}}>TRACKING TECHNOLOGIES</h3>
        <h4 >Cookies and Web Beacons</h4>
        <p>
          {" "}
          We may use cookies, web beacons, tracking pixels, and other tracking
          technologies on the Site and our mobile application to help customise
          the Site and our mobile application and improve your experience. When
          you access the Site or our mobile application, your personal
          information is not collected through the use of tracking technology.
          Most browsers are set to accept cookies by default. You can remove or
          reject cookies, but be aware that such action could affect the
          availability and functionality of the Site or our mobile application.
          You may not decline web beacons. However, they can be rendered
          ineffective by declining all cookies or by modifying your web
          browser’s settings to notify you each time a cookie is tendered,
          permitting you to accept or decline cookies on an individual basis.
          <br /> We may use cookies, web beacons, tracking pixels, and other
          tracking technologies on the Site and our mobile application to help
          customize the Site and our mobile application and improve your
          experience. By using the Site, you agree to be bound by our Cookie
          Policy.
        </p>
        <h4>Internet-Based Advertising</h4>
        <p>
          {" "}
          Additionally, we may use third-party software to serve ads on the Site
          and our mobile application, implement email marketing campaigns, and
          manage other interactive marketing initiatives. This third-party
          software may use cookies or similar tracking technology to help manage
          and optimize your online experience with us. Website Analytics We may
          also partner with selected third-party vendors, such as Crazy Egg,
          Google Analytics, and others, to allow tracking technologies and
          remarketing services on the Site and our mobile application through
          the use of first party cookies and third-party cookies, to, among
          other things, analyse and track users’ use of the Site and our mobile
          application , determine the popularity of certain content and better
          understand online activity. By accessing the Site or our mobile
          application, you consent to the collection and use of your information
          by these third-party vendors. You are encouraged to review their
          privacy policy and contact them directly for responses to your
          questions. We do not transfer personal information to these
          third-party vendors. <br />
          However, if you do not want any information to be collected and used
          by tracking technologies, you can visit the third-party vendor. You
          should be aware that getting a new computer, installing a new browser,
          upgrading an existing browser, or erasing or otherwise altering your
          browser’s cookies files may also clear certain opt-out cookies,
          plug-ins, or settings.
        </p>

        <h3 style={{fontFamily:'DomaineSansText-Black', marginTop:'40px'}}>THIRD-PARTY WEBSITES</h3>
        <p>
          The Site and our mobile application may contain links to third-party
          websites and applications of interest, including advertisements and
          external services, that are not affiliated with us. Once you have used
          these links to leave the Site or our mobile application, any
          information you provide to these third parties is not covered by this
          Privacy Policy, and we cannot guarantee the safety and privacy of your
          information. Before visiting and providing any information to any
          third-party websites, you should inform yourself of the privacy
          policies and practices (if any) of the third party responsible for
          that website, and should take those steps necessary to, in your
          discretion, protect the privacy of your information. We are not
          responsible for the content or privacy and security practices and
          policies of any third parties, including other sites, services or
          applications that may be linked to or from the Site or our mobile
          application.
        </p>

        <h3 style={{fontFamily:'DomaineSansText-Black', marginTop:'40px'}}>SECURITY OF YOUR INFORMATION</h3>
        <p>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse. Any information
          disclosed online is vulnerable to interception and misuse by
          unauthorized parties. Therefore, we cannot guarantee complete security
          if you provide personal information.
        </p>

        <h3 style={{fontFamily:'DomaineSansText-Black', marginTop:'40px'}}>POLICY FOR CHILDREN</h3>
        <p>
          We do not knowingly solicit information from or market to children
          under the age of 11. If you become aware of any data, we have
          collected from children under age 11, please contact us using the
          contact information provided below.
        </p>

        <h3 style={{fontFamily:'DomaineSansText-Black', marginTop:'40px'}}>OPTIONS REGARDING YOUR INFORMATION</h3>
        <h4>Account Information</h4>
        <p>
          You may at any time review or change the information in your account
          or terminate your account by:
        </p>
        <ul>
          <li>Signing into your account settings and updating your account</li>
          <li>Contacting us using the contact information provided below</li>
        </ul>
        
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, some information may be retained in our files to prevent
          fraud, troubleshoot problems, assist with any investigations, enforce
          our Terms of Use and/or comply with legal requirements.
        </p>
        <h4>Emails and Communications</h4>
        <p>
        If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by going to our <span onClick={() => navigate("/contact-us")} style={{fontWeight:'bold', cursor:"pointer", fontFamily:'DomaineSansText-Black', textDecoration:'underline'}}>contact us</span> page 
        </p>
        
      </div>

   
      </StaticPageTemplate>

      <style jsx="true">{`
        .p-list {
          margin: 0;
        }
        .list-promise,
        .list-goals {
          padding-inline-start: 17px;
        }
        .list-goals {
          margin-top: 15px;
        }
        .promise,
        .goal,
        .txt-sellers-desc {
          font-family: DomaineSansText-Light;
        }
        .txt-sellers,
        .txt-promise {
          font: normal 600 16px/24px DomaineSansText-Light;
        }
        .footer-container {
          padding: 40px;
        }
        .details-container {
          width: 85%;
          margin-left: 70px;
          margin-right: auto;
          margin-top: 8em;
          margin-bottom: 8em;
        }
        .collapse-container {
          padding-top: 1em;
        }

        .txt-detail-desc {
          background: #f3f3f3;
          font: normal normal 20px/22px DomaineSansText-Light;
          padding: 20px 164px 28px 36px;
          text-align: justify;
        }
        @media (max-width: 640px) {
          
          .about-content h3,
          .about-content h4 {
            font-size: 15px;
            color: #000;
            font-weight: 700;
          }
          .text-container {
            padding: 20px 10% 0;
          }
          .title-div-about {
            height: 124px;
          }
          .questions-container {
            margin: 40px 7%;
            width: auto;
          }
          .sm-txt {
            font-size: 11px;
            margin: 0;
            font-family: DomaineSansText-Light;
          }
        }
      `}</style>
    </div>
  );
};



export default Policy;
