import { createRef } from "react";
import { Carousel } from "antd";

const Quality = () => {

  const carouselSettings = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // dots: false,
    infinite: false,
  };

  const carouselRef = createRef();

  const items = [
    {
      title: "Quick And Secure Deliveries",
      desc: "At Losode, we have carefully optimised our logistics processes to ensure fast, reliable, and hassle-free delivery. Shop with confidence, knowing your orders are handled with efficiency and care every step of the way.",
      // url: "/images/icon-truck.svg",
      url: "/images/quick-delivery-new.svg",
    },
    {
      title: "Fair Returns Policy",
      desc: "At Losode, our designers uphold fair, transparent policies that safeguard your purchases, providing you with peace of mind. From secure transactions to customer-first returns and exchanges, we ensure a safe and reliable shopping experience.",
      // url: "/images/icon-returns.svg",
      url: "/images/returns-policy-new.svg",
    },
    {
      title: "Easy to Use Platform",
      desc: "We have built a user-friendly platform that’s easy to navigate, making your shopping experience seamless and enjoyable. Discover a simple and efficient way to browse, shop, and connect with top designers on Losode.",
      // url: "/images/icon-search.svg",
      url: "/images/easy-to-use.svg",
    },
    {
      title: "24/7 Support",
      desc: "Visit our Contact Us page to find all the ways you can reach us. Whether you have questions, feedback, or need assistance, we are here to help. Get in touch with Losode today!",
      // url: "/images/icon-support.svg",
      url: "/images/support-new.svg",
    },
  ];

  return (
    <div className="quality-container">
      <h1 className="quality-title">Shop With Confidence On Losode</h1>
      <h4 className="quality-desc">
        What You See Is What You Get. At Losode, we know how important trust is
        when shopping online. That is why we ensure every purchase meets, and
        exceeds, your expectations. If anything falls short, our team is ready
        to make it right, quickly and hassle-free. Shop with confidence and
        discover quality fashion, guaranteed.
      </h4>
      
      <div className="quality-line-container">
        <span className="quality-line-span" />
      </div>

      <div style={{ marginTop: "32px", marginBottom: "40px" }}>
        <Carousel {...carouselSettings} ref={carouselRef} >
          {items.map((item, idx) => (
            <div>
              <div className="quality-item-div" key={idx}>
                <img
                  className="quality-icon"
                  alt={item.title}
                  src={item.url}
                />
                <h1 className="quality-item-title">{item.title}</h1>
                <h4 className="quality-item-desc">{item.desc}</h4>
              </div>
            </div>
          ))}
        </Carousel>
      </div>


      <style jsx="true">{`
        .quality-container {
          padding: 16px;
          text-align: center;
        }
        .quality-title {
          font-family: "DomaineSansText-Medium";
          font-size: 28px;
          margin-bottom: 6px;
          text-transform: uppercase;
          line-height: 1.2;
        }
        .quality-desc {
          font: normal 300 14px DomaineSansText-Light;
          line-height: 1.4;
          margin: 0 auto;
          margin-bottom: 40px;
        }
        .quality-item-div {
          align-items: center;
          display: flex;
          flex-direction: column;
        }
        .quality-icon {
          width: 32px;
          height: 32px;
        }
        .quality-item-title {
          font: normal 700 18px DomaineSansText-Black;
          line-height: normal;
          text-align: center;
          margin-top: 8px;
          text-transform: capitalize;
        }
        .quality-item-desc {
          font: normal 300 16px DomaineSansText-Regular;
          line-height: normal;
          text-align: center;
        }
        .quality-line-container {
        }

        .quality-line-span {
          display: block;
          border-bottom: 0.5px solid #ccc;
          width: 100%;
          margin: 8px auto;
        }

        .quality-container .ant-carousel .slick-dots-bottom button{
            height: 10px;
            width: 10px;
            background: #656565;
            border-radius: 50%;
          }
          .quality-container .ant-carousel .slick-dots li.slick-active button{
            background: black;
          }
          .quality-container .ant-carousel .slick-dots-bottom button:before{
            display: none;
          }
          
          .quality-container .ant-carousel .slick-dots-bottom {
            bottom: -20px !important;
          }

          .quality-container .ant-carousel .slick-dots li.slick-active{
            width: 16px;
          }

        
      `}</style>
    </div>
  );
};

export default Quality;
