import { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import triggerNotification from '../../hooks/triggerNotification';
// import { useMediaQuery } from 'react-responsive';

const ModalForEmailCollection = ({showModal, setShowModal, promoName, showOnPageLoad=false }) => {
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

    // const navigate = useNavigate()

    const onFinish = async (values) => {
        setSubmitting(true);
        console.log('Form submitted:', values);
        try {
            const resp = await axios.post(
            `https://losode.dev/api/v2/new/ten/subscriber/store`,
            {
              email: values.email,
            }
          );
          if (resp?.status === 200) {
            setEmailSubmitted(true);
            window.localStorage.setItem(promoName, "true");
            triggerNotification({
              type: "success",
              message: resp?.data?.message || "Submission successful",
            });
            setSubmitting(false);
          }
          
        } catch (error) {
          console.error("Error occurred:", error);
          triggerNotification({
            type: "error",
            message: error?.response?.data?.message || "An error occurred",
          });
          setSubmitting(false);
          
        }
        
    };
    
    const closeModal = () => {
      setShowModal(false);
    }

    useEffect(() => {
      if(showOnPageLoad && (!window.localStorage.getItem(promoName) || window.localStorage.getItem(promoName) === "false")){
        setShowModal(true);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

  return (<>
    {showModal && (
        
        <div className="mfe-overlay">
        {!emailSubmitted ? 
        <div className="mfe-container">
            <img 
            src={"https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1739551308/website-pictures-optimized/emailCollectionImage_bthuk2.png"} 
            className="mfe-img" alt="mfe-modal" />
            <div className="mfe-content">
                <h1 className='mfe-title'>Get 10% off your First <br/> Order on Losode</h1>
                <p className='mfe-subtitle'>Enter your email address to unlock your exclusive discount</p>
                {/* <p className='mfe-info'>Enter your email address to unlock your exclusive discount.</p> */}
            </div>
            <div className="mfe-form-container">
            <Form onFinish={onFinish} className="mfe-form">
                <Form.Item 
                className='mfe-btn-contain'
                name="email"
                rules={[
                    {
                        type: "email",
                        message: "The input is not valid E-mail!",
                    },
                    {
                        required: true,
                        message: "Please enter your E-mail!",
                    },
                    ]}
                >
                    <Input className="input" placeholder="Enter Your Email Address" />
                </Form.Item>

                <Form.Item className='mfe-btn-contain'>
                    <Button className="mfe-btn" type="primary" htmlType="submit" disabled={submitting}>
                      Unlock My Discount
                    </Button>
                </Form.Item>
            </Form>
            </div>
            <Button
            className="mfe-modal-cancel"
            shape="circle"
            onClick={closeModal}
            icon={
              <svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="27" cy="27" r="27" fill="white"/>
              <path d="M34.7137 19.2812L19.2852 34.7098" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M19.2852 19.2812L34.7137 34.7098" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
                         
            }
            />
        </div>
       :
         <div className="mfe-container mfe-notif">
         <div className="mfe-content">
          <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="43" cy="43" r="42.3621" fill="black" stroke="#FAFAFA" strokeWidth="1.27586"/>
            <path d="M59.9831 30.2578L36.6251 53.6158L26.0078 42.9986" stroke="white" strokeWidth="2.74074" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <h1 className='mfe-title mfe-notif-title'>An email with your exclusive discount code has been sent to you</h1>
         </div>
         <div className="mfe-content" style={{textAlign:'left'}}>
          {/* <Checkbox 
              checked={item.checked} 
              onChange={() => handleCheck(item.slug)} 
            >
            <span style={{color:"white", fontFamily:"DomaineSansText-Light", fontSize:'16px'}}>Subscribe to the Losode Newsletter</span>
          </Checkbox> */}
          </div>
         <Button
         className="mfe-modal-cancel mfe-notif-cancel"
         shape="circle"
         onClick={closeModal}
         icon={
         <svg viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
             <circle cx="29" cy="29" r="28" fill="white" stroke="black" strokeWidth="2"/>
             <path d="M36.7137 21.2812L21.2852 36.7098" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
             <path d="M21.2852 21.2812L36.7137 36.7098" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
         </svg>                
         }
         />
     </div>
        } 
      <style jsx="true">{`
          .mfe-overlay {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(0, 0, 0, 0.5);
              z-index: 1500;
            }
          .mfe-container {
            // height: 600px;
            background: #F9F9F9;
            position: relative;
            font-family:DomaineSansText-Light;
            max-height: 90%;
            max-width: 50%;
            min-width: 656px;
            width: auto;
          }
          .mfe-img {
            width: 100%;
            // height: 55%;
            height: 22vw;
            object-fit:cover;
            // max-height: 14vw;
            min-height: 190px;
            height: auto;
          }
          .mfe-content {
            text-align: center;
            color: #000000;
            padding: 18px 0 4px;
            width: 84%;
            margin: 0 auto;
          }
          .mfe-content .mfe-title {
            font: normal 500 36px DomaineSansText-Medium;
            text-align: center;
            // letter-spacing: 0.03em;
            line-height: 45px;
            margin: 0 auto;
            padding: 0;
            margin-bottom: 15px;
            line-height: 1;
          }
          .mfe-content .mfe-subtitle {
            font: normal 400 16px DomaineSansText-Light;
            text-align: center;
            padding: 0 0 4px;
            margin: 0 auto;
            // font-size: 22px;
            max-width: 500px;
          }
          .mfe-content .mfe-subtitle-bg-black {
            font: normal 400 28px DomaineSansText-Light;
            text-align: center;
            padding: 0 0 4px;
            margin: 0;
            font-size: clamp(16px, 2vh, 20px);
          }
        .mfe-content .mfe-info {
            font: normal 400 20px DomaineSansText-Light;
            text-align: center;
            padding: 16px 0 4px;
            margin: 0;
            font-size: clamp(16px, 2vh, 20px);
          }
          .mfe-form-container {
            padding: 0 20px;
          }
          .mfe-form {
            display: flex;
            justify-content: center;
            padding-top: 20px;
            padding-bottom: 20px;
          }
          .input {
            width: 380px;
            height: 45px;
            background: #FFFFFF;
            border: 1px solid rgb(143, 139, 139);
            margin-right: 5px;
            font-size: 20px;
            border-radius: 4px;
            font-family: DomaineSansText-Light;
            font-size: clamp(16px, 2vh, 16px);
          }
          .input:placeholder {
            font: normal 400 20px DomaineSansText-Light;
            color: #92959E;
            font-size: clamp(16px, 2vh, 20px);
          }
          .mfe-btn {
            width: auto;
            height: 45px;
            font: normal 400 16px DomaineSansText-Light;
            margin-left: 5px;
            border-radius: 4px;
            font-size: clamp(16px, 2vh, 20px);
            box-shadow: none;
          }
          .mfe-modal-cancel {
            position: absolute;
            right: 9px;
            top: 6%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
          }
          .mfe-modal-cancel svg {
            width: 40px;
            height: 40px;
          }

          .mfe-shop-link{
            font: normal 400 20px DomaineSansText-Regular;
            color: #000000;
            margin-top: 10px;
          }

          .mfe-shop-link span{
            text-decoration: underline !important;
          }

           .mfe-shop-link:hover, .mfe-shop-link:active, .mfe-shop-link:visited, .mfe-shop-link:focus{
           color: black !important;
           }

          .mfe-container .ant-form-item-explain-error{
            margin-top:4px          
          }
          
          .mfe-code{
            color: rgb(207, 158, 78);
            font-family: DomaineSansText-Black;
            font-size: 24px;
          }

          .mfe-notif{
            padding: 60px 40px;
            background: #000000;
            color: white;
          }
          .mfe-content .mfe-notif-title{
            font-family: DomaineSansText-Regular;
            font-size: 24px;
            text-align: center;
            color: white;
            margin-top: 24px;
            margin-bottom: 40px;
            line-height: 1.4;
          }
          .mfe-content .mfe-notif-subtitle{
            font: normal 400 16px DomaineSansText-Light;
            text-align: left;
            color: white;
            padding-bottom: 30px;
          }
          .mfe-notif-cancel{
            top: 12% !important
          }

          .mfe-notif .ant-checkbox-inner {
            background: black;
            border: 1px solid white;
            border-color: white;
            outline: 1px solid white;
          }

          @media(min-width:640px) and (max-width: 1024px) {
            .mfe-container {
              width: 95%;
            }
            .mfe-img {
              height: 50%;
            }
            .mfe-content h1 {
              font: normal 500 42px DomaineSansDisplay-Bold;
            }
            .mfe-content p {
              font: normal 400 20px DomaineSansText-Regular;
            }
            .input {
              width: 100%;
              background: #FFFFFF;
              border: 1px solid #000000;
              margin-right: 5px;
            }
            .mfe-btn {
              width: auto;
              margin-left: 5px;
            }
          }
          @media(max-width:640px){
            .mfe-container {
              width: 95%;
              max-width: none;
              min-width: auto;
            }
            .mfe-content {
              padding: 20px 0 4px;
            }
            .mfe-content .mfe-title {
            font: normal 500 24px DomaineSansText-Medium;
            line-height: 1.2;
          }
          .mfe-content .mfe-subtitle {
            font: normal 400 14px DomaineSansText-Light;
            max-width: 100%;
          }
          .mfe-notif{
            padding: 20px 0;
            background: #000000;
            color: white;
          }
          .mfe-content .mfe-notif-title{
            font-family: DomaineSansText-Regular;
            font-size: 24px;
            text-align: center;
            color: white;
            margin-top: 24px;
          }
          .mfe-content .mfe-notif-subtitle{
            font: normal 400 16px DomaineSansText-Light;
            text-align: left;
            color: white;
            padding-bottom: 30px;
          }
          .mfe-notif-cancel{
            top: 7% !important;
            right: 0px !important;
          }
            .mfe-img {
              height: 35%;
              max-height: none;
              min-height: auto;
            }
            .mfe-content h1 {
              font: normal 500 30px DomaineSansDisplay-Bold;
            }
            .mfe-content p {
              font: normal 400 16px DomaineSansText-Regular;
            }
            .mfe-form-container {
                padding: 0px;
              }
            .mfe-form {
              flex-direction: column;
              align-items: center;
              padding-top: 10px;
            }
            .input {
              width: 100%;
              height: 48px;
              margin-right: 0px;
              font-size: 14px;
            }
            .input:placeholder {
              font-size: 114px;
            }
            .mfe-btn {
              width: 100%;
              margin-left: 0px;
              height: 48px;
              font-size: 14px;
            }
            .mfe-content .mfe-info {
                font-size: 16px;
              }
            .mfe-content .mfe-subtitle-bg-black{
              font-size: 16px;
            }
              .mfe-content .mfe-info{
                margin-bottom: -16px;
              }
            .mfe-btn-contain{
              width: 100%;
              padding: 10px 20px 0px;
              margin: 0;
            }
              .mfe-shop-link{
                font-size: 16px;
              }
              .mfe-code{
                font-size: 24px;
              }
            .mfe-modal-cancel {
              position: absolute;
              right: -8px;
              top: 3%;
              width: 24px !important;
              height: 24px !important;
              min-width: auto !important;
            }
            .mfe-modal-cancel svg {
              width: 24px;
              height: 24px;
            }
              .mfe-shop-link span{
                margin-top: 10px;
              }
                .mfe-break-subtitle{
                  display: none;
                }
          }
      `}</style>
  </div>
    )}
  </>
  )
}

export default ModalForEmailCollection