import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const NewInMenu = ({ visible, showNewInMenu, hideNewInMenu }) => {
  const navigate = useNavigate();

  const { newInMenu: data } = useSelector((state) => state.products);

  const categories = data?.categories;
  const designers = data?.designers;
  // const shop_by_brand = data?.shop_by_brand;

  const shopCategory = (id, slug) => {
    navigate(`/products/category/cid=${id}&name=${slug}?page=1`);
    hideNewInMenu();
  };
  const shopBrands = (slug) => {
    navigate(`/designers/${slug}`);
    hideNewInMenu();
  };

  return (
    <div className="new-in-menu-container">
      <div className="menu-overlay" />
      <div
        className="new-in-content"
        onMouseEnter={() => showNewInMenu()}
        onMouseLeave={() => hideNewInMenu()}
      >
        <div className="subs-design-cat-container">
          <div className="subs-cat">
            <h3 className="cat-title">New In</h3>
            {categories?.slice(0, 8).map((sub) => (
              <p
                className="sub-item"
                key={sub.id}
                onClick={() => shopCategory(sub.id, sub.slug)}
              >
                {sub.catname}
              </p>
            ))}
          </div>
          <div className="designers-cat">
            <h3 className="cat-title">Designers</h3>
            {designers?.slice(0, 8).map((designer) => (
              <p
                className="sub-item"
                key={designer.id}
                onClick={() => shopBrands(designer.slug)}
              >
                {designer.brandName}
              </p>
            ))}
          </div>
        </div>

        {/* <div className="shop-by-cat">
          <h3 className="cat-title">Shop By brand</h3>
          {shop_by_brand?.map((shop) => (
            <p
              className="sub-item"
              key={shop.id}
              onClick={() => shopBrands(shop.id, shop.slug)}
            >
              {shop.brandName}
            </p>
          ))}
        </div> */}
        <div className="cat-img-container">
          {/* <div className="menu-divider"></div> */}
          <div>
            <img
              className="cat-img"
              src={`https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1642599041/website-pictures-optimized/men-page-10_ewtmgv_lst2ps.png`}
              alt="brand"
            />
            <p
              className="txt-featured-brand"
              style={{ marginBottom: "4px", fontSize: "18px", color: "#000" }}
            >
              Shop with ease
            </p>
            <p className="txt-shop-featured-brand">Styles for men and women</p>
          </div>
        </div>

        <style jsx="true">{`
          .menu-overlay {
            position: fixed;
            left: 0px;
            top: 214px;
            height: 100%;
            width: 100%;
            background-color: black;
            opacity: 0.5;
            z-index: 998;
          }
          .new-in-menu-container {
            display: ${visible};
            width: 80%;
          }

          .new-in-content {
            display: flex;
            justify-content: space-between;
            min-height: 50vh;
            // margin-left: 3%;
            background: #ffffff;
            z-index: 999;
            padding: 40px 50px;
            position: absolute;
            width: 100%;
          }
          .subs-design-cat-container {
            display: flex;
            flex-basis: 60%;
            border-right: 1px solid #d4d4d4;
          }
          .sub-item {
            cursor: pointer;
            font-family: DomaineSansText-Light;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            line-height: 1.1;
            height: auto !important;
            margin: 0px;
            padding: 8px 0;
          }
          .sub-item:hover {
            color: #800000;
          }
          .cat-title {
            color: #000;
            font: normal bold 14px/18px DomaineSansText-Black;
            text-transform: uppercase;
            margin-bottom: 0 !important;
          }
          .cat-img {
            object-fit: cover;
            object-position: center;
            height: 282px;
            width: 352px;
          }
          .subs-cat {
            // width: 150px;
            flex-basis: 35%;
          }
          .designers-cat {
            // width: 150px;
            flex-basis: 35%;
            display: flex;
            flex-direction: column;
            align-items: start;
            // align-content: center;
            flex-wrap: wrap;
            // border-left: 1px solid #a5a5a5;
          }
          .shop-by-cat {
            flex-basis: 20%;
          }
          .txt-shop-featured-brand {
           font-size: 14px;
          }
        `}</style>
      </div>
    </div>
  );
};

export default NewInMenu;
