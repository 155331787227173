import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
// import { useMediaQuery } from "react-responsive";

const { Sider } = Layout;
// const { SubMenu } = Menu;

const BuyerSideNav = ({ currentPageKey }) => {
  const navigate = useNavigate();
  //   const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div>
      <Sider
        className="sider buyer-sidenav"
        style={{ paddingTop: 16, height: "75vh" }}
      >
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[currentPageKey]}>
          <Menu.Item
            className="menu-item"
            key="7"
            onClick={() => navigate("/account")}
            icon={
              <svg
                width="22"
                height="22"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6667 6.18065V3.15398C14.6667 2.21398 14.24 1.83398 13.18 1.83398H10.4867C9.42667 1.83398 9 2.21398 9 3.15398V6.17398C9 7.12065 9.42667 7.49398 10.4867 7.49398H13.18C14.24 7.50065 14.6667 7.12065 14.6667 6.18065Z"
                  fill="#800000"
                />
                <path
                  d="M14.6667 13.68V10.9867C14.6667 9.92667 14.24 9.5 13.18 9.5H10.4867C9.42667 9.5 9 9.92667 9 10.9867V13.68C9 14.74 9.42667 15.1667 10.4867 15.1667H13.18C14.24 15.1667 14.6667 14.74 14.6667 13.68Z"
                  fill="black"
                />
                <path
                  d="M6.9987 6.18065V3.15398C6.9987 2.21398 6.57203 1.83398 5.51203 1.83398H2.8187C1.7587 1.83398 1.33203 2.21398 1.33203 3.15398V6.17398C1.33203 7.12065 1.7587 7.49398 2.8187 7.49398H5.51203C6.57203 7.50065 6.9987 7.12065 6.9987 6.18065Z"
                  fill="black"
                />
                <path
                  d="M6.9987 13.68V10.9867C6.9987 9.92667 6.57203 9.5 5.51203 9.5H2.8187C1.7587 9.5 1.33203 9.92667 1.33203 10.9867V13.68C1.33203 14.74 1.7587 15.1667 2.8187 15.1667H5.51203C6.57203 15.1667 6.9987 14.74 6.9987 13.68Z"
                  fill="#800000"
                />
              </svg>
            }
          >
            Dashboard
          </Menu.Item>
          <Menu.Item
            className="menu-item"
            key="5"
            onClick={() => navigate("/settings")}
            icon={
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.1188 13.2805C12.0488 13.2705 11.9588 13.2705 11.8788 13.2805C10.1188 13.2205 8.71875 11.7805 8.71875 10.0105C8.71875 8.20047 10.1787 6.73047 11.9988 6.73047C13.8088 6.73047 15.2788 8.20047 15.2788 10.0105C15.2688 11.7805 13.8788 13.2205 12.1188 13.2805Z"
                  stroke="#292D32"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.7417 19.8805C16.9617 21.5105 14.6017 22.5005 12.0017 22.5005C9.40172 22.5005 7.04172 21.5105 5.26172 19.8805C5.36172 18.9405 5.96172 18.0205 7.03172 17.3005C9.77172 15.4805 14.2517 15.4805 16.9717 17.3005C18.0417 18.0205 18.6417 18.9405 18.7417 19.8805Z"
                  stroke="#292D32"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                  stroke="#880000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          >
            Account Details
          </Menu.Item>
          <Menu.Item
            className="menu-item"
            onClick={() => navigate("/saved-items")}
            key="2"
            icon={
              <svg
                width="22"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0174 15.5H7.99304L0 7.70092V2.86976L2.4287 0.5H7.38L8.35565 1.45452L7.43478 2.35305L6.84 1.7727H2.9687L1.30435 3.39666V7.17402L8.53304 14.2273H9.47739L16.6957 7.18166V3.40429L15.0287 1.77779L11.1626 1.78033L5.11043 7.70346L4.18696 6.80494L10.62 0.510182L15.5687 0.505091L18 2.87994V7.70855L10.0174 15.5Z"
                  fill="#6B1214"
                />
              </svg>
            }
          >
            Saved Items
          </Menu.Item>
          <Menu.Item
            className="menu-item"
            key="1"
            onClick={() => navigate("/orders")}
            icon={
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 8.75V18.5C20 21.5 18.21 22.5 16 22.5H8C5.79 22.5 4 21.5 4 18.5V8.75C4 5.5 5.79 4.75 8 4.75C8 5.37 8.24997 5.93 8.65997 6.34C9.06997 6.75 9.63 7 10.25 7H13.75C14.99 7 16 5.99 16 4.75C18.21 4.75 20 5.5 20 8.75Z"
                  stroke="#800000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 4.75C16 5.99 14.99 7 13.75 7H10.25C9.63 7 9.06997 6.75 8.65997 6.34C8.24997 5.93 8 5.37 8 4.75C8 3.51 9.01 2.5 10.25 2.5H13.75C14.37 2.5 14.93 2.75 15.34 3.16C15.75 3.57 16 4.13 16 4.75Z"
                  stroke="#800000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 13.5H12"
                  stroke="#292D32"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 17.5H16"
                  stroke="#292D32"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          >
            My Orders
          </Menu.Item>

          {/* <Menu.Item
            className="menu-item"
          
            onClick={() => navigate("/losode-credits")}
            key="4"
            icon={
              <svg
                width="22"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.9004 16.9C19.6004 14.2 19.6004 9.7 16.9004 7"
                  stroke="#800000"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.09922 7.09961C4.39922 9.79961 4.39922 14.2996 7.09922 16.9996"
                  stroke="#800000"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.69922 13.5998C9.69922 14.4998 10.3992 15.1998 11.2992 15.1998H12.9992C13.6992 15.1998 14.3992 14.5998 14.3992 13.7998C14.3992 12.8998 13.9992 12.5998 13.4992 12.3998L10.6992 11.3998C9.99922 11.2998 9.69922 10.9998 9.69922 10.1998C9.69922 9.3998 10.2992 8.7998 11.0992 8.7998H12.8992C13.7992 8.7998 14.4992 9.4998 14.4992 10.3998"
                  stroke="#17191C"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 7.7998V16.1998"
                  stroke="#17191C"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#800000"
                  stroke-width="0.7"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          >
            Losode Credits
          </Menu.Item> */}

          <Menu.Item
            className="menu-item"
            onClick={() => navigate("/address")}
            key="3"
            icon={
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 8V18C20 21 18.6667 23 15.5556 23H8.44444C5.33333 23 4 21 4 18V8C4 5 5.33333 3 8.44444 3H15.5556C18.6667 3 20 5 20 8Z"
                  stroke="#800000"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 12.5H4"
                  stroke="#800000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 8.5H4"
                  stroke="#800000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 16.5H4"
                  stroke="#800000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 11.5C13.1046 11.5 14 10.6045 14 9.49997C14 8.3954 13.1046 7.5 12 7.5C10.8954 7.5 10 8.3954 10 9.49997C10 10.6045 10.8954 11.5 12 11.5Z"
                  stroke="#292D32"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 17.5C16 15.8435 14.21 14.5 12 14.5C9.79 14.5 8 15.8435 8 17.5"
                  stroke="#292D32"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          >
            My Addresses
          </Menu.Item>

          <Menu.Item
            className="menu-item"
            onClick={() => navigate("/email-preferences")}
            key="6"
            icon={
              <svg
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.582 17.9375H6.41536C3.66536 17.9375 1.83203 16.625 1.83203 13.5625V7.4375C1.83203 4.375 3.66536 3.0625 6.41536 3.0625H15.582C18.332 3.0625 20.1654 4.375 20.1654 7.4375V13.5625C20.1654 16.625 18.332 17.9375 15.582 17.9375Z"
                  stroke="#800000"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.5846 7.875L12.7155 10.0625C11.7713 10.78 10.2221 10.78 9.27796 10.0625L6.41797 7.875"
                  stroke="#292D32"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          >
            Email Preferences
          </Menu.Item>
          {/* <Menu.Item
            className="menu-item"
            key="7"
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </Menu.Item> */}
        </Menu>
      </Sider>

      <style>
        {`
            .buyer-sidenav{
              font-family: 'DomaineSansText-Light';
            }

            .ant-menu-dark.ant-menu-inline, 
            .ant-menu-dark.ant-menu-vertical, 
            .ant-menu-dark.ant-menu-vertical-left, 
            .ant-menu-dark.ant-menu-vertical-right{
                background: white;
            }

            .ant-menu-item .ant-menu-item-icon + span, 
            .ant-menu-submenu-title .ant-menu-item-icon + span, 
            .ant-menu-item .anticon + span, 
            .ant-menu-submenu-title .anticon + span, .ant-menu-dark .ant-menu-item-selected 
            .ant-menu-item-icon + span, .ant-menu-dark
            .ant-menu-item-selected .anticon + span{
                color: black;
            }
            .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
                background-color: rgba(0, 0, 0, 0.04);
            }
            .ant-layout-sider{
                background:white;
                border-right: 1px solid #e4e4e4;
                height:100%;
                position:absolute

            }

            .ant-menu-dark.ant-menu-inline .ant-menu-item,
            .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
              align-items: center; 
              color:#000;
              display: flex;
              padding: 16px;
              width: 100%;
            }
        
            .ant-menu-dark .ant-menu-sub,
            .ant-menu.ant-menu-dark .ant-menu-sub {
              background: #fff;
              color: #fff;
            }
            .ant-menu.ant-menu-dark .sub-item {
              background: #252525;
              color: #fff;
              margin: 1px;
            }
          
           .ant-menu-submenu-arrow::before,.ant-menu-submenu-arrow::after {
              background: #000 !important;
              margin:20%;
            }
            .menu-item{
              font-family: 'DomaineSansText-Light';
            }

            @media (max-width: 640px) {
              .ant-layout-sider{
                border-right: 0

              }
            }
            `}
      </style>
    </div>
  );
};

export default BuyerSideNav;
