import { Modal, Tabs } from "antd";
import { useMediaQuery } from "react-responsive";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import BuyerSignIn from "../pages/BuyerSignIn";
import RegisterBuyer from "../pages/RegisterBuyer";
import SellerSignIn from "../pages/SellerSignIn";
import RegisterSeller from "../pages/RegisterSeller";

const AuthModal = ({
  authModalVisible,
  setAuthModalVisible,
  activePage,
  pageUserType,
}) => {
  const { TabPane } = Tabs;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const [currentKey, setCurrentKey] = useState(activePage);

  const [userType, setUserType] = useState(pageUserType || "buyer");

  useEffect(() => {
    if (authModalVisible) {
      setUserType(pageUserType);
    }
  }, [authModalVisible, pageUserType]);

  return (
    <>
      <Modal
        centered
        visible={authModalVisible}
        footer={null}
        width={!isTabletOrMobile ? "clamp(20px, 65vw, 500px)" : 400}
        onCancel={() => {
          setAuthModalVisible(false);
          setCurrentKey(activePage);
        }}
        closeIcon={
          <CloseCircleOutlined
            style={{
              color: "#000",
              padding: "15px 10px 0 0",
              fontSize: "25px",
            }}
          />
        }
        transitionName=""
        style={{ opacity: "100%", padding: "0" }}
        maskClosable={false}
        className="auth-modal"
      >
        <Tabs
          defaultActiveKey={activePage}
          activeKey={currentKey}
          onChange={(activeKey) => setCurrentKey(activeKey)}
          tabBarStyle={{ margin: "0 24px" }}
          size="large"
        >
          <TabPane
            tab="Sign In"
            key="1"
            active={true}
          >
            {userType === "buyer" && (
              <BuyerSignIn
                changeTab={setCurrentKey}
                isModalVisible={setAuthModalVisible}
                setUserType={setUserType}
              />
            )}
            {userType === "seller" && (
              <SellerSignIn
                changeTab={setCurrentKey}
                isModalVisible={setAuthModalVisible}
                setUserType={setUserType}
              />
            )}
          </TabPane>
          <TabPane
            tab={userType === "seller" ? "Join Losode" : "Register"}
            key="2"
          >
            {userType === "buyer" && (
              <RegisterBuyer
                isModalVisible={setAuthModalVisible}
                setUserType={setUserType}
              />
            )}
            {userType === "seller" && (
              <RegisterSeller
                isModalVisible={setAuthModalVisible}
                setUserType={setUserType}
                setCurrentKey={setCurrentKey}
              />
            )}
          </TabPane>
        </Tabs>
      </Modal>

      <style jsx="true">
        {`
          .auth-modal .ant-input-affix-wrapper > input.ant-input {
            color: #000;
            font-family:  DomaineSansText-Light;
          }
          .auth-modal .ant-modal-body {
            padding: 0px;
          }
        `}
      </style>
    </>
  );
};

export default AuthModal;
