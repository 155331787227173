import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const ShopShirt = () => {
  const navigate = useNavigate();

  return (
    <div className="shopshirt-container">
      <img
        src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732085311/mens-homepage-image-3_iexpbb.png"
        alt="random-category"
        className="shopshirt-image"
      />
      <div className="shopshirt-content-container">
        <div className="text-style-main-sub" style={{ color: "fff" }}>
          BRAND
        </div>
        <div className="text-style-main" style={{ color: "fff" }}>
          Casual Shirts
        </div>
        <div className="subtext">Ease your way into your summer wishlist</div>
        <Button
          className="btn-shop-shopshirt"
          style={{ marginTop: "15px" }}
          onClick={() => navigate("/products/category/cid=57&name=men-shirts-tshirts?page=1")}
        >
          Shop Shirts
        </Button>
      </div>
      <style jsx="true">{`
        .shopshirt-container {
          position: relative;
          background-position: top;
          margin: 24px 16px;
        }
        .shopshirt-image {
          width: 100%;
          height: auto;
        }
        .shopshirt-container .btn-shop-shopshirt {
          background: #fff;
          border-radius: 2px;
          border: none;
          color: #000;
          font: normal normal 14px DomaineSansText-Light;
          width: 100%;
          height: 45px;
        }
        .shopshirt-content-container {
          position: absolute;
          color: #fff;
          bottom: 0px;
          left: 0px;
          width: 100%;
          padding: 16px;
        }
        .shopshirt-content-container .subtext {
          font-size: 14px;
          font-family: DomaineSansText-Light;
          max-width: 100%;
          line-height: 1.3;
        }
        .text-style-main {
          font-size: 28px;
          font-family: DomaineSansText-Medium;
          color: #fff;
          margin-bottom: 10px;
          line-height: 30px;
        }
        .text-style-main-sub {
          font-size: 20px;
          font-family: DomaineSansText-Regular;
          color: #fff;
          margin-bottom: 0px;
        }
      `}</style>
    </div>
  );
};

export default ShopShirt;
