import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../../../redux/user";

// import { useMediaQuery } from "react-responsive";
import Banner from "./sections/BannerMen";
// import MobileSearchBar from "../components/layout/MobileSearchBar";
// import Brands from "./sections/BrandsMen";
// import Ads from "./sections/Ads";
// import StylistChoice from "./sections/StylistChoiceMen";
import RandomCategory from "./sections/RandomCategoryMen";
// import Verdict from "./sections/Verdict";
// import Quality from "./sections/Quality";
import ShopDesigner from "./sections/ShopDesignerMen";
import Line from "../../../components/Line";
import Bestseller from "./sections/Bestseller";

const MensPageWeb = () => {
  const dispatch = useDispatch();

  const { setUserType } = bindActionCreators(userActionCreators, dispatch);
  // const [modalVisible, setModalVisible] = useState(false);
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

  useEffect(() => {
    setUserType("Men");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
    // style={{overflow:"hidden", position:"fixed"}}
    >
      {/* {isTabletOrMobile ? <MobileSearchBar /> : null} */}
      <Banner />
      {/* <Brands /> */}
      <Line />
      <RandomCategory />
      <Bestseller />
      {/* <Verdict /> */}
      {/* <Line /> */}
      <ShopDesigner />
      {/* <Ads />
      
      <Quality /> */}
    </div>
  );
};

export default MensPageWeb;
