import { useEffect, useRef, useState } from "react";
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { brandsActionCreators } from "../redux/brands";
// import Ads from "../components/Ads";

const { TabPane } = Tabs;

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const Designers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const scrollRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: 640 });

  const { sortedBrands:brands = [] } = useSelector((state) => state.brands);
  const { fetchBrands } = bindActionCreators(brandsActionCreators, dispatch);

  const shopBrand = (slug) => {
    navigate(`/designers/${slug}`);
  };

  const brandDiv = (letter) => {
    const items = [];
    for (let [key, value] of Object.entries(brands)) {
      if (key === letter) {
        items.push(
          <>
            {value.map((brand) => (
              <BrandItem key={brand.slug} brand={brand} shopBrand={shopBrand} />
            ))}
          </>
        );
      }
    }
    return items;
  };

  const scrollToRef = (ref) => {
    return window.scrollTo({ top: ref.offsetTop - 40, behavior: "smooth" });
  };

  const itemsRef = useRef([]);

  useEffect(() => {
    fetchBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, alphabet.length);
  }, []);

  // get other stores not in alphabet
  const [otherBrands, setOtherBrands] = useState([]);

  useEffect(() => {
    const otherStores = [];
    for (let [key, value] of Object.entries(brands)) {
      if (!alphabet.includes(key)) {
        otherStores.push(...value);
      }
    }
    setOtherBrands(otherStores);
  }, [brands]);

  return (
    <div>
      <div>
        <div className="top-container">
          <h2 className="txt-title">Designers</h2>
        </div>
        <div
          className="tabs-container designer-alphabet-tabcontainer"
        >
          <Tabs
            defaultActiveKey="1"
            className="tabs"
            tabBarGutter={8.5}
            onTabClick={(key) => scrollToRef(itemsRef.current[key])}
          >
            {alphabet.map((letter, index) => (
              <TabPane
                className="tab"
                tab={
                  <div
                    style={{
                      color: brands[letter]
                        ? "black"
                        : "rgb(128 128 128 / 50%)",
                      cursor: brands[letter] ? "pointer" : "default",
                      fontFamily: "DomaineSansText-Light",
                    }}
                  >
                    {letter}
                  </div>
                }
                key={index}
              ></TabPane>
            ))}
            <TabPane className="tab" tab={"#"} key={"#"}></TabPane>
          </Tabs>
          <div className="designer-ads-contain">
            <img src={ isMobile ? 'https://res.cloudinary.com/losode-ng/image/upload/v1742835192/banner/designers_mobile_iiesvq.png' : 'https://res.cloudinary.com/losode-ng/image/upload/v1742835194/banner/designers_web_rk8obh.png'} style={{width:'100%', cursor:'pointer'}} alt="banner"
            onClick={()=>navigate('/sell')}
            />
          </div>
          <div className="all-letters-section-container">
            <div className="all-letters-topdivider"></div>
            {alphabet.map((letter, index) =>
              brands[letter] ? (
                <div
                  className="letter-section"
                  key={letter}
                  ref={(el) => (itemsRef.current[index] = el)}
                >
                  <h2 className="letter">{letter}</h2>
                  <div className="brand-content">{brandDiv(letter)}</div>
                </div>
              ) : (
                ""
              )
            )}
            <div
              className="letter-section"
              key={"#"}
              ref={(el) => (itemsRef.current["#"] = el)}
            >
              <h2 className="letter">#</h2>
              <div className="brand-content">
                {otherBrands.map((brand) => (
                 <BrandItem key={brand.slug} brand={brand} shopBrand={shopBrand} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .top-container {
          display: flex;
          justify-content: space-between;
          margin: 40px 16px 30px;
          align-items: center;
        }
        .txt-title {
          line-height: normal;
          margin-bottom: 0;
          font-size: 28px;
          font-family: "DomaineSansText-Regular";
        }
        .searchBox {
          width: 200px;
          border: 0px;
          height: 48px;
        }
        .searchBox.ant-input-affix-wrapper > input.ant-input {
          color: #000;
        }
        .tabs-container {
          overflow-x: scroll;
          margin-bottom: 90px;
        }
        .tabs-container::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        .ant-tabs-tab-btn {
          font-size: 18px;
          font-weight: 300;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #000;
          font-weight: 500;
        }
        .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
        .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
          background: #000;
          width: 50px;
          height: 5px;
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
          overflow: auto;
          white-space: nowrap;
          justify-content: space-between;
          width: 99%;
        }
        .ant-tabs-top > .ant-tabs-nav::before {
          border-bottom: 1px solid #000;
          left: -40px;
        }
        .ads {
          height: 200px;
          width: 900px;
        }
        .all-letters-topdivider {
          border-top: 1px solid #d9d9d9;
          height: 1px;
          margin: 0 40px;
        }
        .letter-section {
          display: flex;
          gap: 40px;
          border-bottom: 1px solid #d9d9d9;
          margin: 10px 40px;
          padding: 16px 0 40px;
        }
        .letter {
          margin: 0;
          font-size: 85px;
          font-family: "DomaineSansText-Regular";
          line-height: 57px;
          width: 60px;
          display: flex;
          justify-content: center;
        }
        .brand-content {
          display: flex;
          flex-wrap: wrap;
          width: 50%;
          align-items: flex-start;
        }
        .brands-div {
          font: normal normal 18px/23px DomaineSansText-Regular;
          display: flex;
          align-items: center;
          width: 48%;
          gap: 8px;
        }
        .txt-designer-name:hover {
          color: #800000;
        }
        .txt-designer-name {
          margin: 0;
          font-size: 14px;
          font-family: "DomaineSansText-Regular";
          cursor: pointer;
          text-transform: uppercase;
          line-height: 24px;
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
          height: auto;
          border-bottom: 1px solid #d4d4d4;
          margin: 0 16px;
          padding: 8px 0;
          border-top: 1px solid #d4d4d4;
        }
        .ant-tabs-top > .ant-tabs-nav::before {
          border: 0;
        }
        .designer-ads-contain {
          margin: 50px auto 66px;
          width: 95%;
        }
        html {
          scroll-behavior: smooth;
        }

        @media (max-width: 640px) {
          .ant-tabs-nav-list {
            width: unset !important;
            overflow: unset !important;
            -webkit-overflow-scrolling: touch;
          }
          .tabs-container {
            width: 100%;
            overflow-x: auto;
            display: block !important;
          }
          .designer-alphabet-tabcontainer .ant-tabs-nav-wrap{
            margin: auto !important;
          }
          .designer-alphabet-tabcontainer .ant-tabs-mobile{
            margin: 0 16px !important;
          }
          .letter-section {
            flex-direction: column;
            margin: 0;
            padding: 16px;
            gap: 8px;
            border: 0;
          }
          .letter {
            font-size: 45px;
            justify-content: flex-start;
          }
          .content {
            margin-bottom: 0 !important;
          }
          .txt-designer-name {
            font-size: 14px;
          }
          .brands-div {
            padding-bottom: 12px;
            padding-top: 12px;
            border-bottom: 1px solid #d9d9d9;
            width: 100% !important;
            flex-direction: row-reverse;
            justify-content: space-between;
          }
          .designer-ads-contain {
            margin: auto;
            padding: 0 16px;
            width: 100%;
          }
          .all-letters-topdivider {
            margin: 0 16px;
          }
          .top-container {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 24px;
          }
          .searchBox {
            width: 90%;
            border: 1px solid #d9d9d9;
            margin: 24px auto 0;
            width: 90%;
            height: 40px;
          }
          .txt-title {
            border-bottom: 1px solid #d9d9d9;
            width: 100%;
            padding-bottom: 16px;
          }
            .brand-content{
              width: 100%;
            }
        }
      `}</style>
    </div>
  );
};

export default Designers;

const BrandItem = ({ brand, shopBrand }) => {
  return (
    <div className="brands-div">
      <p className="txt-designer-name" onClick={() => shopBrand(brand?.slug)}>{brand?.store_name}</p>
    </div>
  );
};
