import { Button, Col } from "antd";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../../redux/user";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import formatter from "../../utils/formatter";
import { useState } from "react";
import capitalizeStr from "../../utils/capitalizeStr";
// import { useMediaQuery } from "react-responsive";

import { postSavedItemToLocalStorage } from "../../utils/guestSavedProductsLocal";
import triggerNotification from "../../hooks/triggerNotification";

const ProductWeb = ({ product, currency, productIndex }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { info } = useSelector((state) => state.user);
  const { postItem } = bindActionCreators(userActionCreators, dispatch);
  const [savedState, setSavedState] = useState(false);

  const { data } = useSelector((state) => state.currency);

  const { code } = data;

  const path = location.pathname;

  const saveItem = async (id) => {
    if (info.token) {
      const res = await postItem([id]);
      if (res.status === "ok") {
        triggerNotification({
          type: "success",
          message: res.message,
        });
        setSavedState(true);
      }
    } else {
      postSavedItemToLocalStorage(product.slug, code);
      triggerNotification({
        type: "success",
        message: "Item successfully added to saved items list",
      });
      setSavedState(true);
    }
    // if (res.status === "Not ok")
    //   message.error("Please login to add item to your wishlist");
  };

  const [categoryVisible] = useState(true);

  const optimizeImageSize = (url = "") => {
    const urlParts = url?.split("upload/");
    const optimizedUrl = urlParts[0] + "upload/q_auto:eco/" + urlParts[1];
    return optimizedUrl;
  };

  // const isMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <Col
      className="gutter-row"
      style={{
        display: `${categoryVisible ? "block" : "none"}`,
        marginBottom: "10px",
      }}
      // span={!isMobile ? 6 : 12}
      xs={12}
      sm={12}
      md={8}
      lg={6}
      xl={6}
    >
      <div className="product-container">
        <Link to={`/product/${product.slug}`}>
          {/* <div
            alt={product.name}
            className="product-img"
            style={{ backgroundImage: `url(${product.img1})` }}
          /> */}
          <img
            alt={product?.name}
            className="product-img"
            src={optimizeImageSize(product?.img1)}
            loading={productIndex > 3 ? "lazy" : "eager"}
          />
        </Link>
        {!path.includes("/sale") && (
          <Button
            className="btn-wishlist"
            shape="circle"
            onClick={() => saveItem(product?.product_id)}
            icon={
              savedState ? (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.56522 8H4.44058L0 3.84049V1.26387L1.34928 0H4.1L4.99331 0.857143L4.73785 1.09711L4.48238 1.33709L3.8 0.678771H1.64928L0.724638 1.54488V3.55948L4.74058 7.32123H5.26522L9.27536 3.56355V1.54896L8.34928 0.681487L6.20145 0.682844L2.83913 3.84185L2.32609 3.36263L5.9 0.00543017L8.64928 0.00271509L10 1.2693V3.84456L5.56522 8Z"
                    fill="#6B1214"
                  />
                  <path
                    d="M4.48238 1.33709L3.8 0.678771H1.64928L0.724638 1.54488V3.55948L4.74058 7.32123H5.26522L9.27536 3.56355V1.54896L8.34928 0.681487L6.20145 0.682844L2.83913 3.84185L2.32609 3.36263L4.48238 1.33709Z"
                    fill="#6B1214"
                  />
                </svg>
              ) : (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 17 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.08986 14H7.25295L0 6.72086V2.21178L2.20382 0H6.69667L7.58198 0.890887L6.74638 1.72951L6.20667 1.18785H2.69382L1.18357 2.70355V6.22909L7.74295 12.8122H8.59986L15.1498 6.23621V2.71067L13.6372 1.1926L10.129 1.19498L4.63725 6.72323L3.79928 5.88461L9.63667 0.0095028L14.1272 0.0047514L16.3333 2.22128V6.72798L9.08986 14Z"
                    fill="#6B1214"
                  />
                </svg>
              )
            }
          />
        )}

        <div
          className="details"
          onClick={() => navigate(`/product/${product.slug}`)}
        >
          <h2 className="product-store-name">{product.store_name}</h2>
          <h4 className="product-name">{capitalizeStr(product.name)}</h4>
          <div className="details_price">
            {product?.has_discount && product?.oldPrice && (
              <h4 className="product-price">
                <span style={{ textDecoration: "line-through" }}>
                  {formatter(
                    product.oldPrice,
                    currency?.rate,
                    currency?.code,
                    currency?.indicator
                  ).replace(/\.00$/, "")}
                </span>{" "}
                <span style={{ color: "#800000" }}>
                  {product?.discount_percent}% OFF
                </span>
              </h4>
            )}

            <p
              className="product-price"
              style={{
                color: `${
                  product?.has_discount && product?.oldPrice
                    ? "#800000"
                    : "#000"
                }`,
                marginLeft: 0,
                marginTop: 0,
              }}
            >
              {formatter(
                product.price,
                currency?.rate,
                currency?.code,
                currency?.indicator
              ).replace(/\.00$/, "")}
            </p>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .product-container {
        }
        .product-store-name {
          font-style: normal;
          font-size: 14px;
          font-family: DomaineSansText-Medium;
          margin-left: 0px;
          margin-top: 0px;
          text-transform: uppercase;
        }
        .product-name {
          font: normal normal 14px DomaineSansText-Regular;
          margin-left: 0px;
          margin-top: 0px;
          margin-bottom: 0px;
          line-height: 1.2em;
          max-height: 5em;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          height: 40px;
          text-transform: capitalize;
        }

        .product-img {
          border: 1px solid #f4f4f4;
          background: #fff;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          width: auto;
          height: 350px;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .details {
          cursor: pointer;
          margin-top: 10px;
          align-items: baseline;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          height: 120px;
        }
        .product-price {
          font-style: normal;
          font-size: 14px;
          font-family: DomaineSansText-Medium;
          margin-left: 0px;
          margin-top: 0.2em;
          margin-bottom: 1em;
        }
        .btn-wishlist {
          background: #fff;
          box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
          position: absolute;
          right: 9px;
          top: 5%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
        }
        @media (min-width: 1445px) {
          .product-img {
            height: 450px;
          }
        }
        @media (min-width: 768px) and (max-width: 1024px) {
          .product-img {
            height: 300px;
          }
        }
      `}</style>
    </Col>
  );
};

export default ProductWeb;
