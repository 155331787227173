import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../../redux/user";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Button, Badge, Drawer, Menu, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { productsActionCreators } from "../../redux/products";
import DiscountHeader from "./DiscountHeader";
import AuthModal from "../AuthModal";
import ModalForEmailCollection from "./ModalForEmailCollection";

const { Search } = Input;

const MobileHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  // const { searchProduct } = bindActionCreators(
  //   productsActionCreators,
  //   dispatch
  // );

  const [mobileSearchDisplay, setMobileSearchDisplay] = useState("none");
  const [searchQuery, setSearchQuery] = useState("");

  const [showModalForEmailCollection, setShowModalForEmailCollection] = useState(false);

  const setSearchDisplay = () => {
    if (mobileSearchDisplay === "block") {
      setMobileSearchDisplay("none");
    } else {
      setMobileSearchDisplay("block");
    }
  };

  const onSearch = async (value) => {
    // const res = await searchProduct(value);
    // if (res.status === "ok")
    navigate(
      {
        pathname: "/products/search",
        search: `?query=${value}`,
      },
      { state: value }
    );
    setMobileSearchDisplay("none");
    setSearchQuery("");
  };

  const [buyerAuthModalVisible, setBuyerAuthModalVisible] = useState(false);
  const [buyerRegisterModalVisible, setBuyerRegisterModalVisible] =
    useState(false);

  const { logout } = bindActionCreators(userActionCreators, dispatch);
  const { isAuthenticated, type } = useSelector((state) => state.user);
  const { items: products } = useSelector((state) => state.cart);
  // const { data } = useSelector((state) => state.currency);
  // const { code } = data ? data : { code: "", currency: "", rate: "" };

  const { subs = [], menu_data = {} } = useSelector(
    (state) => state.products?.headerCategories
  );

  const titles = {
    Clothing: { title: "Style That Suits", desc: "For every occasion" },

    Accessories: {
      title: "Accentuate your looks",
      desc: "Bags, jewellery and hats ",
    },

    Footwear: {
      title: "Step out in style",
      desc: "Footwear that accentuates ",
    },

    Beauty: { title: " Care for you", desc: "Skin and beauty " },
  };

  const { fetchAllCategories, fetchHeaderCategories } = bindActionCreators(
    productsActionCreators,
    dispatch
  );
  // const { fetchCurrency } = bindActionCreators(
  //   currencyActionCreators,
  //   dispatch
  // );

  const { designers } = menu_data;

  const shopCategory = (id, slug) => {
    navigate(`/products/category/cid=${id}&name=${slug}?page=1`);
    onCloseDrawer2();
    onClose();
  };
  const shopBrands = (slug) => {
    navigate(`/designers/${slug}`);
    onCloseDrawer2();
    onClose();
  };

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  //navigate(`/${type.toLowerCase()}/subcategory/${cat}/cid=${id}?page=1`);

  const shopSubCategory = (cat) => {
    if (cat === "accessories") {
      const id = type === "Women" ? 10 : 9;
      navigate(`/${type.toLowerCase()}/subcategory/${cat}/cid=${id}?page=1`);
    }
    if (cat === "clothing") {
      const id = type === "Women" ? 6 : 5;
      navigate(`/${type.toLowerCase()}/subcategory/${cat}/cid=${id}?page=1`);
    }
    if (cat === "shoes") {
      const id = type === "Women" ? 8 : 7;
      navigate(`/${type.toLowerCase()}/subcategory/${cat}/cid=${id}?page=1`);
    }
    if (cat === "skin and beauty") {
      const id = 3;
      navigate(`/${type.toLowerCase()}/subcategory/beauty/cid=${id}?page=1`);
    }
    onCloseDrawer2();
    onClose();
  };

  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);
  const onOpenDrawer2 = (title) => {
    fetchHeaderCategories(title, type);
    setTitle(title);
    setOpen(true);
  };
  const onCloseDrawer2 = () => setOpen(false);

  const setPageType = (url) => {
    navigate(url);
    onClose();
  };

  useEffect(() => {
    fetchAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mobile-nav-container">
      <AuthModal
        authModalVisible={buyerAuthModalVisible}
        setAuthModalVisible={setBuyerAuthModalVisible}
        activePage="1"
        pageUserType="buyer"
      />
      <AuthModal
        authModalVisible={buyerRegisterModalVisible}
        setAuthModalVisible={setBuyerRegisterModalVisible}
        activePage="2"
        pageUserType="buyer"
      />

      <DiscountHeader />

      <div className="mobile-nav-menu-container">
        <div className="menu">
          <Button
            className="menu-search-icon"
            style={{ marginRight: "8px", padding: "0 8px" }}
            icon={
              <img
                className="menu-icon"
                alt="drawer"
                src="/images/search-icon.svg"
              />
            }
            onClick={() => setSearchDisplay()}
            ghost
          />

          <div>
            <Link to="/cart">
              <Badge
                count={products.length > 0 ? products.length : ""}
                offset={[30, 15]}
                showZero
                size="small"
                style={{
                  backgroundColor: "#000",
                  fontSize: 8,
                  fontFamily: "DomaineSansText-Light",
                  zIndex: 1000,
                }}
              />
              <Button
                className="menu-cart-icon"
                style={{ padding: "0 8px" }}
                icon={
                  <img
                    className="menu-icon"
                    alt="drawer"
                    src="/images/cart-icon.svg"
                  />
                }
                ghost
              />
            </Link>
          </div>
        </div>

        <img
          src="/images/losode-no-tagline.png"
          alt="logo"
          className="logo"
          onClick={() => navigate("/")}
        />

        <div className="mobile-nav-left-container">
          <Button
            className="btn-drawer"
            style={{}}
            icon={
              <img
                className="menu-icon"
                alt="drawer"
                src="/images/mobile-menu-icon.svg"
              />
            }
            onClick={showDrawer}
            ghost
          />
          <Link to="/saved-items" style={{ display: "flex" }}>
            <SavedItemsIcon />
          </Link>
        </div>
        <Drawer
          className="main-drawer mobile-navbar"
          title={
            <div
              className="mobile-navbar-logo"
              onClick={() => setPageType("/")}
            >
              <svg
                width="110"
                height="38"
                viewBox="0 0 110 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_21937_32770)">
                  <path
                    d="M3.94913 31.1145H-0.235352V5.00195H3.94913V31.1145Z"
                    fill="black"
                  />
                  <path
                    d="M8.05894 21.849C8.05894 19.2892 9.02584 16.8342 10.7469 15.0242C12.4681 13.2141 14.8024 12.1973 17.2364 12.1973C19.6704 12.1973 22.0047 13.2141 23.7258 15.0242C25.4469 16.8342 26.4138 19.2892 26.4138 21.849C26.4138 27.3114 22.3036 31.4846 17.2167 31.4846C16.011 31.4951 14.8153 31.2529 13.6995 30.7723C12.5836 30.2917 11.57 29.5823 10.7176 28.6854C9.86514 27.7885 9.19105 26.7221 8.73459 25.5483C8.27814 24.3746 8.04845 23.1171 8.05894 21.849ZM12.4947 21.849C12.4947 28.6006 14.1903 31.1422 17.2189 31.1422C20.2475 31.1422 21.9453 28.6006 21.9453 21.849C21.9453 15.0215 20.2496 12.4822 17.2189 12.4822C14.1882 12.4822 12.4947 15.0307 12.4947 21.849Z"
                    fill="black"
                  />
                  <path
                    d="M31.9711 15.8276C31.9711 17.7235 33.1598 18.445 35.288 19.015L38.7143 19.9617C41.9242 20.881 43.9083 22.161 43.9083 25.1967C43.9083 28.9884 40.8054 31.4933 36.4789 31.4933C32.8713 31.4933 29.6264 29.5583 28.8311 26.2974L29.1916 26.1457C29.9476 29.4067 33.2668 31.1141 36.4767 31.1141C39.1098 31.1141 41.2359 29.7858 41.2359 27.32C41.2359 25.3851 39.7216 24.5509 37.7026 24.0201L34.1671 23.0733C31.3264 22.3126 29.515 20.8718 29.515 18.1026C29.515 14.5752 32.5457 12.1484 36.4767 12.1484C39.5359 12.1484 41.9941 13.5135 42.9315 16.3217L42.6082 16.4733C41.6336 13.7042 39.3611 12.5276 36.4767 12.5276C33.8087 12.5276 31.9711 13.9317 31.9711 15.8276Z"
                    fill="black"
                  />
                  <path
                    d="M46.7201 21.849C46.7201 20.5815 46.9575 19.3264 47.4187 18.1554C47.8799 16.9844 48.5559 15.9204 49.4081 15.0242C50.2603 14.1279 51.272 13.417 52.3855 12.932C53.4989 12.4469 54.6923 12.1973 55.8975 12.1973C57.1027 12.1973 58.2961 12.4469 59.4096 12.932C60.523 13.417 61.5347 14.1279 62.387 15.0242C63.2392 15.9204 63.9152 16.9844 64.3764 18.1554C64.8376 19.3264 65.075 20.5815 65.075 21.849C65.075 27.3114 60.9648 31.4846 55.8779 31.4846C54.6721 31.4951 53.4765 31.2529 52.3606 30.7723C51.2448 30.2917 50.2311 29.5823 49.3787 28.6854C48.5263 27.7885 47.8522 26.7221 47.3957 25.5483C46.9393 24.3746 46.7096 23.1171 46.7201 21.849ZM51.1558 21.849C51.1558 28.6006 52.8515 31.1422 55.88 31.1422C58.9086 31.1422 60.6064 28.6006 60.6064 21.849C60.6064 15.0215 58.9108 12.4822 55.88 12.4822C52.8493 12.4822 51.1558 15.0307 51.1558 21.849Z"
                    fill="black"
                  />
                  <path
                    d="M81.7388 5.00195H85.9233V31.1145H81.8831L81.7388 26.4862C81.0177 29.2439 78.7452 31.4937 75.5004 31.4937C71.5322 31.4937 67.8896 27.8903 67.8896 22.0097C67.8896 16.129 71.3858 12.1488 75.5725 12.1488C78.6316 12.1488 80.9828 14.1964 81.7388 17.1172V5.00195ZM81.776 19.4796C81.6667 15.8372 79.5406 13.1439 76.9076 13.1439C73.9861 13.1439 72.3626 16.7863 72.3626 21.943C72.3626 27.0998 73.9489 30.5538 76.8704 30.5538C79.3593 30.5538 81.6777 27.9754 81.776 24.2204V19.4796Z"
                    fill="black"
                  />
                  <path
                    d="M98.979 31.5011C94.5782 31.5011 90.0332 27.8978 90.0332 21.9803C90.0332 15.9503 94.2527 12.1562 98.7977 12.1562C104.027 12.1562 106.948 16.2146 106.948 21.0312H94.469V22.0792C94.469 27.8449 96.3809 30.8806 100.133 30.8806C103.054 30.8806 105.687 28.9824 106.47 25.2665L106.83 25.3424C105.939 29.2559 103.126 31.5011 98.979 31.5011ZM94.469 20.6521H102.772C102.772 15.647 101.87 12.4987 98.8042 12.4987C96.4531 12.4918 94.6853 14.7254 94.469 20.6452V20.6521Z"
                    fill="black"
                  />
                  <path
                    d="M18.4929 34.3184H15.9844V37.8504H18.4929V34.3184Z"
                    fill="black"
                  />
                  <path
                    d="M54.4365 10.3012L57.3645 5.00195H54.4365V10.3012Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_21937_32770">
                    <rect width="109.571" height="38" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              {/* <img src="/images/losode-no-tagline.png" alt="losode-logo"  /> */}
            </div>
          }
          placement="left"
          width="100%"
          onClose={onClose}
          visible={visible}
          closeIcon={
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.625 14.375L14.375 31.625"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.375 14.375L31.625 31.625"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        >
          <div className="page-change-container">
            <div
              onClick={() => setPageType("/women")}
              style={{
                padding: "10px 0px",
                margin: "0px 30px",
                fontSize: "16px",
                borderBottom: `${type !== "Men" ? "2px solid #000" : ""}`,
              }}
            >
              Women
            </div>
            <div
              onClick={() => setPageType("/men")}
              style={{
                padding: "10px 0px",
                margin: "0px 30px",
                fontSize: "16px",
                borderBottom: `${
                  location?.pathname?.split("/")[1] === "men" || type === "Men"
                    ? "2px solid #000"
                    : ""
                }`,
              }}
            >
              Men
            </div>
            {/* <div onClick={() => setPageType("/kids")}>Kids</div> */}
          </div>
          <div
            style={{
              background: "#000",
              padding: "8px",
              fontSize: "10px",
              color: "#fff",
              textAlign: "center",
              margin: "16px auto",
            }}
          >
            New to Losode? Get 10% off your first order, click{" "}
            <span
              style={{
                color: "#FE7972",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                setShowModalForEmailCollection(true);
                onClose();
              }}
            >
              here
            </span>
          </div>
          <Menu className="mobilenav-main-menu" mode="vertical">
            <Menu.Item
              className="drawer-item"
              key="200"
              style={{ border: 0, margin: 0 }}
              onClick={() => {
                shopNewIn();
                onClose();
              }}
            >
              New In
            </Menu.Item>
            <Menu.Item
              className="drawer-item"
              key="3"
              style={{ border: 0, margin: 0 }}
              onClick={() => {
                navigate("/designers");
                onClose();
              }}
            >
              Designers
            </Menu.Item>
            <Menu.Item
              className="drawer-item"
              key="4"
              style={{ border: 0, margin: 0 }}
              onClick={() => onOpenDrawer2("Clothing")}
            >
              <div className="item-content">
                Clothing
                <PlusOutlined />
              </div>
            </Menu.Item>
            <Menu.Item
              className="drawer-item"
              key="5"
              style={{ border: 0, margin: 0 }}
              onClick={() => onOpenDrawer2("Accessories")}
            >
              <div className="item-content">
                Accessories
                <PlusOutlined />
              </div>
            </Menu.Item>
            <Menu.Item
              className="drawer-item"
              key="7"
              style={{ border: 0, margin: 0 }}
              onClick={() => onOpenDrawer2("Footwear")}
            >
              <div className="item-content">
                Footwear
                <PlusOutlined />
              </div>
            </Menu.Item>
            {type === "Women" && (
              <Menu.Item
                className="drawer-item"
                key="6"
                style={{ border: 0, margin: 0 }}
                onClick={() => onOpenDrawer2("Skin and Beauty")}
              >
                <div className="item-content">
                  Beauty
                  <PlusOutlined />
                </div>
              </Menu.Item>
            )}
            <Menu.Item
              className="drawer-item"
              key="209"
              style={{ border: 0, margin: 0, color: "#800000" }}
              onClick={() => {
                navigate("/sale");
                onClose();
              }}
            >
              <div
                className="item-content"
                style={{ fontFamily: "DomaineSansText-Medium" }}
              >
                SALE
              </div>
            </Menu.Item>
          </Menu>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "0 18px",
              borderTop: "1px solid #d4d4d4",
            }}
          >
            {!isAuthenticated ? (
              <Button
                style={{
                  width: "100%",
                  height: "42px",
                  marginTop: "15px",
                  background: "#000",
                  color: "#fff",
                  fontSize: "14px",
                }}
                onClick={() => {
                  logout();
                  setBuyerAuthModalVisible(true);
                  onClose();
                }}
              >
                Sign In / Register
              </Button>
            ) : (
              <Button
                style={{
                  width: "100%",
                  height: "42px",
                  marginTop: "15px",
                  background: "#000",
                  color: "#fff",
                  fontSize: "14px;",
                }}
                onClick={() => {
                  logout();
                  onClose();
                }}
              >
                Sign Out
              </Button>
            )}
            <Button
              style={{
                width: "100%",
                height: "42px",
                marginTop: "15px",
                background: "#F9F9F9",
                color: "#000",
                fontSize: "16px",
                border: "1px solid #D4D4D4",
              }}
              onClick={() => {
                setPageType("/sell");
              }}
            >
              <div>Sell On Losode</div>
            </Button>
          </div>
          {isAuthenticated && (
            <Menu
              className="mobilenav-main-menu"
              mode="vertical"
              style={{ border: 0, marginTop: 10 }}
            >
              <Menu.Item
                className="drawer-item"
                key="200"
                style={{ border: 0, margin: 0 }}
                onClick={() => navigate("/account")}
              >
                My Account
              </Menu.Item>
              {/* )} */}
            </Menu>
          )}
          <div style={{ padding: "18px", fontSize: "12px", marginTop: "8px" }}>
            <div style={{ fontSize: "14px" }}>Need Help?</div>
            <div>Send an email to
            <a href="mailto:hello@losode.com" style={{textDecoration: "underline"}}>
              hello@losode.com
            </a>{" "}
            or call us on <a href="tel:+2342013306011" style={{textDecoration: "underline"}}>+2342013306011</a>
            </div>
          </div>
        </Drawer>
        <Drawer
          className="second-drawer"
          title={
            <div className="drawer2-container">
              <img
                src="/images/back-arrow.png"
                alt="logo"
                className="back-arrow"
                onClick={onCloseDrawer2}
              />
              <h3 className="drawer2-title">{title}</h3>
            </div>
          }
          placement="left"
          width="100%"
          onClose={onCloseDrawer2}
          visible={open}
        >
          <div className="mobile-menu-content">
            <div className="left-section">
              <div className="subs-cat">
                <h3 className="mobile-cat-title">{title}</h3>
                <p
                  className="mobile-sub-item"
                  onClick={() => shopSubCategory(title.toLowerCase())}
                >
                  All {title}
                </p>
                {subs?.slice(0, 10).map((sub) => (
                  <p
                    className="mobile-sub-item"
                    key={sub.id}
                    onClick={() => shopCategory(sub.id, sub.slug)}
                  >
                    {sub.category}
                  </p>
                ))}
              </div>
            </div>

            <div className="designers-cat">
              <h3 className="mobile-cat-title">Designers</h3>
              {designers?.slice(0, 10).map((designer) => (
                <p
                  className="mobile-sub-item"
                  key={designer.store_id}
                  onClick={() => shopBrands(designer.slug)}
                >
                  {designer.store_name}
                </p>
              ))}
            </div>
          </div>
          <div
            className="mobile-cat-img-container"
            style={{ width: "100%", marginBottom: "40px" }}
          >
            <img
              className="mobile-cat-img"
              style={{ width: "100%", height: "auto" }}
              src={`/images/category_${title?.toLowerCase()}.png`}
              alt="brand"
            />
            <div
              style={{ textAlign: "left", width: "100%", padding: "0 16px" }}
            >
              <p className="mobile-cat-img-title"> {titles[title]?.title}</p>
              <p className="mobile-cat-img-desc"> {titles[title]?.desc}</p>
            </div>
          </div>
        </Drawer>
      </div>

      <div
        className="search-container"
        style={{ padding: "8px", display: mobileSearchDisplay }}
      >
        <Search
          className="search"
          allowClear
          placeholder="Search"
          onPressEnter={() => onSearch(searchQuery)}
          onSearch={onSearch}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e?.target?.value)}
          style={{ fontFamily: "DomaineSansText-Light" }}
        />
      </div>

      <ModalForEmailCollection
        showModal={showModalForEmailCollection}
        setShowModal={setShowModalForEmailCollection}
        promoName={"10PercentPromo"}
      />

      <style jsx="true">{`
        .mobile-nav-container {
          position: fixed;
          z-index: 200;
          width: 100%;
          background: white;
        }
        .mobile-nav-menu-container {
          align-items: center;
          background: #fff;
          display: flex;
          justify-content: space-evenly;
          position: relative;
          width: 100%;
        }
        .main-drawer {
          font-family: DomaineSansText-Light;
          color: black;
          width: 85% !important;
        }
        .main-drawer .ant-drawer-header {
          border: 0;
        }
        .second-drawer {
          width: 85% !important;
        }
        .logo {
          cursor: pointer;
          width: 112px;
          width: 102px;
          padding: 12px 0;
        }
        .ant-scroll-number-only > p.ant-scroll-number-only-unit {
          font: normal normal 8px/8px DomaineSansText-Regular;
        }
        .menu {
          position: absolute;
          right: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .mobile-nav-left-container {
          position: absolute;
          left: 16px;
          display: flex;
          align-items: center;
          gap: 8px;
        }
        .mobile-navbar .ant-drawer-body,
        .second-drawer .ant-drawer-body {
          padding: 0px !important;
        }
        .collapse-container {
          margin: 0px;
        }
        .currency-dropdown {
          background: #000;
          height: 39px;
          border: 1px solid #000;
          margin-left: 16px;
          padding: 7px;
        }
        .ant-dropdown-link {
          color: #ffffff;
          font: normal normal 12px/22px DomaineSansText-Regular;
        }
        .social-icon {
          width: auto;
          height: 12px;
        }

        .icon-cur {
          height: 18px;
          width: 18px;
        }
        .txt-cur {
          font: normal normal 15px/22px DomaineSansText-Light;
          margin-left: 8px;
        }
        .gender-select {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
          margin-left: 16px;
          width: 50%;
        }
        .btn-gender {
          font: normal normal 14px/146.16% DomaineSansText-Light;
          height: 36px;
          width: 85px;
          text-align: center;
          padding-top: 5px;
        }
        .drawer-container {
          display: flex;
          flex-direction: column;
          overflow-x: hidden;
        }
        .main-drawer .ant-drawer-close {
          right: 0;
          margin: 0 0 8px;
          padding: 0;
          width: auto;
          height: auto;
        }
        .auth-container {
          margin-top: 2rem !important;
        }
        .txt-auth {
          color: #800000;
          font: normal 600 14px/146.16% DomaineSansText-Light;
        }
        .txt-or {
          font: normal 600 14px/146.16% DomaineSansText-Light;
        }
        .footer-icons {
          align-items: flex-end;
          display: flex;
          margin-left: 20px;
        }
        .social-links {
          flex: 0.5;
          margin: 0px;
        }
        .txt-privacy {
          color: #000000;
          flex: 1;
          font: normal 400 14px/184.16% DomaineSansText-Light;
          text-align: end;
        }
        .drawer-item {
          font: normal 300 14px/146.16% DomaineSansText-Light;
        }
        .drawer2-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          position: relative;
        }
        .back-arrow {
          height: 12.73px;
          width: 7.78px;
          // margin-left: 10%;
          position: absolute;
          left: 0;
          cursor: pointer;
        }
        .drawer2-title {
          font: normal 700 16px/146.16% DomaineSansText-Black;
          margin: 0px;
        }
        .fr-arrow {
          height: 12.73px;
          width: 7.78px;
        }
        .item-content {
          align-items: center;
          display: flex;
          justify-content: space-between;
          font-family: DomaineSansText-Light;
        }
        .sell-div {
          align-items: center;
          background: #f2f2f2;
          display: flex;
          justify-content: flex-start;
          height: 47px;
          margin: 10px 0px -8px -15px;
          width: 100vw;
        }
        .btn-sell-reg {
          background: #000000;
          font: normal normal 12px/22px DomaineSansText-Light;
          height: 36px;
          width: 97px;
          margin-left: 16px;
        }
        .span-txt-sell {
          color: #800000;
          font: normal normal 9px/22px DomaineSansText-Light;
        }
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
          background: #f3f3f3;
        }
        .mobile-sidenav-currency {
          width: auto;
          margin: 24px 16px;
          display: flex;
          align-items: center;
        }
        .mobile-sidenav-currency .ant-select-selector {
          border: 0 !important;
        }
        .mobile-sidenav-currency .ant-select-selection-item {
          display: flex;
          align-items: center;
        }
        .mobile-sidenav-currency.ant-select-single:not(
            .ant-select-customize-input
          )
          .ant-select-selector {
          padding: 0 16px;
        }

        .page-change-container {
          display: flex;
          font-family: "DomaineSansText-Regular";
        }
        .mobilenav-auth-container {
          font-family: "DomaineSansText-Regular";
        }
        .mobilenav-main-menu {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 0px;
          margin-top: 24px;
        }
        .mobilenav-main-menu .ant-menu-item {
          color: black;
          height: auto;
          padding: 4px 16px;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: #f5f5f5;
        }
        .mobile-menu-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 16px 16px;
        }
        .designers-cat {
          margin-top: 25px;
        }
        .mobile-cat-title {
          font: normal 700 18px/146.16% DomaineSansText-Regular;
        }
        .mobile-sub-item {
          color: #000;
          margin: 8px 0;
          padding: 8px 0;
          font-size: 14px;
          font-family: "DomaineSansText-Light";
          line-height: 1;
        }
        .main-drawer .ant-drawer-header-title {
          justify-content: space-between;
          flex-direction: row-reverse;
        }
        .second-drawer .ant-drawer-header-title {
          flex-direction: row-reverse;
        }
        .second-drawer .ant-drawer-close {
          margin-right: 0px;
          position: absolute;
          z-index: 1000;
        }

        .ant-drawer-header {
          border: 0;
        }

        .search-container
          .ant-input-search
          .ant-input-group
          .ant-input-affix-wrapper:not(:last-child) {
          height: 40px;
        }

        .search-container
          .ant-input-search
          > .ant-input-group
          > .ant-input-group-addon:last-child
          .ant-input-search-button:not(.ant-btn-primary) {
          background: #000;
          color: #fff;
          height: 40px;
          width: 60px;
        }

        .ant-select-disabled.ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          color: #000;
          background: transparent;
          cursor: not-allowed;
        }
        // .mobile-navbar.main-drawer .ant-drawer-header{
        //   box-shadow: 0 2px 10px 0 #05050526;
        // }
        .ant-drawer-close {
          color: black;
        }
      `}</style>
    </div>
  );
};

export default MobileHeader;


const SavedItemsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.8382 4.61183C20.3274 4.10083 19.721 3.69547 19.0535 3.41891C18.3861 3.14235 17.6707 3 16.9482 3C16.2257 3 15.5103 3.14235 14.8428 3.41891C14.1754 3.69547 13.5689 4.10083 13.0582 4.61183L11.9982 5.67183L10.9382 4.61183C9.90647 3.58013 8.5072 3.00053 7.04817 3.00053C5.58913 3.00053 4.18986 3.58013 3.15817 4.61183C2.12647 5.64352 1.54688 7.04279 1.54688 8.50183C1.54687 9.96086 2.12647 11.3601 3.15817 12.3918L4.21817 13.4518L11.9982 21.2318L19.7782 13.4518L20.8382 12.3918C21.3492 11.8811 21.7545 11.2746 22.0311 10.6072C22.3076 9.93972 22.45 9.22431 22.45 8.50183C22.45 7.77934 22.3076 7.06393 22.0311 6.39647C21.7545 5.72901 21.3492 5.12258 20.8382 4.61183Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
  );
}
