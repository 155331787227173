import { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { Input, Button, Modal, message } from "antd";

import { storeActionCreators } from "../../redux/stores";

const PhoneVerificationModal = ({ modalVisible, setModalVisible, number }) => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState("number");

  const [phoneNumber, setPhoneNumber] = useState("");

  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  // const [otp5, setOtp5] = useState("");
  // const [otp6, setOtp6] = useState("");

  const { reqOtp, verifyOtp } = bindActionCreators(
    storeActionCreators,
    dispatch
  );

  const moveCursor = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      // console.log("next");

      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const requestToken = async () => {
    const res = await reqOtp( phoneNumber);
    if (res.status === "ok") {
      setMode("otp");
    }
  };
  const verifyToken = async () => {
    const code = `${otp1}${otp2}${otp3}${otp4}`;
    const res = await verifyOtp( { phone: phoneNumber, code });

    if (res.status === "ok") {
      message.success(res.message);
      setModalVisible(false);
    }
    if (res.status === "not ok") message.error(res.message);
  };

  useEffect(() => {
    setPhoneNumber(`+${number?.slice(1, 11)}`);
    setPhoneNumber(number);
  }, [number]);

  return (
    <div>
      <div>
        <Modal
          centered
          visible={modalVisible}
          footer={null}
          // width={400}
          onCancel={() => setModalVisible(false)}
        >
          <div className="phone-verify-body">
            <div className="phone-verify-title">OTP Verification</div>

            {mode === "number" && (
              <div className="phone-verify-number-section">
                <div className="phone-verify-subtitle">
                  Kindly provide mobile number to send you a one-time password
                </div>
                <Input
                  className="phone-verify-input"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {/* <div
                  className="phone-verify-input-subtitle"
                  style={{ fontSize: "13px" }}
                  onClick={() => setMode("number")}
                >
                  {" "}
                  <strong>Change Mobile Number</strong>{" "}
                </div> */}
                <Button
                  className="phone-verify-input-button"
                  onClick={() => requestToken()}
                >
                  Get OTP
                </Button>
              </div>
            )}

            {mode === "otp" && (
              <div className="phone-verify-number-section">
                <div className="phone-verify-subtitle">
                  Enter the OTP sent to <strong>{phoneNumber}</strong>{" "}
                </div>
                <form className="phone-verify-otp-container">
                  <Input
                    className="phone-verify-otp-item"
                    type="number"
                    tabIndex="1"
                    value={otp1}
                    onKeyUp={(e) => moveCursor(e)}
                    onChange={(e) => setOtp1(e.target.value)}
                  />
                  <Input
                    className="phone-verify-otp-item"
                    type="number"
                    tabIndex="2"
                    value={otp2}
                    onKeyUp={(e) => moveCursor(e)}
                    onChange={(e) => setOtp2(e.target.value)}
                  />
                  <Input
                    className="phone-verify-otp-item"
                    type="number"
                    tabIndex="3"
                    value={otp3}
                    onKeyUp={(e) => moveCursor(e)}
                    onChange={(e) => setOtp3(e.target.value)}
                  />
                  <Input
                    className="phone-verify-otp-item"
                    type="number"
                    tabIndex="4"
                    value={otp4}
                    onKeyUp={(e) => moveCursor(e)}
                    onChange={(e) => setOtp4(e.target.value)}
                  />
                  {/* <Input
                    className="phone-verify-otp-item"
                    type="number"
                    tabIndex="5"
                    value={otp5}
                    onKeyUp={(e) => moveCursor(e)}
                    onChange={(e) => setOtp5(e.target.value)}
                  />
                  <Input
                    className="phone-verify-otp-item"
                    type="number"
                    tabIndex="6"
                    value={otp6}
                    onKeyUp={(e) => moveCursor(e)}
                    onChange={(e) => setOtp6(e.target.value)}
                  /> */}
                </form>
                <div className="phone-verify-input-subtitle">
                  Didnt receive OTP? <strong>Resend OTP</strong>
                </div>
                <div
                  className="phone-verify-input-subtitle"
                  style={{ fontSize: "13px", marginTop: "10px" }}
                  onClick={() => setMode("number")}
                >
                  <strong>Change Mobile Number</strong>
                </div>
                <Button
                  disabled={otp4 ? false : true}
                  className="phone-verify-input-button"
                  onClick={() => verifyToken()}
                >
                  Verify
                </Button>
              </div>
            )}
          </div>
        </Modal>
      </div>
      <style jsx="true">
        {`
          .phone-verify-otp-container {
            display: flex;
            gap: 20px;
            width: 80%;
            justify-content: center;
            margin: auto;
          }
          .phone-verify-body {
            font-family: "DomaineSansText-Light";
            font-size: 16px;
            text-align: center;
            padding: 16px 40px;
          }
          .phone-verify-title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .phone-verify-input {
            border: 0;
            border-bottom: 1px solid black;
            margin-top: 30px;
            margin-bottom: 16px;
            width: 70%;
            margin-bottom: 40px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
          }
          .phone-verify-input-button {
            height: 47px;
            background: black;
            color: white;
            font-size: 16px;
            margin-top: 10px;
            width: 200px;
          }
          .phone-verify-otp-item {
            border: 0;
            border-bottom: 1px solid black;
            margin-top: 60px;
            margin-bottom: 40px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            padding: 0;
          }
          .phone-verify-input-subtitle {
            cursor: pointer;
          }
        `}
      </style>
    </div>
  );
};

export default PhoneVerificationModal;
