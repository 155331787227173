import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Checkbox,
  Form,
  Input,
  Col,
  Row,
  Select,
  Button,
  Space,
  message,
} from "antd";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

const categoryOptions = [
  "Clothing",
  "Shoes",
  "Accessories",
  "Beauty",
  "Grooming",
];

const { Option } = Select;

const ApplyToJoinSuccess = () => {
  return (
    <div className="apply-to-join-success-overlay">
      <div className="apply-to-join-success-div">
        <h3>Success!</h3>
        <p style={{ padding: "0 10px" }}>
        You have been added to our waitlist. 
We will notify you as soon as we start to trade in your region.
        </p>
      </div>
    </div>
  );
};

const SellerApplyToWaitlist = () => {
  const navigate = useNavigate();
  const [sellerForm] = Form.useForm();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const [countryValue, setCountryValue] = useState("United States");
  const [countryInfo, setCountryInfo] = useState({});
  const [newCountries, setNewCountries] = useState([]);
  // const [phoneNumberFilled, setPhoneNumberFilled] = useState(false);

  const [applySuccess, setApplySuccess] = useState(false);

  const onSelectCountry = async () => {
    const country = newCountries.find(
      (country) => country.name === countryValue
    );
    setCountryInfo(country);
    sellerForm.setFieldsValue({
      brand_phone_dial: country?.phoneCode,
    });
  };

  useEffect(() => {
    onSelectCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryValue]);

  const [selectedCategories, setSelectedCategories] = useState([]);

  const onSubmit = async (values) => {
    const payload = {
      brand_phone_dial: sellerForm.getFieldValue("brand_phone_dial"),
      ...values,
    };

    try {
      const { data } = await axios.post(
        "https://www.losode.dev/api/v2/application/join/waitlist",
        payload
      );
      if (data.status === "ok") setApplySuccess(true);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const getCountries = async () => {
    try {
      const response = await axios.get(
        "https://www.losode.dev/api/v2/utility/countries"
      );
      const countriesObj = response.data.data;
      const countriesFiltered = countriesObj.filter(
        (country) => country.name !== "Nigeria"
      );
      setNewCountries(countriesFiltered);

      const country = countriesFiltered.find(
        (country) => country.name === countryValue
      );
      setCountryInfo(country);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  const handleCategoryChange = (selected) => {
    setSelectedCategories(selected);
  };

  const redirect = () => {
    if (applySuccess === true) {
      navigate("/sell");
    }
  };

  useEffect(() => {
    const myTimeout = setTimeout(redirect, 5000);

    return () => {
      clearTimeout(myTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applySuccess]);

  return (
    <>
      {applySuccess && <ApplyToJoinSuccess />}
      <div className="apply-to-join">
        <header className="apply-to-join-navbar-content">
          <div className="apply-to-join-navbar-div">
            <div
              className="apply-to-join-navbar-title"
              onClick={() => navigate("/sell")}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "2px" }}
              >
                <path
                  d="M19 12L5 12"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 19L5 12L12 5"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="apply-to-join-navbar-title-text">
                Back
              </p>
            </div>
            <div className="apply-to-join-navbar-logo">
              <img src="/images/losode-no-tagline.png" alt="losode-logo" />
            </div>
          </div>
        </header>
        <section className="apply-to-join-section">
          <div className="apply-to-join-section-content">
            <div className="apply-to-join-section-form">
              <Form
                form={sellerForm}
                scrollToFirstError
                layout="vertical"
                onFinish={onSubmit}
  
              >
                <Row
                  className="form-row"
                  gutter={[!isTabletOrMobile ? 80 : 0, 0]}
                  style={{
                    background: "white",
                    padding: !isTabletOrMobile ? "50px 140px" : "12px",
                    marginBottom: 40,
                  }}
                >
                  <div className="apply-to-join-section-content-title">
                    <h3>Join Our Designer's Waitlist</h3>
                    <p>
                    Please fill the form below, as soon as we start to trade in your region, we will let you know.
                    </p>
                  </div>
                  <Col
                    span={!isTabletOrMobile ? 12 : 24}
                    style={{ margin: "auto" }}
                  >
                    <Form.Item
                      name="brand_country"
                      label="Select Country"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Select
                        className="select"
                        placeholder="Select your Country"
                        value={countryValue}
                        onChange={(value) => setCountryValue(value)}
                      >
                        {newCountries.map(({ name, flag }) => (
                          <Option key={name} value={name}>
                            <img className="icon-flag" src={flag} alt={name} />
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    span={24}
                    style={{
                      margin: "20px 0 40px",
                      display: !isTabletOrMobile ? "block" : "none",
                    }}
                  >
                    <span
                      style={{
                        display: "block",
                        borderBottom: "1px solid #ccc",
                        width: "100%",
                      }}
                    />
                  </Col>

                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="firstname"
                      label="First Name"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                        {
                          pattern: /^[a-zA-Z]+$/g,
                          message: "Only letters are allowed",
                        },
                      ]}
                    >
                      <Input
                        className="reg-input"
                        placeholder="Enter your first name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="lastname"
                      label="Last Name"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                        {
                          pattern: /^[a-zA-Z]+$/g,
                          message: "Only letters are allowed",
                        },
                      ]}
                    >
                      <Input
                        className="reg-input"
                        placeholder="Enter your last name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_email"
                      label="Email Address"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          type: "email",
                          message: "Use a valid email address",
                        },
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input
                        className="reg-input"
                        placeholder="Enter your email address"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}
                  // onFocus={() => setPhoneNumberFilled(true)}
                  // onBlur={() => setPhoneNumberFilled(false)}
                  >
                    <Form.Item
                      name="brand_phone"
                      label="Phone Number"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Space.Compact style={{ width: "100%" }}>
                        <Select
                          style={{ width: "40%", 
                          // display: phoneNumberFilled ? "flex": "none"
                        }}
                          value={
                            sellerForm.getFieldValue("brand_phone_dial") ||
                            countryInfo?.phoneCode
                          }
                        >
                          <Option
                            key={countryInfo?.id}
                            value={countryInfo?.phoneCode}
                          >
                            <img
                              className="icon-flag"
                              src={countryInfo?.flag}
                              alt={countryInfo?.name}
                            />
                            {countryInfo?.phoneCode}
                          </Option>
                        </Select>
                        <Input
                          className="input reg-input"
                          type="number"
                          placeholder="Enter phone number"
                          onChange={(e) =>
                            sellerForm.setFieldsValue({ phone: e.target.value })
                          }
                          value={sellerForm.getFieldValue("phone")}
                        />
                      </Space.Compact>
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_what_i_sell"
                      label="What Does Your Brand Sell?"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select categories"
                        value={selectedCategories}
                        onChange={handleCategoryChange}
                        optionLabelProp="label"
                        className="select-multiple"
                      >
                        {categoryOptions.map((category) => (
                          <Select.Option
                            key={category}
                            value={category}
                            label={category}
                          >
                            <Checkbox
                              checked={selectedCategories.includes(category)}
                            >
                              {category}
                            </Checkbox>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {!isTabletOrMobile &&<div
                    style={{
                      width: "100%",
                      display: "flex",
                      margin: !isTabletOrMobile ? "40px" :"0px 0px 24px",
                      padding: !isTabletOrMobile ? "0px" :"12px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className="reg-btn-register"
                      type="primary"
                      htmlType="submit"
                    >
                      Join Waitlist
                    </Button>
                  </div>}
               
                </Row>
                {isTabletOrMobile &&
                <div
                    style={{
                      width: "100%",
                      display: "flex",
                      margin: !isTabletOrMobile ? "40px" :"0px 0px 24px",
                      padding: !isTabletOrMobile ? "0px" :"12px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className="reg-btn-register"
                      type="primary"
                      htmlType="submit"
                    >
                      Join Waitlist
                    </Button>
                  </div>}
              </Form>
            </div>
          </div>
        </section>

        <style>
          {`
        .apply-to-join {
          font-family: "DomaineSansText-Light";
          background: #f9f9f9
        }

        .apply-to-join-navbar-content {
          display: flex;
          justify-content: center;
          padding: 0 25px;
          align-items: center;
          background: white;
          width: 100%;
          height: 80px;
          box-shadow: 0 2px 10px 0 #05050526;
          z-index: 1000;
          position: fixed;
          top: 0;
        }

        .apply-to-join-navbar-div {
          width:80%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        .apply-to-join-navbar-logo img {
          height: 30px;
          width: 180px;
          object-fit: contain;
        }

        .apply-to-join-navbar-title {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;
          cursor: pointer;
          height: 100%;
          gap: 8px;
        }

        .apply-to-join-section {
          width: 100%;
          padding-top: 100px;
          padding-bottom: 30px;
        }

         .btn-upload {
          margin-right: 0px !important;
        }
        .btn-upload,
        .btn-upload:hover {
          background: #000000;
          color: #fff;
          height: 48px;
          width: 130px;
        }
        .apply-to-join-section-content {
          width: 80%;
          margin: 0px auto;          
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .apply-to-join-section-content-title {
          background-color:#fff;
          width: 100%;
          margin: 0px auto 20px;          
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .apply-to-join-section-content-title h3 {
          font-size: 24px;
          font-family: 'DomaineSansText-Black';
          font-weight: normal;
          margin-bottom: 4px;
        }
        .apply-to-join-section-content-title p {
          font-family: 'DomaineSansText-Regular';
          text-align: center;
          width: 500px;
          font-weight: 500;
          font-size: 16px;
        }
        .apply-to-join-section-form {
          width: 100%;
          padding-top: 20px;
        }

      .apply-to-join-section-form .ant-input-affix-wrapper{
        padding:0px 0px 0px 10px;
      }

        img {
          width: 20px;
          height: 20px;
          margin-right:10px;
        }
        .reg-input,
        .reg-date {
          // border: 0;
          border: 1px solid #ccc;
          font-size: 16px;
          font-weight: 400;
          height: 48px;
          width: 100%;
        }
        .reg-input::placeholder {
          font-family: 'DomaineSansText-Regular';
        }
        .ant-select-selection-placeholder {
          font-family: 'DomaineSansText-Regular';
        }
        .reg-input-phone {
          // border: 0;
          border: 1px solid #ccc;
          font-size: 16px;
          font-weight: 400;
          height: fit;
          width: 100%;
          padding: 0px; /* Adjust padding as needed */
        }
        .reg-input-phone .ant-input {
          height: 48px;
          border: none;
        }

        .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          display: none;
          content: ' ';
        }  
    
        .reg-input-phone:focus,
        .reg-input-phone:hover {
          border: 1px solid #000;
        }
        .reg-input:focus,
        .reg-input:hover {
          border: 1px solid #000;
        }

        .reg-btn-register {
          font: normal 16px DomaineSansText-Regular;
          width: 300px;
          height: 48px;
          margin-top: 10px;
        }

        .select-multiple{
          width: 90px;
          height: fit;
        }
        .ant-form-item {
          margin-bottom: 20px;
        }
        .ant-form-item-extra,
        .ant-form-item-explain {
          padding-top: 4px;
        }
        .ant-form-vertical .ant-form-item-label > label {
          font-family: DomaineSansText-Light;
          font-size: 14px;
          fon-weight: bold;
          text-transform: capitalize;
        }
        .ant-form-item-label > label {
          font-family: DomaineSansText-Light;
          font-size: 16px;
          fon-weight: bold;
          font: 700;
        }
        .ant-picker-input > input {
            font-size: 16px;
            font-weight: 300;
          }
        .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
            border: 1px solid #ccc;
            font-size: 16px;
            height: 48px;
            padding: 0px;
          }
          .prefix-select {
            width: 100px;
            height: 48px;
            border: none;
            outline: none;
          }
          .prefix-select .ant-select {
            width: 90px;
            height: 48px;
            border: 0;
            outline: 0;
          }
          
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
            display: flex;
            padding: 7px 10px;
        }
        .ant-picker:hover,
        .ant-picker-focused,
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border-color: black;
          border-right-width: 1px !important;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          height: 48px;
          border: 1px solid #ccc;
        }
        .ant-input-group-addon:first-child {
          border: 0;
          background: transparent;
          border-right: 1px solid #ccc;
          
        }

        .apply-to-join-navbar-title-text {
          margin: auto;
          text-transform: capitalize;
        }

        .apply-to-join-success-overlay {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3000;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .apply-to-join-success-div {
          position: absolute;
          background: black;
          color: white;
          padding: 30px 50px;
        }
        .apply-to-join-success-div h3{
          font: normal 24px DomaineSansText-Regular;
          color: white;
          text-align: center;
        }
        .apply-to-join-success-div p {
          font: normal 16px/24px DomaineSansText-Light;
          color: white;
          text-align: center;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }


        @media(max-width:640px){
          .apply-to-join-navbar-div {
            width: 100%;
          }
          .apply-to-join-section-content {
            width: auto;
            margin: 0px auto;
            padding: 0px;
          }
          // .apply-to-join-section-content-title{
          //   padding: 0 12px;
          // }
          .apply-to-join-section-content-title h3 {
            line-height:1;
            margin-bottom: 16px;
            padding-top: 20px;
          }
          .apply-to-join-section-content-title p {
            width: 100%;
            font-size: 13px;
            line-height:22px;
            padding: 0 20px;
            border-bottom: 1px solid #D4D4D4;
            padding-bottom: 12px;
          }

          .apply-to-join-navbar-title-text {
            // display: none;
            color:black;

          }
          .apply-to-join-navbar-content{
            padding: 0 17px;
            height:70px
          }
          .apply-to-join-section-form .ant-form-vertical .ant-form-item-label > label {
            font-size: 12px;
          }
          .apply-to-join-section-form .reg-input, 
          .apply-to-join-section-form .reg-date, 
          .apply-to-join-section-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
          .apply-to-join-section-form .ant-picker-input > input{
            font-size: 14px;
          }

          .icon-flag-country{
            margin-right:24px
          }
          .country-select.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
            color:black !important
          }
          .country-select .ant-select-arrow{
            display: none
          }
          .seller-welcome-txt-about{
            font-size: 20px;
            margin-bottom:16px;
            margin-top:20px
          }
          .seller-welcome-line{
            border-top: 1px solid #D4D4D46b;;
          }
          .btn-upload, .btn-upload:hover{
            width: auto;
            padding: 0 13px;
          }
          .policy-container{
            padding: 28px 12px;
          }
          .policy-container p{
            font-size: 12px !important;
            line-height: 20px !important;
            text-align: justify;
          }
          .policy-container .ant-checkbox-wrapper{
            align-items: flex-start;
          }
          .reg-btn-register{
            margin-top: 18px;
            width: 100%;
            font-family: DomaineSansText-Light;
          }
          .apply-to-join-success-div{
            margin: 12px;
            padding: 32px 24px;
          }
          .apply-to-join-success-div h3{
            font-size: 28px;
          }
          .apply-to-join-success-div p {
            font: normal 18px/30px DomaineSansText-Light;
        }


        }

        
      `}
        </style>
      </div>
    </>
  );
};

export default SellerApplyToWaitlist;
