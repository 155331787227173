import React from "react";
import { useMediaQuery } from "react-responsive";
import MailingListModalMobile from "./MailingListModalMobile";
import MailingListModalWeb from "./MailingListModalWeb";

function MailingListModal({
  handleCancel,
  visible,
  subscriberEmail,
}) {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  return (
    <>
      {isTabletOrMobile ? 
      <MailingListModalMobile
        handleCancel={handleCancel}
        visible={visible}
        subscriberEmail={subscriberEmail}
      /> : 
      <MailingListModalWeb
        handleCancel={handleCancel}
        visible={visible}
        subscriberEmail={subscriberEmail}
      />}
    </>
  );
}

export default MailingListModal;
