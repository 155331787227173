import { Button } from "antd";
// import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const ShopGender = () => {
  const navigate = useNavigate();
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const genders = [
    {
      id: "male",
      imageUrl: "https://res.cloudinary.com/losode-ng/image/upload/v1716063101/product-images/fjquglsgz9krufmarits.jpg",
      name: "Be The Man They Remember",
      description:
        "From laid-back cool to refined sophistication, our designs will make you stand out effortlessly. Aim for understated charm and bold confidence, own the stage no matter the occasion.",
      action: "Shop Men",
      cta_url: "/men-fashion/all/cid=1&name=men-products",
    },
    {
      id: "female",
      imageUrl: "https://res.cloudinary.com/losode-ng/image/upload/v1713928505/product-images/eqbhnq2iqfdtklzdxytm.jpg",
      name: "Play Dress-up With Confidence",
      description:
        "Discover flawless outfits, chic styles, and statement-making designs guaranteed to turn heads and leave a lasting impression. Elevate your presence with impeccable tailoring and timeless style.",
      action: "Shop Women",
      cta_url: "/women-fashion/all/cid=2&name=women-products",
    },
  ];

  return (
    <div className="shop-gender-main">
      {genders.map((gender, index) => (
        <div className="shop-gender-container" key={index}>
          {/* temporary link to specific product. for update */}
          <img
            src={gender.imageUrl}
            alt="shop"
            className="shop-gender-image"
            onClick={() =>
              gender.id === "female" ? navigate("/product/bell-pants-set") : ""
            }
          />
          <div className="shop-gender-content-container">
            <div>
              <div className="gender-title" style={{ color: "fff" }}>
                {gender.name}
              </div>
              <div className="gender-subtext">{gender.description}</div>
            </div>
            <Button
              className="btn-shop-shop-gender"
              onClick={() => navigate(gender.cta_url)}
            >
              {gender.action}
            </Button>
          </div>
        </div>
      ))}

      <style jsx="true">{`
        .shop-gender-main {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 32px;
          margin: 0 auto;
          padding: 35px 4rem;
        }
        .shop-gender-container {
          position: relative;
          flex: 1;
        }
        .shop-gender-image {
          width: 100%;
          // height: 816px;
          object-fit: cover;
          object-position: top;
          aspect-ratio: 11/16;
          cursor: pointer;
        }
        .shop-gender-container .btn-shop-shop-gender {
          width: 350px;
          height: 48px;
          border-radius: 2px;
          font: normal normal 16px DomaineSansText-Regular;
          margin-top: 24px;
          background-color: #000000;
          color: #fff;
        }
        .shop-gender-content-container {
          text-align: left;
          width: 100%;
          padding: 16px 0 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // height: 100%;
        }
        .shop-gender-content-container .gender-title {
          font-size: 30px;
          font-family: DomaineSansText-Medium;
          margin-bottom: 4px;
          line-height: 30px;
        }
        .shop-gender-content-container .gender-subtext {
          font-size: 16px;
          font-family: DomaineSansText-Light;
          line-height: 1.4;
        }
      `}</style>
    </div>
  );
};

export default ShopGender;
