import { Button, Carousel } from "antd";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const Banner = () => {

  const { type } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div className="banner-container">
      {!isTabletOrMobile ? (
        <div className="bannercontainer-image-container">
          <img
            src="https://res.cloudinary.com/losode-ng/image/upload/w_800/f_auto/v1728530712/website-pictures-optimized/home-main-image-1_u8uyxj.png"
            alt="banner"
            className="bannercontainer-image"
            height={600}
            width={600}
          />
          <img
            src="https://res.cloudinary.com/losode-ng/image/upload/w_800/f_auto/v1728530746/website-pictures-optimized/home-main-image-2_t8r3tw.png"
            alt="banner"
            className="bannercontainer-image"
            height={600}
            width={600}
          />
          <img
            src="https://res.cloudinary.com/losode-ng/image/upload/w_800/f_auto/v1728530688/website-pictures-optimized/home-main-image-3_fvoy9k.png"
            alt="banner"
            className="bannercontainer-image"
            height={600}
            width={600}
          />
        </div>
      ) : (
        <Carousel fade autoplay autoplaySpeed={5000} draggable speed={1200}>
          <img
            src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1728530712/website-pictures-optimized/home-main-image-1_u8uyxj.png"
            alt="banner"
            className="bannercontainer-image"
          />
          <img
            src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1728530746/website-pictures-optimized/home-main-image-2_t8r3tw.png"
            alt="banner"
            className="bannercontainer-image"
          />
          <img
            src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1728530688/website-pictures-optimized/home-main-image-3_fvoy9k.png"
            alt="banner"
            className="bannercontainer-image"
          />
        </Carousel>
      )}

      <div className="banner-content-container">
        <div className="banner-content-title">
          Discover Fashion Without Limits, from Elegant Classics to Bold
          Statements
        </div>
        <div className="subtext">
          Explore a world of style with hundreds of extravagant designers
          offering everything from subtle, sophisticated pieces to vibrant
          colors and daring, elaborate designs. Whether you are seeking timeless
          elegance or eye-catching fashion, We connect you with exclusive
          collections
        </div>
        <Button
          type={isTabletOrMobile ? "link" : "default"}
          onClick={() => shopNewIn()}
          className="shop-now-btn"
        >
          <span
            style={{ textDecoration: isTabletOrMobile ? "underline" : "none" }}
          >
            Shop now
          </span>
        </Button>
      </div>

      <style jsx="true">{`
        .banner-container {
          position: relative;
          background-position: top;
        }
        .bannercontainer-image-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          gap: 0px;
        }
        .bannercontainer-image {
          flex: 1;
          height: auto;
          width: 33%;
          object-fit: cover;
          object-position: top;
        }
        .banner-content-container {
          margin: 16px auto 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          bottom: 0px;
          left: 0px;
          padding: 16px 16px 0;
        }
        .banner-content-container .banner-content-title {
          // font-family: 'DomaineSansDisplay-SemiBold';
          font-family: "DomaineSansText-Medium";
          font-size: 30px;
          text-align: center;
          line-height: 30px;
          margin: 8px auto;
        }
        .banner-content-container .subtext {
          font-size: 16px;
          font-family: DomaineSansText-Light;
          line-height: 1.4;
          max-width: 1100px;
          margin: 8px auto 16px;
        }

        .banner-content-container .shop-now-btn {
          color: white;
          background-color: #000;
          font-family: "DomaineSansText-Light";
          padding: 0px;
          height: 48px;
          font-size: 16px;
          margin-top: 8px;
          text-transform: capitalize;
          width: 350px;
          border-radius: 2px;
        }

        @media (max-width: 640px) {
          .banner-container {
            position: relative;
            background-position: top;
          }
          .bannercontainer-image-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 0px;
          }
          .bannercontainer-image {
            flex: 1;
            height: 542px;
            width: 100%;
            object-fit: cover;
            object-position: top;
          }
          .banner-container .ant-carousel .slick-dots-bottom li button {
            bottom: 14px;
            height: 10px;
            border-radius: 130px;
            width: 10px;
            opacity: 0.5;
          }
          .banner-container .ant-carousel .slick-dots li.slick-active button {
            width: 20px;
            opacity: 1;
          }
          .banner-container .ant-carousel .slick-dots-bottom li button:before {
            display: none;
          }
          .banner-content-container {
            margin: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            bottom: 0px;
            left: 0px;
            padding: 16px 16px 0;
          }
          .banner-content-container .banner-content-title {
            font-family: "DomaineSansDisplay-SemiBold";
            font-size: 30px;
            text-align: center;
            line-height: 30px;
            margin: 8px auto;
          }
          .banner-content-container .subtext {
            font-size: 14px;
            font-family: DomaineSansText-Light;
            line-height: 18px;
          }

          .banner-content-container .shop-now-btn {
            color: rgb(0, 0, 0);
            font-family: "DomaineSansText-Medium";
            padding: 0px;
            height: auto;
            font-size: 14px;
            margin-top: 8px;
            text-transform: capitalize;
            width: auto;
          }
        }
      `}</style>
    </div>
  );
};

export default Banner;
