import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Checkbox, Form, Input, Col, Row, Select, Button, Space } from "antd";
import { useMediaQuery } from "react-responsive";
import { countries } from "../assets/constants";
import axios from "axios";
import { fetchStates } from "../utils/getStates";
import triggerNotification from "../hooks/triggerNotification";
import PhoneDial from "../components/PhoneDial";

const { Option } = Select;

const ApplyToJoinSuccess = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  return (
    <div className="apply-to-join-success-overlay">
      <div className="apply-to-join-success-div">
        <h3>Your application has been submitted successfully!</h3>
        <p style={{ padding: "20px 10px" }}>
          An email containing next steps will be sent to you shortly.
        </p>
        {!isTabletOrMobile && (
          <p>You will now be redirected to the seller homepage</p>
        )}
      </div>
    </div>
  );
};

const SellerApplyToJoin = () => {
  const navigate = useNavigate();
  const [sellerForm] = Form.useForm();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const [countryValue, setCountryValue] = useState("Nigeria");
  const [states, setStates] = useState([]);

  const [applySuccess, setApplySuccess] = useState(false);

  // const handleSelectChange = (value) => {
  //   setSelectedOption(value);
  // };

  const onSelectCountry = async () => {
    if (countryValue && states.length === 0) {
      const res = await fetchStates(countryValue);
      setStates(res);
    }
  };

  useEffect(() => {
    onSelectCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryValue]);

  // const validateSection = (formitemArray) =>
  //   sellerForm
  //     .validateFields(formitemArray)
  //     .then((values) => {
  //       setActiveMobileSection((prev) => prev + 1);
  //     })
  //     .catch((errorInfo) => {
  //       console.error("Failed:", errorInfo);
  //     });

  const onSubmit = async (values) => {
    values.brand_phone_dial = "+234";
    try {
      const { data } = await axios.post(
        "https://www.losode.dev/api/v2/application/join",
        values
      );
      if (data.status === "ok") {
        setApplySuccess(true);
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: error.response.data.message,
      });
    }
  };

  // const handleCategoryChange = (selected) => {
  //   setSelectedCategories(selected);
  // };

  const redirect = () => {
    if (applySuccess === true) {
      navigate("/sell");
    }
  };

  useEffect(() => {
    const myTimeout = setTimeout(redirect, 5000);

    return () => {
      clearTimeout(myTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applySuccess]);

  // const [activeMobileSection, setActiveMobileSection] = useState(0);

  // const mobileSteps = (
  //   <div style={{ width: "100%", margin: "24px 0" }}>
  //     {activeMobileSection !== 0 && (
  //       <div
  //         style={{
  //           display: "flex",
  //           alignItems: "center",
  //           marginBottom: "16px",
  //           width: "fit-content",
  //         }}
  //         onClick={() => setActiveMobileSection((prev) => prev - 1)}
  //       >
  //         <svg
  //           width="20"
  //           height="20"
  //           viewBox="0 0 24 24"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //           style={{ marginRight: "2px" }}
  //         >
  //           <path
  //             d="M19 12L5 12"
  //             stroke="black"
  //             stroke-linecap="round"
  //             stroke-linejoin="round"
  //           />
  //           <path
  //             d="M12 19L5 12L12 5"
  //             stroke="black"
  //             stroke-linecap="round"
  //             stroke-linejoin="round"
  //           />
  //         </svg>
  //         <p className="apply-to-join-navbar-title-text">Back</p>
  //       </div>
  //     )}
  //     <div
  //       style={{
  //         fontSize: "10px",
  //         fontFamily: "DomaineSansText-Regular",
  //         marginBottom: "8px",
  //       }}
  //     >
  //       step {activeMobileSection + 1} of 2
  //     </div>
  //     <div style={{ display: "flex", width: "100%", gap: "20px" }}>
  //       {[1, 2].map((step, index) => (
  //         <div
  //           key={index}
  //           style={{
  //             height: "3px",
  //             background: activeMobileSection >= index ? "#800000" : "#D9D9D9",
  //             borderRadius: "8px",
  //             flex: "1",
  //           }}
  //         ></div>
  //       ))}
  //     </div>
  //   </div>
  // );

  return (
    <>
      {applySuccess && <ApplyToJoinSuccess />}

      <div className="apply-to-join">
        <header className="apply-to-join-navbar-content">
          <div className="apply-to-join-navbar-div">
            {!isTabletOrMobile && (
              <div
                className="apply-to-join-navbar-title"
                onClick={() => navigate("/sell")}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "2px" }}
                >
                  <path
                    d="M19 12L5 12"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 19L5 12L12 5"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="apply-to-join-navbar-title-text">Back</p>
              </div>
            )}
            <div className="apply-to-join-navbar-logo">
              <img src="/images/losode-no-tagline.png" alt="losode-logo" />
            </div>
          </div>
        </header>
        <section className="apply-to-join-section">
          <div className="apply-to-join-section-content">
            <div className="apply-to-join-section-form">
              <Form
                form={sellerForm}
                scrollToFirstError
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                  brand_country: "Nigeria",
                  brand_phone_dial: "+234",
                }}
              >
                <Row
                  className="form-row"
                  gutter={[!isTabletOrMobile ? 80 : 0, 0]}
                  style={{
                    background: "white",
                    padding: !isTabletOrMobile ? "50px" : "12px",
                    marginBottom: 40,
                    display: "flex",
                  }}
                >
                  <div className="apply-to-join-section-content-title">
                    <h3>Apply To Join Losode</h3>
                    <p>
                      Please enter your business details in the form below. A
                      member of our team will review your application.
                    </p>
                  </div>
                  {/* {isTabletOrMobile && mobileSteps} */}
                  {isTabletOrMobile && (
                    <Col span={24} style={{ marginBottom: 20 }}>
                      <h2 className="seller-welcome-txt-about">About You</h2>
                      <hr className="seller-welcome-line" />
                    </Col>
                  )}
                  <Col
                    span={!isTabletOrMobile ? 12 : 24}
                    style={{ margin: "auto" }}
                  >
                    <Form.Item
                      name="brand_country"
                      label="Country"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Select
                        className="select country-select"
                        placeholder="Select Country"
                        optionFilterProp="children"
                        defaultValue={countryValue}
                        value={countryValue}
                        onChange={(value) => setCountryValue(value)}
                        disabled
                      >
                        {countries.map(({ country, icon }) => (
                          <Option key={country} value={country}>
                            <img
                              className="icon-flag icon-flag-country"
                              src={icon}
                              alt={country}
                            />
                            {country}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {!isTabletOrMobile && (
                    <Col span={24} style={{ marginBottom: 20 }}>
                      <h2 className="seller-welcome-txt-about">About You</h2>
                      <hr className="seller-welcome-line" />
                    </Col>
                  )}

                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="firstname"
                      label="First Name"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                        {
                          pattern: /^[a-zA-Z]+$/g,
                          message: "Only letters are allowed",
                        },
                      ]}
                    >
                      <Input
                        className="reg-input"
                        placeholder="Enter your first name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="lastname"
                      label="Last Name"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                        {
                          pattern: /^[a-zA-Z]+$/g,
                          message: "Only letters are allowed",
                        },
                      ]}
                    >
                      <Input
                        className="reg-input"
                        placeholder="Enter your last name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="gender"
                      label="Gender"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Gender is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select your gender"
                        className="select"
                      >
                        <Select.Option value="Male">Male</Select.Option>
                        <Select.Option value="Female">Female</Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_phone"
                      label="Phone Number"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Space.Compact style={{ width: "100%" }}>
                        <PhoneDial
                          value={sellerForm.getFieldValue("brand_phone_dial")}
                          country={countries[0]}
                        />

                        <Input
                          className="input reg-input"
                          type="number"
                          placeholder="Enter phone number"
                          onChange={(e) =>
                            sellerForm.setFieldsValue({ phone: e.target.value })
                          }
                          value={sellerForm.getFieldValue("phone")}
                        />
                      </Space.Compact>
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_name"
                      label="Brand Name"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input
                        className="reg-input"
                        placeholder="Enter your brand name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_email"
                      label="Email Address"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          type: "email",
                          message: "Use a valid email address",
                        },
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input
                        className="reg-input"
                        placeholder="Enter your brand email address"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_social_platform"
                      label="Brand Social Media Platform"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select your media platform"
                        className="select"
                      >
                        <Select.Option value="twitter(x)">
                          Twitter(x)
                        </Select.Option>
                        <Select.Option value="instagram">
                          Instagram
                        </Select.Option>
                        <Select.Option value="facebook">Facebook</Select.Option>
                        <Select.Option value="threads">Threads</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_social_media_profile"
                      label="Social Media Profile Link"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input
                        className="reg-input"
                        placeholder="Enter your brand social media profile link"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="how_you_heard_about_us"
                      label="Tell Us How You Heard About Losode"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Select className="select" placeholder="Select an option">
                        <Select.Option value="search engine">
                          Search Engine
                        </Select.Option>
                        <Select.Option value="referred by a friend">
                          Referred by a friend
                        </Select.Option>
                        <Select.Option value="advert">Advert</Select.Option>
                        <Select.Option value="social media">
                          Facebook / Intagram / Twitter / LinkedIn
                        </Select.Option>
                        <Select.Option value="losode event">
                          A Losode Event
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_state"
                      label="State"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Select
                        className="select"
                        placeholder="Select your state"
                        showSearch
                      >
                        {states?.map(({ state_name: state }) => (
                          <Option key={state} value={state}>
                            {state}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* {isTabletOrMobile && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Button
                        className="reg-btn-register"
                        type="primary"
                        onClick={() =>
                          validateSection([
                            "firstname",
                            "lastname",
                            "gender",
                            "brand_phone",
                          ])
                        }
                      >
                        Continue
                      </Button>
                    </Col>
                  )} */}
                </Row>
                {/* 
                <Row
                  className="form-row"
                  gutter={[30, 0]}
                  style={{
                    background: "white",
                    padding: !isTabletOrMobile ? "50px 140px" : "12px",
                    display:
                      !isTabletOrMobile || activeMobileSection !== 0
                        ? "flex"
                        : "none",
                  }}
                >
                  <div
                    style={{
                      display:
                        !isTabletOrMobile || activeMobileSection === 1
                          ? "contents"
                          : "none",
                    }}
                  >
                    {isTabletOrMobile && (
                      <div style={{ width: "100%", padding: "0 15px" }}>
                        {mobileSteps}
                      </div>
                    )}
                    <Col span={24} style={{ marginBottom: 20 }}>
                      <h2 className="seller-welcome-txt-about">
                        About Your Brand
                      </h2>
                      <hr className="seller-welcome-line" />
                    </Col>

                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_logo_url"
                        label="Upload Brand Logo"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Input
                          className="reg-input"
                          placeholder="Use a professionally designed logo"
                          onChange={uploadImage}
                          suffix={
                            <>
                              <Button
                                className="btn-upload"
                                onClick={handleClick}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "end",
                                  }}
                                >
                                  <img alt="upload" src="/images/upload.png" />
                                  <span
                                    style={{
                                      fontSize: `${
                                        !isTabletOrMobile ? "14px" : "12px"
                                      }`,
                                    }}
                                  >
                                    Browse
                                  </span>
                                </div>
                              </Button>
                              <input
                                accept="image/*"
                                type="file"
                                ref={hiddenFileInput}
                                onChange={handleChange}
                                style={{ display: "none" }}
                              />
                            </>
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_description"
                        label="Tell us about your brand"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field!",
                          },
                        ]}
                      >
                        <Input.TextArea
                          className="input"
                          style={{
                            padding: "12px",
                            minHeight: "80px",
                            border: "1px solid #0000007a",
                          }}
                          placeholder="Give a detailed description of your brand"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_address"
                        label="Address"
                        labelAlign="top"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Input
                          className="reg-input"
                          placeholder="Enter your brand address"
                        />
                      </Form.Item>
                    </Col>
                    {isTabletOrMobile && (
                      <Button
                        className="reg-btn-register"
                        type="primary"
                        style={{ marginLeft: "15px", marginRight: "15px" }}
                        onClick={() =>
                          validateSection([
                            "brand_name",
                            "brand_email",
                            "brand_logo_url",
                            "brand_description",
                            "brand_address",
                          ])
                        }
                      >
                        Continue
                      </Button>
                    )}
                  </div>

                  <div
                    style={{
                      display:
                        !isTabletOrMobile || activeMobileSection === 2
                          ? "contents"
                          : "none",
                    }}
                  >
                    {isTabletOrMobile && (
                      <div style={{ width: "100%", padding: "0 15px" }}>
                        {mobileSteps}
                      </div>
                    )}
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_state"
                        label="State"
                        labelAlign="top"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Select
                          className="select"
                          placeholder="Select your state"
                          showSearch
                        >
                          {states?.map(({ state_name: state }) => (
                            <Option key={state} value={state}>
                              {state}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_city"
                        label="City"
                        labelAlign="top"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Select
                          className="select"
                          placeholder="Select your city"
                          showSearch
                        >
                          {cities?.map((city, index) => (
                            <Option key={index} value={city}>
                              {city}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_area"
                        label="Area"
                        labelAlign="top"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input
                          className="reg-input"
                          placeholder="Enter your area"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_postal_code"
                        label="Postal Code (Optional)"
                        labelAlign="top"
                        labelCol={{ span: 24 }}
                      >
                        <Input
                          className="reg-input"
                          placeholder="Enter post code"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_has_website"
                        label="Do you have a website?"
                        labelAlign="top"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Select
                          placeholder=""
                          className="select"
                          value={selectedOption}
                          onChange={handleSelectChange}
                        >
                          <Select.Option value="yes">Yes</Select.Option>
                          <Select.Option value="no">No</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {selectedOption === "yes" && (
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="brand_website_url"
                          label="Please provide your website detail"
                          labelAlign="top"
                          labelCol={{ span: 24 }}
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          <Input
                            placeholder="www.example.com"
                            className="reg-input"
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {isTabletOrMobile && (
                      <Button
                        className="reg-btn-register"
                        style={{ marginLeft: "15px", marginRight: "15px" }}
                        type="primary"
                        onClick={() =>
                          validateSection([
                            "brand_state",
                            "brand_city",
                            "brand_area",
                            "brand_has_website",
                          ])
                        }
                      >
                        Continue
                      </Button>
                    )}
                  </div>

                  <div
                    style={{
                      display:
                        !isTabletOrMobile || activeMobileSection === 3
                          ? "contents"
                          : "none",
                    }}
                  >
                    {isTabletOrMobile && (
                      <div style={{ width: "100%", padding: "0 15px" }}>
                        {mobileSteps}
                      </div>
                    )}
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_social_platform"
                        label="Brand Social Media Platform"
                        labelAlign="top"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select your media platform"
                          className="select"
                        >
                          <Select.Option value="twitter(x)">
                            Twitter(x)
                          </Select.Option>
                          <Select.Option value="instagram">
                            Instagram
                          </Select.Option>
                          <Select.Option value="facebook">
                            Facebook
                          </Select.Option>
                          <Select.Option value="threads">Threads</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_social_media_profile"
                        label="Social Media Profile Link"
                        labelAlign="top"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Input
                          className="reg-input"
                          placeholder="Enter your brand social media profile link"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_established_date"
                        label="When was your brand Established"
                        labelAlign="top"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            type: "object",
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <DatePicker className="reg-date" />
                      </Form.Item>
                    </Col>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_what_i_sell"
                        label="What Does Your Brand Sell?"
                        labelAlign="top"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          placeholder="Select categories"
                          value={selectedCategories}
                          onChange={handleCategoryChange}
                          optionLabelProp="label"
                          className="select-multiple"
                        >
                          {categoryOptions.map((category) => (
                            <Select.Option
                              key={category}
                              value={category}
                              label={category}
                            >
                              <Checkbox
                                checked={selectedCategories.includes(category)}
                              >
                                {category}
                              </Checkbox>
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="how_you_heard_about_us"
                        label="Tell Us How You Heard About Losode"
                        labelAlign="top"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Select
                          className="select"
                          placeholder="Select an option"
                        >
                          <Select.Option value="search engine">
                            Search Engine
                          </Select.Option>
                          <Select.Option value="referred by a friend">
                            Referred by a friend
                          </Select.Option>
                          <Select.Option value="advert">Advert</Select.Option>
                          <Select.Option value="social media">
                            Facebook / Intagram / Twitter / LinkedIn
                          </Select.Option>
                          <Select.Option value="losode event">
                            A Losode Event
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </div>
                </Row> */}

                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                  className="policy-container"
                >
                  <Form.Item
                    name="confirmed_policies"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Checkbox
                      onChange={(e) =>
                        sellerForm.setFieldsValue({
                          confirmed_policies: e.target.checked,
                        })
                      }
                    >
                      <p style={{ width: "100%" }} className="policy-container-text">
                        I confirm that i have read and agree with the Losode{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          <Link to="/sell-terms" style={{ textDecoration: "underline" }}>
                            Terms and Condition
                          </Link>
                          ,{" "}
                          <Link to="/policy" style={{ textDecoration: "underline" }}>
                            Privacy Policy
                          </Link>{" "}
                          and{" "}
                          <Link to="/policy" style={{ textDecoration: "underline" }}>
                            Cookies Policy
                          </Link>
                        </span>
                        {!isTabletOrMobile ? <br /> : " "}
                        We will only use your personal information to keep you
                        updated on our products and services.
                      </p>
                    </Checkbox>
                  </Form.Item>

                  <Button
                    className="reg-btn-register"
                    type="primary"
                    htmlType="submit"
                  >
                    Join Losode
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </section>

        <style>
          {`
        .apply-to-join {
          font-family: "DomaineSansText-Light";
          background: #f9f9f9
        }

        .apply-to-join-navbar-content {
          display: flex;
          justify-content: center;
          padding: 0 25px;
          align-items: center;
          background: white;
          width: 100%;
          height: 80px;
          box-shadow: 0 2px 10px 0 #05050526;
          z-index: 1000;
          position: fixed;
          top: 0;
        }

        .apply-to-join-navbar-div {
          width:80%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        .apply-to-join-navbar-logo img {
          height: 30px;
          width: 180px;
          object-fit: contain;
        }

        .apply-to-join-navbar-title {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;
          cursor: pointer;
          height: 100%;
          gap: 8px;
        }

        .apply-to-join-section {
          width: 100%;
          padding-top: 100px;
          padding-bottom: 30px;
        }

         .btn-upload {
          margin-right: 0px !important;
        }
        .btn-upload,
        .btn-upload:hover {
          background: #000000;
          color: #fff;
          height: 48px;
          width: 130px;
        }
        .apply-to-join-section-content {
          width: 80%;
          margin: 0px auto;          
          display: flex;
          flex-direction: column;
          align-items: center;
        }
          .apply-to-join-section-content-title {
        background-color:#fff;
          width: 100%;
          margin: 0px auto 20px;          
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .apply-to-join-section-content-title h3 {
          font-size: 24px;
          font-family: 'DomaineSansText-Black';
          font-weight: normal;
          margin-bottom: 4px;
        }
        .apply-to-join-section-content-title p {
          font-family: 'DomaineSansText-Regular';
          text-align: center;
          width: 500px;
          font-weight: 500;
          font-size: 16px;
        }
        .apply-to-join-section-form {
          width: 100%;
          padding-top: 20px;
        }

      .apply-to-join-section-form .ant-input-affix-wrapper{
        padding:0px 0px 0px 10px;
      }

        img {
          width: 20px;
          height: 20px;
          margin-right:10px;
        }
        .reg-input,
        .reg-date {
          // border: 0;
          border: 1px solid #6F6F6F;
          font-size: 16px;
          font-weight: 400;
          height: 48px;
          width: 100%;
        }
        .reg-input::placeholder {
          font-family: 'DomaineSansText-Regular';
        }
        .ant-select-selection-placeholder {
          font-family: 'DomaineSansText-Regular';
        }
        .policy-container-text {
            font-family: DomaineSansText-Regular;
        }
        .reg-input-phone {
          // border: 0;
          border: 1px solid #6F6F6F;
          font-size: 16px;
          font-weight: 400;
          height: fit;
          width: 100%;
          padding: 0px; /* Adjust padding as needed */
        }
        .reg-input-phone .ant-input {
          height: 48px;
          border: none;
        }

        .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          display: none;
          content: ' ';
        }  
    
        .reg-input-phone:focus,
        .reg-input-phone:hover {
          border: 1px solid #000;
        }
        .reg-input:focus,
        .reg-input:hover {
          border: 1px solid #000;
        }

        .reg-btn-register {
          font: normal 16px DomaineSansText-Regular;
          width: 300px;
          height: 48px;
          margin-top: 10px;
        }

        .select-multiple{
          width: 90px;
          height: fit;
        }
        .ant-form-item {
          margin-bottom: 20px;
        }
        .ant-form-item-extra,
        .ant-form-item-explain {
          padding-top: 4px;
        }
        .ant-form-vertical .ant-form-item-label > label {
          font-family: DomaineSansText-Light;
          font-size: 14px;
          fon-weight: bold;
          text-transform: capitalize;
        }
        .ant-form-item-label > label {
          font-family: DomaineSansText-Light;
          font-size: 16px;
          fon-weight: bold;
          font: 700;
        }
        .ant-picker-input > input {
            font-size: 16px;
            font-weight: 300;
          }
          input:{
            font-size: 12px;
          } 
        .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
            border: 1px solid #6F6F6F;
            font-size: 16px;
            height: 48px;
            padding: 0px;
          }
          .prefix-select {
            width: 100px;
            height: 48px;
            border: none;
            outline: none;
          }
          .prefix-select .ant-select {
            width: 90px;
            height: 48px;
            border: 0;
            outline: 0;
          }

          .ant-select-single .ant-select-selector .ant-select-selection-search{
            top:10px;
          }
          
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
            display: flex;
            font-size:13px;
            padding: 7px 10px;
        }
        .ant-input:placeholder-shown,.ant-picker-input > input:placeholder-shown,.ant-select-multiple .ant-select-selection-placeholder{
           font-size:13px;
        }
        .ant-picker:hover,
        .ant-picker-focused,
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border-color: black;
          border-right-width: 1px !important;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          height: 48px;
          border: 1px solid #6F6F6F;
        }
        .ant-input-group-addon:first-child {
          border: 0;
          background: transparent;
          border-right: 1px solid #6F6F6F;
          
        }

        .apply-to-join-navbar-title-text {
          margin: auto;
          text-transform: capitalize;
        }

        .apply-to-join-success-overlay {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3000;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .apply-to-join-success-div {
          position: absolute;
          background: black;
          color: white;
          padding: 30px 50px;
        }
        .apply-to-join-success-div h3{
          font: normal 24px DomaineSansText-Regular;
          color: white;
          text-align: center;
        }
        .apply-to-join-success-div p {
          font: normal 16px/24px DomaineSansText-Light;
          color: white;
          text-align: center;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }


        @media(max-width:640px){
          .apply-to-join-navbar-div {
            width: 100%;
          }
          .apply-to-join-section-content {
            width: auto;
            margin: 0px auto;
            padding: 0px;
          }
          .apply-to-join-section-content-title h3 {
            line-height:1;
            margin-bottom: 16px;
            padding-top: 20px;
          }
          .apply-to-join-section-content-title p {
            width: 100%;
            font-size: 13px;
            line-height:22px;
            padding: 0 20px;
            border-bottom: 1px solid #D4D4D4;
            padding-bottom: 12px;
          }

          .apply-to-join-navbar-title-text {
            // display: none;
            color:black;

          }
          .apply-to-join-navbar-content{
            padding: 0 17px;
            height:70px
          }
          .apply-to-join-section-form .ant-form-vertical .ant-form-item-label > label {
            font-size: 12px;
          }
          .apply-to-join-section-form .reg-input, 
          .apply-to-join-section-form .reg-date, 
          .apply-to-join-section-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
          .apply-to-join-section-form .ant-picker-input > input{
            font-size: 14px;
          }

          .icon-flag-country{
            margin-right:24px
          }
          .country-select.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
            color:black !important
          }
          .country-select .ant-select-arrow{
            display: none
          }
          .seller-welcome-txt-about{
            font-size: 20px;
            margin-bottom:16px;
            margin-top:20px
          }
          .seller-welcome-line{
            border-top: 1px solid #D4D4D46b;;
          }
          .btn-upload, .btn-upload:hover{
            width: auto;
            padding: 0 13px;
          }
          .policy-container{
            padding: 28px 12px;

          }
          .policy-container p{
            font-size: 12px !important;
            line-height: 20px !important;
            text-align: justify;
            font-family: 'DomaineSansText-Regular';
            color: red;
          }
          .policy-container .ant-checkbox-wrapper{
            align-items: flex-start;
          }
          .reg-btn-register{
            margin-top: 18px;
            width: 100%;
            font-family: DomaineSansText-Light;
          }
          .apply-to-join-success-div{
            margin: 12px;
            padding: 32px 24px;
          }
          .apply-to-join-success-div h3{
            font-size: 28px;
          }
          .apply-to-join-success-div p {
            font: normal 18px/30px DomaineSansText-Light;
        }


        }

        
      `}
        </style>
      </div>
    </>
  );
};

export default SellerApplyToJoin;
