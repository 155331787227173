import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { productsActionCreators } from "../../../redux/products";
import { useEffect } from "react";
import formatter from "../../../utils/formatter";

const Bestseller = ({products}) => {
  const navigate = useNavigate();

  const { data:currencyData } = useSelector((state) => state.currency);

  // const { type } = useSelector((state) => state.user);

  const {
    homepageProducts,
  } = useSelector((state) => state.products);

  const dispatch = useDispatch();

  const { fetchHomepageProductsAction } = bindActionCreators(
    productsActionCreators,
    dispatch
  );

  useEffect(() => {
    fetchHomepageProductsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optimizeImageSize = (url = "") => {
    const urlParts = url?.split("upload/");
    const optimizedUrl = urlParts[0] + "upload/w_360/q_auto,f_auto/" + urlParts[1];
    return optimizedUrl;
  };

  return (
    <div className="bestseller-container">
      <div className="bestseller-head">
        <div className="bestseller-title">
          Unique Fashion Finds from Our Stylists
        </div>
        <p className="bestseller-subtext">
          Explore exclusive, trend-defining pieces curated by our expert
          stylists. Transform your wardrobe with Losode’s unique,
          fashion-forward finds. Want to be featured? Join Losode Now to
          showcase your items to a global audience{" "}
        </p>
      </div>

      <div className="bestseller-products-container">
        {homepageProducts?.bestsellers?.map((product) => (
          <div
            className="bestseller-product"
            key={product.img1}
            onClick={() => navigate(`/designers/${product?.store_slug}`)}
          >
            <img
              className="bestseller-product-image"
              loading="lazy"
              alt="product"
              src={optimizeImageSize(product.img1)}
            />
            <div style={{textAlign:'left', fontFamily:'DomaineSansText-Light'}}>
              <div className="bestseller-product-title">{product.store_name}</div>
              <div className="bestseller-product-subtitle">{product.name}</div>
              <div className="bestseller-product-price">
              {formatter(
                  product?.price,
                  currencyData?.rate,
                  currencyData?.code,
                  currencyData?.indicator
                )}
              </div>
            </div>
            <Button className="mobile-bestseller-btn" 
            // onClick={() => shopNewIn()}
            >
                  Shop Now
            </Button>
          </div>
        ))}
      </div>

      <style jsx="true">{`
        .bestseller-container {
          margin: 12px 0;
          padding: 16px 0;
          background: #f9f9f9;
        }
        .bestseller-head {
          margin-bottom: 20px;
        }
        .bestseller-container .bestseller-title {
          font-family: 'DomaineSansText-Medium';
          font-size: 28px;
          text-align: center;
          line-height: 26px;
          margin: 0 auto 16px;
          font-weight: 600 !important;
        }
        .bestseller-subtext {
          font-size: 14px;
          font-family: DomaineSansText-Light;
          line-height: 1.4;
          max-width: 90%;
          margin: 8px auto 16px;
          text-align: center
        }
        .bestseller-products-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 16px;
          justify-content: center;
          padding: 0 16px;
          text-align: center;
        }
        .bestseller-product-image{
          width: 100%;
          height: auto;
          aspect-ratio: 11/16;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        .bestseller-product-title{
          font-size: 12px;
          font-family: DomaineSansText-Medium;
          line-height: 1;
          margin: 10px 0px 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-transform: uppercase;
        }
        .bestseller-product-subtitle{
          font-size: 12px;
          font-family: DomaineSansText-Light;
          line-height: 1;
          margin: 0px 0px 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .bestseller-product-price{
          font-size: 12px;
          font-family: DomaineSansText-Medium;
          color: #000;
        }
        .mobile-bestseller-btn {
          width: 100%;
          height: 40px;
          background: #000000;
          border-radius: 2px;
          color: #fff;
          font: normal normal 14px DomaineSansText-Regular;
          margin: 16px 0;
        }

      `}</style>
    </div>
  );
};

export default Bestseller;
