import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const RandomCategory = () => {


  const navigate = useNavigate();

  return (
    <div className="randomcategory-container">
      <img
        src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732098401/website-pictures-optimized/men-random-cat_oeixiu.png"
        alt="random-category"
        className="randomcategory-image"
        height={600}
        width={1000}
      />
      <div className="randomcategory-content-container">
        <div className="text-style-premain">Brands</div>
        <div className="txt-style-main">Casual Shirts</div>
        <div className="subtext">Ease your way into your summer wishlist</div>
        <Button
          className="btn-shop-randomcategory"
          style={{ marginTop: "20px" }}
          onClick={() =>
            navigate("/products/category/cid=57&name=men-shirts-tshirts?page=1")
          }
        >
          Shop Now
        </Button>
      </div>
      <style jsx="true">{`
        .randomcategory-container {
          position: relative;
          background-position: top;
          margin: 0 4rem;
          padding: 20px 0;
        }
        .randomcategory-image {
          width: 100%;
          height: 100%;
        }
        .randomcategory-container .btn-shop-randomcategory {
          width: 426px;
          height: 48px;
          background: #fff;
          border-radius: 2px;
          border: none;
          color: #000;
          font: normal normal 16px DomaineSansText-Light;
        }
        .randomcategory-content-container {
          position: absolute;
          left: 8%;
          bottom: 22%;
          color: #fff;
        }
        .randomcategory-content-container .subtext {
          font-size: 16px;
          font-family: DomaineSansText-Light;
        }
        .text-style-premain {
          font-size: 30px;
          font-family: DomaineSansText-Light;
          color: #fff;
          text-transform: uppercase;
        }

        .txt-style-main {
          font-family: DomaineSansText-Medium;
          font-size: 30px;
          line-height: 60px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }

        @media (max-width: 640px) {
          .randomcategory-container {
            height: 248px;
            margin: 0px 16px;
          }
          .randomcategory-content-container {
            bottom: 0px;
            left: 0px;
            width: 100%;
            padding: 16px;
          }
          .randomcategory-container .btn-shop-randomcategory {
            width: 208px;
            height: 40px;
            font-size: 14px;
          }
          .randomcategory-content-container .subtext {
            font-size: 14px;
          }
          .text-style-premain {
            font-size: 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default RandomCategory;
