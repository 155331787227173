import { useNavigate } from "react-router-dom";

const ShopDesigner = () => {
  const navigate = useNavigate();

  const shopBrand = (slug) => {
    navigate(`/designers/${slug}`);
  };

  const brands = [
    {
      id: 6264,
      imageUrl:
        "https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732081426/womens-homepage-image-4_joysr4.png",
      name: "Neptunes Female Clothing",
      subtitle: "Every day cuts and unique lines",
      description:
        "Timeless and stylish pieces to elevate and enhance the modern woman",
      action: "Shop Designer",
      slug: "neptunes-female-clothing-1",
    },
    {
      id: 6143,
      imageUrl:
        "https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732081432/womens-homepage-image-5_hb5wmb.png",
      name: "FLAT17STUDIO",
      subtitle: "Edgy methods graced with poise",
      description:
        "Versatility wrapped in femininity. Ethically crafted for defined style",
      action: "Shop Designer",
      slug: "flat17studio",
    },
  ];

  return (
    <div>
      <div className="shop-gender-container">
        {brands.map((brand) => (
          <div
            key={brand.id}
            className="gender-container"
            onClick={() => shopBrand(brand.slug)}
          >
            <img
              className="img-gender"
              alt={brand.name}
              src={brand.imageUrl}
              loading="lazy"
            />
            <h3 className="gender-title">{brand.name}</h3>
            <h5 className="gender-desc">{brand.subtitle}</h5>
            <h5 className="gender-desc">{brand.description}</h5>
            <p className="mobile-shop-designer-cta">{brand.action}</p>
          </div>
        ))}
      </div>
      <style jsx="true">{`
        .shop-gender-container {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: 0px;
          margin: 20px 16px;
          gap: 30px;
          margin-bottom: 60px;
        }
        .img-gender {
          width: 100%;
          // height: 474px;
          object-fit: cover;
          object-position: top;
          // height: calc(100vh - 290px);
        }
        .gender-title {
          font-family: "DomaineSansText-Medium";
          font-size: 28px;
          line-height: 26px;
          text-transform: uppercase;
          margin: 12px 0 8px;
          padding: 0;
        }
        .gender-desc {
          font: normal normal 14px DomaineSansText-Light;
          line-height: 24px;
          margin: 0;
          padding: 0;
        }
        .gender-container {
          flex-basis: 50%;
        }
        .mobile-shop-designer-cta {
          cursor: pointer;
          font: normal 400 16px DomaineSansText-Light;
          color: #000;
          text-decoration: underline;
          text-align: left;
          margin-top: 8px;
          padding: 0;
        }
      `}</style>
    </div>
  );
};

export default ShopDesigner;
