import { useState, useEffect, useMemo } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Layout, Footer } from "../components/layout";
import Home from "../pages/Home";
import Products from "../pages/Products";
import Designers from "../pages/Designers";
import ProductDetails from "../pages/ProductDetails";
import RegisterSeller from "../pages/RegisterSeller";
import CreateStore from "../pages/seller/RegisterBusiness";
import Account from "../components/Account";
import KidsPage from "../pages/kidsPage/KidsPage";
import FAQ from "../pages/Faq";
import Cart from "../pages/Cart";
import Checkout from "../pages/Checkout";
import Completed from "../pages/OrderCompleted";
import About from "../pages/AboutUs";
import ScrollToTop from "../utils/scrollToTop";
import Orders from "../pages/user/Orders";
import Address from "../pages/user/Address";
import Rewards from "../pages/user/Rewards";
import SavedItems from "../pages/user/SavedItems";
import RecentViews from "../pages/user/RecentViews";
import Settings from "../pages/user/Settings";
import NewAddress from "../pages/user/NewAddress";
import NotFound from "../pages/404";
import Shipping from "../pages/Shipping";
import Delivery from "../pages/Delivery";
import Policy from "../pages/Policy";
import Returns from "../pages/Returns";
import Terms from "../pages/Terms";
import SearchResult from "../pages/SearchResult";
import SellerHomepage from "../pages/SellerHomepage";
import Linktree from "../pages/Linktree";
// import { useSelector } from "react-redux";
import SellerResetPassword from "../pages/SellerResetPassword";
import BuyerResetPassword from "../pages/BuyerResetPassword";
import SellerVerifyEmail from "../pages/SellerVerifyEmail";
import SellerEmailVerified from "../pages/SellerEmailVerified";
import BuyerEmailVerified from "../pages/BuyerEmailVerified";
import SellerResetPasswordConfirm from "../pages/SellerResetPasswordConfirm";
import SellerResetPasswordSuccessful from "../pages/SellerResetPasswordSuccessful";
import SellerAgreement from "../pages/SellerAgreement";
import ListAnItem from "../pages/seller/ListAnItem";
import ManageListings from "../pages/seller/ManageListings";
import ManageOrders from "../pages/seller/ManageOrders";
import EditListing from "../pages/seller/EditListing";
import ManageDrafts from "../pages/seller/ManageDrafts";
import ManagePayment from "../pages/seller/Payment";
import PaymentSettings from "../pages/seller/PaymentSettings";
import StoreSettings from "../pages/seller/StoreSettings";
import OrderDetails from "../pages/seller/OrderDetails.js";
import CompleteBuyerAccount from "../pages/user/CompleteBuyerAccount";
import EmailPreferences from "../pages/user/EmailPreferences";
import BuyerOrderDetails from "../pages/user/BuyerOrderDetails";
import MultipleOrderDetails from "../pages/user/MultipleOrderDetails";
import LosodeCredit from "../pages/user/LosodeCredit";
import RequestReturn from "../pages/user/RequestReturn";
import RequestAmendment from "../pages/user/RequestAmendment";
import RequestExchange from "../pages/user/RequestExchange";
import MensPage from "../pages/mensPage";
import WomensPage from "../pages/womensPage";
import ListingPreview from "../pages/seller/ListingPreview";
import StorePreview from "../pages/seller/StorePreview";
import SellerApplyToJoin from "../pages/SellerApplyToJoin";
import ContactUs from "../pages/ContactUs";
import SellerApplyToWaitlist from "../pages/SellerApplyToWaitlist";
import CompleteListing from "../pages/seller/CompleteListing";
// import {
//   BookPhotoshoot,
//   PhotoshootCheckout,
//   PhotoshootSuccess,
// } from "../pages/seller/photoshoot";
import ManagePhotoshoots from "../pages/seller/ManagePhotoshoots.jsx";
import SalePage from "../pages/SalePage.js";
import Cookies from "../pages/HomepageWeb/sections/Cookies.jsx";

const PageRoutes = () => {
  // const user = useSelector((state) => state.user);

  const [layoutFooterShown, setLayoutFooterShown] = useState(true);
  const [currentPath, setCurrentPath] = useState("");
  const location = useLocation();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const noHeaderAndFooterRoutes = useMemo(
    () => [
      "/sell",
      "/sell/reset-password",
      "/account",
      "/list-item",
      "/book-photoshoot",
      "/plan1-photoshoot-checkout",
      "/plan2-photoshoot-checkout",
      "/photoshoot/confirm",
      "/manage-listings",
      "/manage-orders",
      "/edit-listing",
      "/complete-listing",
      "/saved-listings",
      "/manage-payment",
      "/payment-settings",
      "/store-settings",
      "/order/sale-number",
      "/listing",
      "/store/",
      "/checkout",
      "/confirm",
      "/sell/apply-to-join",
      "/sell/email-verified",
      "/manage-photoshoots",
    ],
    []
  );

  useEffect(() => {
    for (var i = 0; i < noHeaderAndFooterRoutes.length; i++) {
      // console.log(currentPath+":"+noHeaderAndFooterRoutes[i])

      if (currentPath.includes("/seller/")) {
        return;
      }
      if (currentPath.includes("/sell-terms")) {
        return;
      }

      if (currentPath.includes(noHeaderAndFooterRoutes[i])) {
        setLayoutFooterShown(false);
        return;
      }
    }

    setLayoutFooterShown(true);
  }, [currentPath, noHeaderAndFooterRoutes]);

  const nonSellerRoutes = [
    {
      path: "/:slug/all/cid=:cid&name=:slug",
      search: "?query=:params",
      element: <Products />,
    },
    {
      path: "/new-in/:g/cid=:cid&name=:slug",
      search: "?query=:params",
      element: <Products />,
    },
    {
      path: "/:type/:sub/:cat/cid=:cid",
      search: "?query=:params",
      element: <Products />,
    },
    {
      path: "/products/:slug/cid=:cid&name=:slug",
      search: "?query=:params",
      element: <Products />,
    },
    {
      path: "/designers/:slug",
      search: "?query=:params",
      element: <Products />,
    },
    { path: "/designers", element: <Designers /> },
    { path: "/product/:slug", element: <ProductDetails /> },
    {
      path: "/buyer/confirm-reset-password/:slug",
      element: <SellerResetPasswordConfirm />,
    },
    {
      path: "/buyer/reset-password-successful",
      element: <SellerResetPasswordSuccessful />,
    },

    {
      path: "/products/search",
      search: "?query=:slug",
      hash: "",
      element: <SearchResult />,
    },
    { path: "/orders", element: <Orders /> },
    { path: "/saved-items", element: <SavedItems /> },
    { path: "/recent-views", element: <RecentViews /> },
    { path: "/cart", element: <Cart /> },
    { path: "/checkout", element: <Checkout /> },

    { path: "/shipping", element: <Shipping /> },
    {
      path: "/confirm",
      search: "?txRef=:slug",
      hash: "",
      element: <Completed />,
    },
    { path: "/women", element: <WomensPage /> },
    { path: "/men", element: <MensPage /> },
    { path: "/kids", element: <KidsPage /> },
    { path: "/about-us", element: <About /> },
    { path: "/delivery", element: <Delivery /> },
    { path: "/policy", element: <Policy /> },
    { path: "/returns", element: <Returns /> },
    { path: "/sell-terms", element: <SellerAgreement /> },
    { path: "/terms", element: <Terms /> },
    { path: "/complete-account", element: <CompleteBuyerAccount /> },
    { path: "/contact-us", element: <ContactUs /> },
  ];

  return (
    <>
      <ScrollToTop />
      {layoutFooterShown && <Layout />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register-seller" element={<RegisterSeller />} />
        <Route path="/sell/apply-to-join" element={<SellerApplyToJoin />} />
        <Route
          path="/sell/apply-to-waitlist"
          element={<SellerApplyToWaitlist />}
        />
        <Route path="/sell/register/:id" element={<CreateStore />} />
        <Route path="/account" element={<Account />} />
        <Route path="/sale" element={<SalePage />} />
        <Route path="/address" element={<Address />} />
        <Route path="/email-preferences" element={<EmailPreferences />} />
        <Route path="/losode-credits" element={<LosodeCredit />} />
        <Route path="/add-address" element={<NewAddress />} />
        <Route path="/rewards" element={<Rewards />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/sell" element={<SellerHomepage />} />
        <Route path="/order/:slug/details" element={<BuyerOrderDetails />} />
        <Route path="/:slug/request-return" element={<RequestReturn />} />
        <Route path="/:slug/request-exchange" element={<RequestExchange />} />
        <Route path="/:slug/request-amendment" element={<RequestAmendment />} />
        <Route
          path="/orders/:slug/details"
          element={<MultipleOrderDetails />}
        />
        <Route path="/bio-link" element={<Linktree />} />
        <Route
          path="/products/search"
          search="?query=:slug"
          hash=""
          element={<SearchResult />}
        />
        <Route path="/reset-password" element={<BuyerResetPassword />} />
        <Route path="/sell/reset-password" element={<SellerResetPassword />} />
        <Route
          path="/seller/confirm-reset-password/:slug"
          element={<SellerResetPasswordConfirm />}
        />
        <Route
          path="/seller/reset-password-successful"
          element={<SellerResetPasswordSuccessful />}
        />
        <Route
          path="/seller/email-verification"
          element={<SellerVerifyEmail />}
        />
        {/* photshoot disabled until review */}
        {/* <Route path="/book-photoshoot" element={<BookPhotoshoot />} />
        <Route
          path="/:plan-photoshoot-checkout"
          element={<PhotoshootCheckout />}
        />
        <Route path="/photoshoot/confirm" element={<PhotoshootSuccess />} />*/}

        <Route path="/manage-photoshoots" element={<ManagePhotoshoots />} />
        <Route path="/sell/email-verified" element={<SellerEmailVerified />} />
        <Route path="/email-verified" element={<BuyerEmailVerified />} />
        <Route path="/list-item" element={<ListAnItem />} />
        <Route path="/manage-listings" element={<ManageListings />} />
        <Route path="/manage-orders" element={<ManageOrders />} />
        <Route path="/edit-listing" element={<EditListing />} />
        <Route path="/complete-listing" element={<CompleteListing />} />
        <Route path="/saved-listings" element={<ManageDrafts />} />
        <Route path="/manage-payment" element={<ManagePayment />} />
        <Route path="/payment-settings" element={<PaymentSettings />} />
        <Route path="/store-settings" element={<StoreSettings />} />
        <Route path="/order/sale-number=:slug" element={<OrderDetails />} />
        <Route path="/faqs" element={<FAQ />} />
        <Route path="/listing/:slug" element={<ListingPreview />} />
        <Route path="/store/:slug/preview" element={<StorePreview />} />

        {nonSellerRoutes.map(({ path, element }) => {
          return <Route path={path} element={element} key={path} />;
          // if (
          //   path.includes("about") ||
          //   path.includes("return") ||
          //   path.includes("delivery") ||
          //   path.includes("terms") ||
          //   path.includes("policy")
          // ) {
          //   return <Route path={path} element={element} key={path} />;
          // }
          // return (
          //   <Route
          //     path={path}
          //     element={<Navigate to="/" replace />}
          //     key={path}
          //   />
          // );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Cookies />
      {layoutFooterShown && <Footer />}
    </>
  );
};

export default PageRoutes;
