import { createRef } from "react";
import { Carousel } from "antd";
import { useMediaQuery } from "react-responsive";

const Quality = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const carouselSettings = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // dots: false,
    infinite: false,
  };

  const carouselRef = createRef();

  const items = [
    {
      title: "Quick And Secure Deliveries",
      desc: "At Losode, we have carefully optimised our logistics processes to ensure fast, reliable, and hassle-free delivery. Shop with confidence, knowing your orders are handled with efficiency and care every step of the way.",
      // url: "/images/icon-truck.svg",
      url: "/images/quick-delivery-new.svg",
    },
    {
      title: "Fair Returns Policy",
      desc: "At Losode, our designers uphold fair, transparent policies that safeguard your purchases, providing you with peace of mind. From secure transactions to customer-first returns and exchanges, we ensure a safe and reliable shopping experience.",
      // url: "/images/icon-returns.svg",
      url: "/images/returns-policy-new.svg",
    },
    {
      title: "Easy to Use Platform",
      desc: "We have built a user-friendly platform that’s easy to navigate, making your shopping experience seamless and enjoyable. Discover a simple and efficient way to browse, shop, and connect with top designers on Losode.",
      // url: "/images/icon-search.svg",
      url: "/images/easy-to-use.svg",
    },
    {
      title: "24/7 Support",
      desc: "Visit our Contact Us page to find all the ways you can reach us. Whether you have questions, feedback, or need assistance, we are here to help. Get in touch with Losode today!",
      // url: "/images/icon-support.svg",
      url: "/images/support-new.svg",
    },
  ];

  return (
    <div className="quality-container">
      <h1 className="quality-title">Shop With Confidence On Losode</h1>
      <h4 className="quality-desc">
        What You See Is What You Get. At Losode, we know how important trust is
        when shopping online. That is why we ensure every purchase meets, and
        exceeds, your expectations. If anything falls short, our team is ready
        to make it right, quickly and hassle-free. Shop with confidence and
        discover quality fashion, guaranteed.
      </h4>

      <div className="quality-line-container">
        <span className="quality-line-span" />
      </div>

      {!isTabletOrMobile && (
        <div className="quality-items-container">
          {items.map((item, idx) => (
            <div className="quality-item-div" key={idx}>
              <img className="quality-icon" alt={item.title} src={item.url} />
              <h1 className="quality-item-title">{item.title}</h1>
              <h4 className="quality-item-desc">{item.desc}</h4>
            </div>
          ))}
        </div>
      )}

      {isTabletOrMobile && (
        <div style={{ marginTop: "40px", marginBottom: "80px" }}>
          <Carousel {...carouselSettings} ref={carouselRef}>
            {items.map((item, idx) => (
              <div className="quality-item-div" key={idx}>
                <img className="quality-icon" alt={item.title} src={item.url} />
                <h1 className="quality-item-title">{item.title}</h1>
                <h4 className="quality-item-desc">{item.desc}</h4>
              </div>
            ))}
          </Carousel>
        </div>
      )}

      <style jsx="true">{`
        .quality-container {
          background: #f9f9f9;
          padding: 24px 4rem;
          text-align: center;
        }
        .quality-title {
          font: normal 400 30px DomaineSansText-Medium;
          line-height: 30px;
          margin-bottom: 16px;
          text-transform: uppercase;
        }
        .quality-desc {
          font: normal 300 16px DomaineSansText-Light;
          line-height: 1.4;
          margin-bottom: 40px;
          margin: 0 auto;
        }
        .quality-desc-bold {
          font: normal 400 15px DomaineSansText-Black;
        }
        .quality-items-container {
          display: flex;
          justify-content: space-between;
          margin-top: 24px;
          margin-bottom: 24px;
        }
        .quality-item-div {
          align-items: center;
          display: flex;
          flex-direction: column;
          width: 23%;
        }
        .quality-icon {
          width: 40px;
          height: 40px;
        }

        .quality-item-title {
          font: normal 700 20px DomaineSansText-Medium;
          line-height: 25px;
          text-align: center;
          margin-top: 8px;
          text-transform: capitalize;
        }
        .quality-item-desc {
          font: normal 300 16px DomaineSansText-Light;
          line-height: 1.4;
          text-align: center;
        }
        .quality-item-div:first-child > .quality-item-desc {
          text-align: left;
        }
        .quality-item-div:last-child > .quality-item-desc {
          text-align: right;
        }
        .quality-line-container {
        }

        .quality-line-span {
          display: block;
          border-bottom: 0.5px solid #dbdbdb;
          width: 100%;
          margin: 50px auto;
        }

        @media (max-width: 640px) {
          .quality-container {
            padding: 0 16px;
            margin: 40px 0;
            text-align: left;
          }
          .quality-title {
            font-size: 16px;
            line-height: normal;
          }
          .quality-desc {
            width: 100%;
            line-height: 22px;
            font-size: 14px;
          }
          .quality-desc-bold {
            font-size: 14px;
          }
          .quality-items-container {
            flex-direction: column;
            gap: 24px;
            margin-bottom: 60px;
            margin-top: 24px;
          }
          .quality-item-div {
            width: 100%;
          }
          .quality-item-title {
            font-family: "DomaineSansText-Regular";
            font-weight: 400;
            margin-bottom: 2px;
            font-size: 16px;
          }
          .quality-item-desc {
            font-size: 14px;
            width: 80%;
          }
          .quality-line-container {
          }
          .quality-line-span {
            margin: 24px auto !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Quality;
