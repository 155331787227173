import { Button, Dropdown, Menu } from "antd";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

export default function CheckoutHeader({
  dropdownOpen,
  setDropdownOpen,
  pageItemsLength,
}) {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  return (
    <div>
      <div className="checkout-top-nav">
        <Dropdown
          trigger={["hover"]}
          open={dropdownOpen}
          overlay={
            <Menu>
              <div className="checkout-logo-dropdown">
                <div>Are you sure you want to return to your Shopping Bag?</div>
                <div
                  style={{ display: "flex", gap: "16px", marginTop: "16px" }}
                >
                  <Button
                    className="btn-stay"
                    onClick={() => navigate("/cart")}
                  >
                    Return to Bag
                  </Button>
                  <Button
                    className="btn-return"
                    onClick={() => setDropdownOpen(false)}
                  >
                    Continue Checkout
                  </Button>
                </div>
              </div>
            </Menu>
          }
          placement="bottomRight"
          arrow
        >
          <img
            src="/images/losode-no-tagline.png"
            alt="losode-logo"
            width={isTabletOrMobile ? "80px" : "100px"}
            style={{ zIndex: 201, cursor: "pointer" }}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          />
        </Dropdown>

        <div
          style={{ position: "absolute", width: "100%", textAlign: "center" }}
        >
          Checkout <span style={{fontWeight: 400}}>{`(${pageItemsLength} Items)`}</span>
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <style>{`
        .checkout-top-nav{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px;
            position: fixed;
            top: 0;
            width: 100%;
            background: white;
            font-family: 'DomaineSansText-Light';
            font-size: 18px;
            font-weight: bold;
            height: 80px;
            z-index: 100;
            box-shadow: 0 2px 10px 0 #05050526;
        }
        .checkout-logo-dropdown{
            background:#fff;
            font-family: 'DomaineSansText-Light';
            padding:16px
        }
        .checkout-logo-dropdown .btn-stay{
            font-size: 14px;
            height: 40px;
            background: white;
            color: black;
            width:100%
        }
        .checkout-logo-dropdown .btn-return{
            font-size: 14px;
            height: 40px;
            background: black;
            color: white;
            width:100%
        }
      `}</style>
    </div>
  );
}
